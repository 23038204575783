import { connect } from 'react-redux';
import HeadBarView from './HeadBarView';
import { isCollapsed, isOpenLeftFloatMenue, isLogIn, getUserInfo, menues, userEnd, isNewHeaderbarToggle, lastPage, isShowGenerate } from '@/selector/user';
import { isShouldSave } from '@/selector/story';
import * as userAction from '../../action/userAction';
import * as dataAction from '@/action/dataAction';

const mapStateToProps = (state) => ({
    isLogIn: isLogIn(state),
    userInfo: getUserInfo(state),
    isCollapsed: isCollapsed(state),
    isOpenLeftFloatMenue: isOpenLeftFloatMenue(state),
    menues: menues(state),
    userEnd: userEnd(state),
    isNewHeaderbarToggle: isNewHeaderbarToggle(state),
    lastPage: lastPage(state),
    isShowGenerate: isShowGenerate(state),
    isShouldSave: isShouldSave(state),
})

const mapDispatchToProps = dispatch => {
    return {
        visualizeData: (data) => dispatch(dataAction.visualizeData(data)),
        updateLastPage: (lastPage) => dispatch(userAction.updateLastPage(lastPage)),
        updateClickedMenus: (item) => dispatch(userAction.updateClickedMenus(item)),
        openLeftFloatMenue: (isOpenLeftFloatMenue) => dispatch(userAction.openLeftFloatMenue(isOpenLeftFloatMenue)),
        setCollapsed: (isCollapsed) => dispatch(userAction.setCollapsed(isCollapsed)),
        loadingGeneratingView: (isGenerateStory) => dispatch(userAction.loadingGeneratingView(isGenerateStory)),
        toNewPage: (page) => dispatch(userAction.toNewPage(page)),
        leaveCreatePage: (isLeaving) => dispatch(userAction.leaveCreatePage(isLeaving)),
        isClickedLogout: (isLogOut) => dispatch(userAction.isClickedLogout(isLogOut)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeadBarView);
