import { connect } from 'react-redux';
import LogoutLoading from './LogoutLoading';
import { lastPage } from '@/selector/user';

const mapStateToProps = (state) => ({
    lastPage: lastPage(state),
})

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutLoading);
