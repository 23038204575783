import React, { Component } from "react";
import { Layout, Empty, Spin, message } from "antd";
import HeadBarView from '@/components/HeadBarView/index';
import SearchView from '@/components/SearchView/SearchView';
import CardList from '@/components/CardListView/index';
import ProjectDescription from './ProjectDescriptionView/ProjectDescription';
import IndexLeftMenueView from '@/components/IndexLeftMenueView/index';
import mediaQueryConfig from '@/constant/mediaQueryConfig';
import getUserEndSize from '@/tool/getUserEndSize';
import * as api from '@/axios/api';
import uuidv4 from 'uuid/v4';
import InfiniteScroll from 'react-infinite-scroller';
import _ from 'lodash';
import '@/pages/common.less';
import './HomePage.less';

const { Content } = Layout;

export default class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            hasMore: true,
            storiesList: [],
            isShowLoading: true,
            page: 0,
        }
        this.per_page = 6
    }
    componentWillMount() {
        mediaQueryConfig.map((media) => {
            let mql = window.matchMedia(media)
            this.mediaQueryChanged(mql)
            mql.addListener(this.mediaQueryChanged);
            return media
        })
        this.props.windowUpScroller(false)
        let params = {
            per_page: this.per_page,
            page: this.state.page,
            sort: 'rate' //rate|share|time
        }
        api.queryTopRatedDataStories(params).then((response) => {
            response.stories.sort((a, b) => { return b.rating - a.rating });
            this.processChartData(response.stories)
        })
    }

    onSearchListener = (value) => {
        if (value === '' || value === " ") {
            message.info(this.props.intl.get('please input search text'))
            return
        }
        this.props.history.push({
            pathname: `/index/topRatedDataStoriesAbout/${value}`
        })
    }

    handleInfiniteOnLoad = () => {
        let nextPage = this.state.page + 1
        let params = {
            per_page: this.per_page,
            page: nextPage,
            sort: 'rate' //rate|share|time
        }
        this.setState({
            loading: true,
            page: nextPage
        })
        this.props.windowUpScroller(true)
        api.queryTopRatedDataStories(params).then((response) => {
            this.props.windowUpScroller(false)
            let currentStories = _.cloneDeep(this.state.storiesList).concat(response.stories)
            this.processChartData(currentStories)
        })
    };
    processChartData = (queryStories) => {
        let newStoriesList = queryStories.map(story => {
            // if (story.json === '{}' || JSON.parse(story.json).facts.length === 0 || JSON.parse(story.json).relations.length === 0 || JSON.parse(story.json).data.length === 0) {
            //     story.facts = stories[0].facts
            //     story.relations = _.cloneDeep(this.props.relations)
            //     story.data = _.cloneDeep(this.props.data)
            // } else {
            const { facts, relations, data, schema, showSuggestion } = JSON.parse(story.json)
            story = { ...story, facts, relations, data, schema, showSuggestion }
            // }
            return story
        })
        newStoriesList.map(story => {
            story.MaxSignificanceId = 0
            let events = story.facts.map(function (fact, i, facts) {
                //significance最高的显示第一帧chart画面
                if (fact.significance > facts[story.MaxSignificanceId].significance) {
                    story.MaxSignificanceId = i;
                }
                return {
                    id: uuidv4(),
                    index: i,
                    fact: fact,
                    relation: story.relations[i],
                }
            })
            events.filter(fact => {
                if (!fact.aggregated) {
                    return true
                } else {
                    return fact.aggregated && fact.aggregatedFact
                }
            })
            story.events = events;
            let averageFactScore = (events.map(x => x.fact.score).reduce((a, b) => a + b, 0)) / events.length;
            if (!averageFactScore) averageFactScore = 0;
            story.averageFactScore = averageFactScore;
            return story
        })
        this.setState({
            storiesList: newStoriesList,
            isShowLoading: false,
            loading: false
        })
    }
    mediaQueryChanged = (mql) => {
        if (mql.matches) {
            this.props.updateUserEnd(mql.media)
            let userEnd = this.props.userEnd
            if (!userEnd) {
                userEnd = getUserEndSize(mql.media)
            }
            this.changeCardNumber(userEnd)
        }

    }
    changeCardNumber = (userEnd) => {
        switch (userEnd) {
            case "browser-max-pro":
            case "browser-max":
                this.per_page = 6
                break;
            case "browser-min":
                this.per_page = 5
                break;
            case "ipad":
                this.per_page = 4
                break;
            case "ipad-min":
                this.per_page = 3
                break;
            case "iphone-pro":
            case "iphone":
                this.per_page = 2
                break;
            default:
                this.per_page = 6
                break;
        }
    }
    clickBlank = () => {
        //左侧的菜单展开的情况下，点击右侧空白区域，可以关闭
        if (this.props.isOpenLeftFloatMenue) {
            this.props.openLeftFloatMenue(false)
        }
    }

    render() {
        const { storiesList, isShowLoading } = this.state;
        const { isOpenLeftFloatMenue, initDone, intl } = this.props;
        return initDone && <Layout className='pageWrapper'>
            <HeadBarView {...this.props} isLoadedInIndexPage={false}></HeadBarView>
            <Content className='homePageContent'>
                <InfiniteScroll className='contentWrapper'
                    initialLoad={false}
                    pageStart={0}
                    //loadMore={this.handleInfiniteOnLoad}
                    loadMore={() => { }}
                    hasMore={!this.state.loading && this.state.hasMore}
                    useWindow={false}
                >
                    <div className='leftFloatMenue' style={{ display: isOpenLeftFloatMenue ? 'block' : 'none' }}>
                        <IndexLeftMenueView isLeftFloatMenue={true}
                            {...this.props} ></IndexLeftMenueView>
                    </div>
                    <div className='mediumContent' onClick={this.clickBlank}>
                        <ProjectDescription intl={intl} />
                        <div style={{ position: 'relative', width: '100%', height: '1px' }}>
                            <div className='decorationImage'></div>
                            <div className='belowLine' ></div>
                        </div>
                    </div>
                    <Layout className='bottomContent columnDiv' onClick={this.clickBlank}>
                        <span className='topRatedTxt'>{intl.get('homePage.TopRatedDataStories')}</span>
                        <div className='searchWrapper'>
                            <SearchView onSearchListener={this.onSearchListener} intl={intl}></SearchView>
                        </div>
                        <Layout style={{ width: '100%', backgroundColor: 'white' }}>
                            {isShowLoading ?
                                <div className="loading-container">
                                    <Spin tip={intl.get("Loading")} />
                                </div>
                                :
                                storiesList.length === 0 ?
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    :
                                    <div style={{ height: '300px', overflowY: "hidden", paddingTop: "10px" }}>
                                        <CardList isCardCenter={true} stories={storiesList} {...this.props} />
                                    </div>
                            }
                        </Layout>
                    </Layout>

                </InfiniteScroll>
            </Content>
        </Layout>
    }
}