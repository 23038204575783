import ActionType from './type';



export const uploadData = (fileName, real_filename, schema, data) => ({
    type: ActionType.UPLOAD_DATA,
    fileName,
    real_filename,
    schema,
    data
})
export const connectData = (fileName, real_filename, schema, data) => ({
    type: ActionType.CONNECT_DATA,
    fileName,
    real_filename,
    schema,
    data
})
export const changeData = (fileName,real_filename) => ({
    type: ActionType.CHANGE_DATA,
    fileName,
    real_filename
})

export const visualizeData = (visData) => ({
    type: ActionType.VISUALIZE_DATA,
    visData
})
