import React from 'react';
import { Tooltip, Divider } from 'antd';
import * as d3 from 'd3';

export default class NumericalFilter extends React.Component {
  
    componentDidMount() {
        this.draw(this.props);
    }
    componentDidUpdate() {
        this.draw(this.props);
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     let { filters } = this.props;
    //     console.log("🚀 ~ file: NumericalFilter.js ~ line 20 ~ NumericalFilter ~ shouldComponentUpdate ~ nextProps", nextProps)

    //     console.log("🚀 ~ file: NumericalFilter.js ~ line 17 ~ NumericalFilter ~ shouldComponentUpdate ~ this.props", this.props)

    //     let nextfilters= nextProps.filters;// this.forceUpdate()
    //     console.log("🚀 ~ file: NumericalFilter.js ~ line 22 ~ NumericalFilter ~ shouldComponentUpdate ~ !_.isEqual(filters, nextfilters) ", nextfilters )
    //     console.log("🚀 ~ file: NumericalFilter.js ~ line 22 ~ NumericalFilter ~ shouldComponentUpdate ~ !_.isEqual(filters, nextfilters) ", filters )
    //     console.log("🚀 ~ file: NumericalFilter.js ~ line 23 ~ NumericalFilter ~ shouldComponentUpdate ~ _.isEqual(filters, nextfilters) ", _.isEqual(filters, nextfilters) )
    //     if(nextProps.filtersChangeFlag){
    //         console.log("我在这里")
    //         this.draw(_.cloneDeep(this.props))
    //     } 
    //     if(!_.isEqual(filters, nextfilters)){
    //         if(nextProps.filtersChangeFlag){
    //             console.log("我在这里")
    //             this.draw(_.cloneDeep(this.props))
    //         } 
    //         else this.draw(_.cloneDeep(nextProps))
    //     } 
    //     return !_.isEqual(filters, nextfilters)
    // }
  

    draw = (props) => {
        
        if(document.getElementById(props.id + '-svg')) {
            // d3.selectAll('#' + props.id + '-svg').remove();
            return;
        }
        else console.log('不存在')

        
        var requestData = props.info;
        var requestDataValues = props.info.values.map(d => d.replace(',', '')); //去除数据中的","" 比如"1,385.66"
        var data = [];

        var map = new Map();

        map.clear();

        var arr = requestData.values.sort(function (a, b) {
            return parseFloat(a) - parseFloat(b);
        });
        arr = arr.map((value) => {
            return parseFloat(value);
        })
        var range = parseFloat((Math.max(...arr) - Math.min(...arr)) / 20);


        for (let i = 0; i < 20; i++) {
            map.set(i, 0);
        }

        for (let i = 0; i < arr.length - 1; i++) {
            var setname = parseInt((arr[i] - Math.min(...arr)) / range);
            map.set(setname, map.get(setname) + 1);
        }
        map.set(19, map.get(19) + 1);


        for (let [key, value] of map) {
            var datum = {};
            // datum.index = i;
            datum.index = key;
            datum.value = value;
            data.push(datum);
        }

        var margin = { top: 0, right: 0, bottom: 0, left: 0 },
            width = 196 - margin.left - margin.right,
            height = 36 - margin.top - margin.bottom;


        var x = d3.scaleBand().range([0, width]),
            //([0, width], .1),
            y = d3.scaleLinear().range([height, 0]);

        // var xAxis2 = d3.svg.axis().scale(x).orient("bottom").tickValues([]);
        x.domain(data.map(function (d) { return d.index }));
        y.domain([0, Math.max(...map.values())]);

    

        var brush = d3.brushX()
            //  .x(x) 
            .extent([[0, 0], [width, height]])
            .on("brush", brushed);

        var svg = d3.select('#' + props.id).append("svg")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .attr("id", props.id + '-svg');

       
   


        svg.append("rect")
            .attr("width", width)
            .attr("height", height)
            .attr("x", "0")
            .attr("y", "0")
            .attr("fill", "#f0f0f0")




        var context = svg.append("g")
            .attr("class", "context")
        //   .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        updateScale(data, [0, width]);

        var subBars = context.selectAll('.subBar')
            .data(data)

        subBars.enter().append("rect")
            .classed('subBar', true)
            .attr("height", (d) => height - y(d.value))
            .attr("width", (d) => x.bandwidth())
            .attr("x", (d) => x(d.index))
            .attr("y", (d) => y(d.value))
            .attr("fill", "#777");
        // .attr(
        // {
        // height: function (d)
        // {
        //     console.log('ddd', height);
        //     console.log('ddd', y(d.value))
        //     return height - y(d.value);
        // },
        // width: function(d){ return x.rangeBand()},
        // x: function(d) {

        //     return x(d.index);
        // },
        // y: function(d)
        // {
        //     return y(d.value)
        // }
        // })
        context.append("g")
            .attr("class", "x brush" + Math.random())
            .call(brush)
            .selectAll("rect")
            .attr("y", 0)
            .attr("width", width)
            .attr("height", height);


        let brushInitStart = props.filters[props.filterIndex].brushLocation === undefined ? 0 : props.filters[props.filterIndex].brushLocation[0];
        let brushInitEnd = props.filters[props.filterIndex].brushLocation === undefined ? 196 : props.filters[props.filterIndex].brushLocation[1];


if(brushInitStart === 0 && brushInitEnd === 196) {

        }else {
            context.append("rect")
                .attr("class", "xBrushInit")
                .attr("id", props.id + "-xBrushInit")
                .call(brush)
                // .selectAll("rect")
                .attr("x", brushInitStart)
                .attr("y", 0)
                .attr("width", brushInitEnd - brushInitStart)
                .attr("height", height);
        }
        
        function brushed() {
          
            d3.select('#' + props.id +'-xBrushInit').remove();
            if (!d3.event.selection) return;
            if (d3.event.selection === null) d3.event.selection = [0, width];
            var selected = null;
            selected = x.domain()
                .filter(function (d) {
                    return (d3.event.selection[0] <= x(d)) && (x(d) <= d3.event.selection[1]);
                });
            if(selected.length === 0) {
                selected = x.domain()
                .filter(function (d) {
                    return (d3.event.selection[0] <= x(d));
                });
            }
            
            var start;
            var end;

            var maxValue;
            var minValue;
            var newData;


            if (d3.event.selection[0] !== d3.event.selection[1]) {
                if(selected.length === 1) {
                    start = selected[0];
                    end = selected[0] + 1;
                    
                } else {
                    start = selected[0];
                    end = selected[selected.length - 1];
                }

                // console.log('selected', selected)

                // console.log('start', start)
                // console.log('end', end)

                maxValue = (end  + 1)* range + Math.min(...arr);
                minValue = (start === 0 ? 0 : start - 1) * range + Math.min(...arr);
                newData = requestDataValues.filter(d => d >= minValue && d <= maxValue);
                // console.log('minValue', minValue)
                // console.log('maxValue', maxValue)
                // console.log('newData', newData)
                if(isNaN(minValue) || isNaN(maxValue)) {
                    maxValue = '';
                    minValue = '';
                    newData = [];
                }
                
            } else {
                start = 0;
                end = 0;
                maxValue = '';
                minValue = '';
                newData = [];
            }        

            d3.selectAll('#' + props.id + '-textpostion').remove();

            let temporalTextDiv = d3.select('#' + props.id).append("div").attr("id", props.id + '-textpostion');

    //  d3.select('#' + props.id).append("div").attr("class", "textpostion")
            temporalTextDiv
                .append("span")
                .style("position", "relative")
                .style("bottom", "60px")
                .style("font-size", "9px")
                .style("float", "left")
                .text(minValue === '' ? minValue : minValue.toFixed(2))
                .attr('id', props.id + 'minValue')

            // .transfrom('');

            temporalTextDiv
                .append("span")
                .style("position", "relative")
                .style("bottom", "60px")
                .style("font-size", "9px")
                .style("float", "right")
                .text(maxValue === '' ? maxValue : maxValue.toFixed(2))
                .attr('id', props.id + 'maxValue')

            var filters = props.filters;
            // console.log('filters-brush-0', filters)
            // d3.select('text').remove();

            // d3.select('.topicDetails').append("text")
            // .text(minValue);
            props.filters[props.filterIndex].value = newData;
            props.filters[props.filterIndex].type = 'equal';

            props.filters[props.filterIndex].value1 = [minValue, maxValue];
            props.filters[props.filterIndex].brushLocation = [d3.event.selection[0], d3.event.selection[1]];

            // console.log('filters-brush-1', props.filters)
            props.updateFilters(filters);
            props.updateFiltersChangeFlag(true); 

            // updateScale(updatedData, d3.event.selection)


        }

        d3.selectAll('#' + props.id + '-textpostion').remove();
        let temporalTextDiv = d3.select('#' + props.id).append("div").attr("id", props.id + '-textpostion');

       

        let maxValue = props.filters[props.filterIndex].value1 === undefined ? Math.max(...requestDataValues) : props.filters[props.filterIndex].value1[1];
        let minValue = props.filters[props.filterIndex].value1 === undefined ? Math.min(...requestDataValues) : props.filters[props.filterIndex].value1[0];

        temporalTextDiv
            .append("span")
            .style("position", "relative")
            .style("bottom", "60px")
            .style("font-size", "9px")
            .style("float", "left")
            .text(minValue.toFixed(2))
            .attr('id', props.id + 'minValue')

        // .transfrom('');

        temporalTextDiv
            .append("span")
            .style("position", "relative")
            .style("bottom", "60px")
            .style("font-size", "9px")
            .style("float", "right")
            .text(maxValue.toFixed(2))
            .attr('id', props.id + 'maxValue')

        function updateScale(data, selection) {
            var tickScale = d3.scalePow().range([data.length / 10, 0]).domain([data.length, 0]).exponent(.5)
            // console.log('UPDATEd3.event',selection);
            var brushValue = selection[1] - selection[0];
            if (brushValue === 0) {
                brushValue = width;
            }

            var tickValueMultiplier = Math.ceil(Math.abs(tickScale(brushValue)));
            var filteredTickValues = data.filter(function (d, i) { return i % tickValueMultiplier === 0 }).map(function (d) { return d.index })// eslint-disable-line 

        }


        // return svg;
    }

    render() {
        let field = this.props.info.field;
        return (
            <div className="NumericalFilter">
                <div className="topicContent">
                    <Tooltip title={field}>
                        <p className="fieldText">{field}</p>
                    </Tooltip>
                </div>

                <div className="topicDetails topicDetailsSales" id={this.props.id} style={{ height: '60px', overflow: 'hidden' }}>
                    
                </div>
                <Divider />
            </div>
        )
    }
   
}

