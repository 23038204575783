import { connect } from 'react-redux';
import {
    getUserInfo, isLeftCollapsed, isOpenLeftFloatMenue, isMobilephoneEnd, isWindowUpScroll, userEnd, layoutType, isLogIn, lastPage, isShareStory, isShowGenerate, newPage, isToLogout,
    isLeavingCreatePage, OperateRelatedId
} from '@/selector/user';
import {
    fileName, real_filename, schema, relations, selectedFactIndex, storyParameter, data, resultCoverage, aggregationLevel, facts, rewardWeight, visData, title,
    isShouldSave,
    showSuggestion
} from '@/selector/story';
import { visContainerSize } from '@/selector/vis';
import * as userAction from '@/action/userAction';
import * as visAction from '@/action/visAction';
import * as dataAction from '@/action/dataAction';
import * as factAction from '@/action/factAction';
import * as storyAction from '@/action/storyAction';
import indexRoute from './indexRoute';
import {subtitle ,filters,filtersTitle,datares,schemaFilter,filtersChangeFlag,editstate,editingCurrentFact,dataresLocal, filtersLocal, filtersTitleLocal, schemaFilterLocal, unusedFacts, maximumFact} from '@/selector/story';
import { factsheetLayoutType } from '../../selector/user';
import { algorithm, calSimilarityType, hoverFactIndex, recommendedEfacts, recommendedMfacts, recommendedSfacts } from '../../selector/story';

const mapStateToProps = (state) => ({
    isLeftCollapsed: isLeftCollapsed(state),
    isOpenLeftFloatMenue: isOpenLeftFloatMenue(state),
    isLogIn: isLogIn(state),
    userInfo: getUserInfo(state),
    facts: facts(state),
    relations: relations(state),
    data: data(state),
    showSuggestion: showSuggestion(state),
    title: title(state),
    subtitle:subtitle(state),
    visData: visData(state),
    resultCoverage: resultCoverage(state),
    fileName: fileName(state),
    real_filename:real_filename(state),
    isMobilephoneEnd: isMobilephoneEnd(state),
    isWindowUpScroll: isWindowUpScroll(state),
    userEnd: userEnd(state),
    schema: schema(state),
    selectedFactIndex: selectedFactIndex(state),
    storyParameter: storyParameter(state),
    rewardWeight: rewardWeight(state),
    aggregationLevel: aggregationLevel(state),
    layoutType: layoutType(state),
    visContainerSize: visContainerSize(state),
    lastPage: lastPage(state),
    isShareStory: isShareStory(state),
    isShowGenerate: isShowGenerate(state),
    newPage: newPage(state),
    isToLogout: isToLogout(state),
    isShouldSave: isShouldSave(state),
    isLeavingCreatePage: isLeavingCreatePage(state),
    OperateRelatedId: OperateRelatedId(state),
    filters:filters(state),
    filtersTitle:filtersTitle(state),
    datares:datares(state),
    schemaFilter:schemaFilter(state),
    filtersChangeFlag:filtersChangeFlag(state),
    editstate:editstate(state),
    editingCurrentFact:editingCurrentFact(state),
    filtersLocal:filtersLocal(state),
    filtersTitleLocal:filtersTitleLocal(state),
    dataresLocal:dataresLocal(state),
    schemaFilterLocal:schemaFilterLocal(state),
    unusedFacts:unusedFacts(state),
    factsheetLayoutType:factsheetLayoutType(state),
    hoverFactIndex:hoverFactIndex(state),
    recommendedSfacts:recommendedSfacts(state),
    recommendedMfacts:recommendedMfacts(state),
    recommendedEfacts:recommendedEfacts(state),
    algorithm:algorithm(state),
    calSimilarityType:calSimilarityType(state),
    maximumFact:maximumFact(state)


})

const mapDispatchToProps = dispatch => {
    return {
        //users
        updateOperateRelatedId: ({ did, sid }) => dispatch(userAction.updateOperateRelatedId({ did, sid })),
        updateStoryCardNumber: (cardNumber) => dispatch(userAction.updateStoryCardNumber(cardNumber)),
        updateUserEnd: (userEndSize) => dispatch(userAction.updateUserEnd(userEndSize)),
        windowUpScroller: (isWindowUpScroll) => dispatch(userAction.windowUpScroller(isWindowUpScroll)),
        openLeftFloatMenue: (isOpenLeftFloatMenue) => dispatch(userAction.openLeftFloatMenue(isOpenLeftFloatMenue)),
        setLeftCollapsed: (isLeftCollapsed) => dispatch(userAction.setLeftCollapsed(isLeftCollapsed)),
        isChangeToggle: (isChangeToggle) => dispatch(userAction.updateHeaderbarToggle(isChangeToggle)),
        changeLayOutType: (layoutType) => dispatch(userAction.changeLayOutType(layoutType)),
        changeUserEnd: (isMobilephoneEnd) => dispatch(userAction.changeUserEnd(isMobilephoneEnd)),
        updateLastPage: (lastPage) => dispatch(userAction.updateLastPage(lastPage)),
        loadingGeneratingView: (isGenerateStory) => dispatch(userAction.loadingGeneratingView(isGenerateStory)),
        leaveCreatePage: (isLeaving) => dispatch(userAction.leaveCreatePage(isLeaving)),
        interruptGenerateProcess: (isInterrupt) => dispatch(userAction.interruptGenerateProcess(isInterrupt)),
        //comment
        hiddenCommentAStory: (isHidden) => dispatch(userAction.isHiddenCommentAStory(isHidden)),
        //data
        visualizeData: (data) => dispatch(dataAction.visualizeData(data)),
        changeData: (fileName, real_filename) => dispatch(dataAction.changeData(fileName, real_filename)),
        uploadData: (fileName, real_filename, schema, data) => dispatch(dataAction.uploadData(fileName,real_filename, schema, data)),
        connectData: (fileName, real_filename, schema, data) => dispatch(dataAction.connectData(fileName, real_filename,schema, data)),
        //vis
        updateVisContainerSize: (containerSize) => dispatch(visAction.updateVisContainerSize(containerSize)),
        //fact
        addFact: (facts) => dispatch(factAction.addFact(facts)),
        insertEmptyFact:(insert,emptyFact) => dispatch(factAction.insertEmptyFact(insert,emptyFact)),
        insertInterpolatedFacts:(insert,interpolatedFacts) => dispatch(factAction.insertInterpolatedFacts(insert,interpolatedFacts)),

        updateFact: (index, fact) => dispatch(factAction.updateFact(index, fact)),
        deleteFact: (index) => dispatch(factAction.deleteFact(index)),
        orderFacts: (sourceIndex, destinationIndex) => dispatch(factAction.orderFacts(sourceIndex, destinationIndex)),
        //story
        selectFact: (index) => dispatch(storyAction.selectFact(index)),
        generateStory: (facts, relations, coverage) => dispatch(storyAction.generateStory(facts, relations, coverage)),
        changeMethod: (method) => dispatch(storyAction.changeMethod(method)),
        setStoryParameter: (maxStoryLength, information, chartDiversity, timeLimit) => dispatch(storyAction.setStoryParameter(maxStoryLength, information, chartDiversity, timeLimit)),
        setRewardWeight: (logicality, diversity, integrity) => dispatch(storyAction.setRewardWeight(logicality, diversity, integrity)),
        setAggregationLevel: (level) => dispatch(storyAction.setAggregationLevel(level)),
        changeTitle: (title) => dispatch(storyAction.changeTitle(title)),
        changeSubTitle:(subtitle)=>dispatch(storyAction.changeSubTitle(subtitle)),
        updateCommentStoryInfo: (facts, relations, data, schema, title, fileName,real_filename, storyParameter, aggregationLevel, resultCoverage, showSuggestion,subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal ) => dispatch(storyAction.updateCommentStoryInfo(facts, relations, data, schema, title, fileName,real_filename, storyParameter, aggregationLevel, resultCoverage, showSuggestion,subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal )),
        changeCurrentEditing:(editstate,editingCurrentFact)=>dispatch(factAction.changeCurrentEditing(editstate,editingCurrentFact)),
        setHoverIndex: (index) => dispatch(factAction.setHoverIndex(index)),
        undo:()=>dispatch(storyAction.undo()),
        redo:()=>dispatch(storyAction.redo()),
        inserRecommendedFact: (index, insert,factLocation) => dispatch(factAction.inserRecommendedFact(index, insert,factLocation)),
        addSFact:(facts) => dispatch(factAction.addSFact(facts)),
        addMFact:(facts) => dispatch(factAction.addMFact(facts)),
        addEFact:(facts) => dispatch(factAction.addEFact(facts)),
        deleteMFact:(index) => dispatch(factAction.deleteMFact(index)),
        deleteSFact:(index) => dispatch(factAction.deleteSFact(index)),
        deleteEFact:(index) => dispatch(factAction.deleteEFact(index)),
        deleteUnusedFact:(index)=>dispatch(factAction.deleteUnusedFact(index)),
        updateFiltersLocal: (filtersLocal, filtersTitleLocal, dataresLocal, schemaFilterLocal) => dispatch(factAction.updateFiltersLocal(filtersLocal, filtersTitleLocal, dataresLocal, schemaFilterLocal)), 
        updateFilters:(filters)=>dispatch(factAction.updateFilters(filters)),
        updateFiltersTitle:(filtersTitle)=>dispatch(factAction.updateFiltersTitle(filtersTitle)),
        updateDatares:(datares)=>dispatch(factAction.updateDatares(datares)),
        updateSchemaFilter:(schemaFilter)=>dispatch(factAction.updateSchemaFilter(schemaFilter)),
        updateFiltersChangeFlag:(filtersChangeFlag)=>dispatch(factAction.updateFiltersChangeFlag(filtersChangeFlag)),
        resetTimeline:()=>dispatch(storyAction.resetTimeline())


    }
}

export default connect(mapStateToProps, mapDispatchToProps)(indexRoute);
