const templates = [
    {
        'id': 0,
        'template': '{{Sentence A}} In general, {{Sentence B}}',
    },
    {
        'id': 1,
        'template': '{{Sentence A}} As a whole, {{Sentence B}}',
    },
    {
        'id': 2,
        'template': '{{Sentence A}} Generally speaking, {{Sentence B}}',
    },
]

export default templates;