import ActionType from './type';

export const selectFact = (index) => ({
    type: ActionType.SELECT_FACT,
    index
})

export const changeMethod = (method) => ({
    type: ActionType.CHANGE_METHOD,
    method,
})

export const setStoryParameter = (maxStoryLength, information, chartDiversity, timeLimit) => ({
    type: ActionType.SET_STORY_PARAMETER,
    maxStoryLength,
    information,
    chartDiversity,
    timeLimit,
})

export const setRewardWeight = (logicality, diversity, integrity) => ({
    type: ActionType.SET_REWARD_WEIGHT,
    logicality,
    diversity,
    integrity,
})

export const setAggregationLevel = (level) => ({
    type: ActionType.SET_AGGREGATION_LEVEL,
    level,
})

export const generateStory = (facts, relations, coverage) => ({
    type: ActionType.GENERATE_STORY,
    facts,
    relations,
    coverage,
})

export const changeTitle = (title) => ({
    type: ActionType.CHANGE_TITLE,
    title,
})

export const changeSubTitle = (subtitle) => ({
    type: ActionType.CHANGE_SUBTITLE,
    subtitle,
})

export const updateCommentStoryInfo = (facts, relations, data, schema, title, fileName, real_filename, storyParameter, aggregationLevel, resultCoverage, showSuggestion,subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal ) => ({
    type: ActionType.UPDATE_COMMENT_STORY_INFO,
    facts,
    relations,
    data,
    schema,
    title,
    storyParameter,
    aggregationLevel,
    resultCoverage,
    fileName,
    real_filename,
    showSuggestion,
    subtitle,
    dataresLocal,
    schemaFilterLocal,
    filtersLocal,
    filtersTitleLocal
})
export const updateUUID = (uuid) => ({
    type: ActionType.UPDATE_UUID,
    uuid,
})
export const isEnableShowSuggestion = (isEnable) => ({
    type: ActionType.IS_ENABLE_SHOW_SUGGESTION,
    isEnable,
})

// animation
export const changeToAnimation = (animationFlag)=> ({
    type: ActionType.CHANGE_TO_ANIMATION,
    animationFlag
})

// algorithm
export const changeAlgorithm=(algorithm,calSimilarityType,maximumFact)=>({
    type: ActionType.CHANGE_ALGORITHM,
    algorithm,
    calSimilarityType,
    maximumFact
})

export const undo = () => ({
    type: ActionType.UNDO
})

export const redo = () => ({
    type: ActionType.REDO
})

export const resetTimeline = () =>({
    type:ActionType.RESET_TIMELINE
})
