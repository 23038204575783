import { connect } from 'react-redux';
import ShareModalView from './ShareModalView';
import { OperateRelatedId, getUserInfo } from '@/selector/user';
import * as userAction from '@/action/userAction';
import { schema, storyParameter, resultCoverage, aggregationLevel, facts, title, fileName } from '@/selector/story';
import { isShareStory } from '@/selector/user';

const mapStateToProps = (state) => ({
    userInfo: getUserInfo(state),
    OperateRelatedId: OperateRelatedId(state),
    facts: facts(state),
    title: title(state),
    schema: schema(state),
    aggregationLevel: aggregationLevel(state),
    storyParameter: storyParameter(state),
    resultCoverage: resultCoverage(state),
    fileName: fileName(state),
    isShareStory: isShareStory(state)
})

const mapDispatchToProps = dispatch => {
    return {
        shareStory: (isShareStory) => dispatch(userAction.shareStory(isShareStory)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareModalView);
