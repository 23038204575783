const menueToLink = (munue) => {
    switch (munue) {
        case "stories":
            return "/index/explore/stories"
        case "comments":
            return "/index/myStuff/comments"
        case "datasets":
            return "/index/explore/datasets"
        //Create a story
        case "story":
            return "/index/story/select/dataset"
        //Learn more
        case "tutorial":
            return "/index/learnMore/tutorial"
        case "about calliope":
            return "/index/learnMore/aboutCalliope"
        case "user manual":
            return "/index/learnMore/userManual"
        //Contact
        case "contact us":
            return "/index/contact/contactUs"
        // case "report a bug":
        //     return "/index/contact/reportABug"
        //Legal
        case "term of use":
            return "/index/legal/termOfUse"
        default:
            return ''
    }
}
export default menueToLink