import React from 'react';
import OperationType from '@/constant/OperationType'
import config from '@/axios/config';
import Fact from '@/model/fact';
import "./index.less";
import AlgorithmSettings from './AlgorithmSettings';
import TopicSettings from './TopicSettings';
import MoreFactsSelections from './MoreFactsSelections';
import RightBottomButton from './RightBottomButton'
import './index.less'


// var settledTimeOut = 0;
// var loopOrNot = false;
// //var animationType = this.props.animationType;
// var animationType = "顺序播放"
// var animateOrNot = false;


const data = [];



//   const SortableItem = sortableElement(props => <tr {...props} />);
//   const SortableContainer = sortableContainer(props => <tbody {...props} />);
export default class FactList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: data,
        }
        // this.filteredgenerate = this.filteredgenerate.bind(this);
    }

    // componentWillMount() {
    //     this.setState({
    //         filters: this.props.filters
    //     })
    // }
    componentDidMount() {

    }

  
    deleteFact = (index) => {
        
        this.props.deleteFact(index);
    }

    clickGenerate = () => {
        this.props.operateGenerating();
        this.setState({
            operateState: OperationType.GENERATING
        });
        this.generate(this.getGenerateURL(config.url.generateRandomlyNew)).then(response => {
            const facts = response.data.story.facts;
            let tempFacts = [];
                for (let factDict of facts) {
                    let fact = new Fact(
                        factDict['type'],
                        factDict['measure'],
                        factDict['subspace'],
                        factDict['groupby'],
                        factDict['focus'],
                        factDict['parameter'],
                        "", // chart
                        factDict['score'],
                        factDict['information'],
                        factDict['significance']
                    )
                    tempFacts.push(fact);
                }
                this.props.generateStory(tempFacts.slice(), [
                    "none",
                    "similarity",
                    "similarity",
                    "similarity",
                    "similarity",
                    "similarity"
                ], 1);
            
        this.props.operateGenerating();
        })
    }


    endEditingTitle = () => {
        if (this.titleRef) {
            let title = this.titleRef.innerText;
            this.props.changeTitle(title);
        }
    }



    render() {

        // const TitleView = () => {
        //     return (
        //         <div className='nameEditor'>
        //             <div className='name'
        //                 ref={(node) => this.titleRef = node}
        //                 contentEditable={true}
        //                 suppressContentEditableWarning="true"
        //                 onBlur={this.endEditingTitle}>{
        //                     title ? title : (initDone && intl.get("nameEditor"))}</div>
        //         </div>)
        // };

        return (
            <div style={{ height: "100%", position: 'relative' }}>
                {/* <Tabs defaultActiveKey="topicFilter-1" > */}
                    {/* <TabPane tab={initDone && intl.get("Data")} key="topicFilter-1"> */}
                        <div className="leftTopicPanel" >
                            {/* <AlgorithmSettings {...this.props}/>                    */}
                            <TopicSettings {...this.props}/>
                            <MoreFactsSelections {...this.props}/>
                         
                        </div>
                        <RightBottomButton {...this.props}/>
                        {/* <div className="leftTopicPanelButton" style={{display:"inline",width:"100%"}}> */}
                    {/* </TabPane> */}
                    {/* <TabPane tab={initDone && intl.get("Design")} key="topicFilter-2">

                    </TabPane> */}

                {/* </Tabs> */}
            </div>
        )
    }
}