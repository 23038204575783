import React, { Component } from 'react';
import { message } from 'antd';
import LayoutType from '@/constant/LayoutType';
import StorylineWebView from './StorylineWebView';
import FactSheetView from './FactSheetView';
import MobileView from './MobileView';
import TextView from './TextView';
import TableView from './TableView';
import { getPublishLink, getEmbedLink } from '../../tool/publish';
import VisOptionView from '@/components/StoryView/VisOptionView/index';
import shareStory from '../../network/shareStory';
import './storyview.less';



export default class StoryView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userEndTag: this.props.userEnd,
            isShareModalVisible: false,
            publishLink: '',
            embedLink: ''
        }
    }
    componentWillMount() {
        const { userEndTag } = this.state
        this.updateVisContainerSize(userEndTag)
    }
    componentWillReceiveProps(newProps) {
        if (newProps.userEnd !== this.state.userEndTag) {
            this.setState({
                userEndTag: newProps.userEnd
            })
            if (newProps.userEnd === 'iphone-pro' || newProps.userEnd === 'iphone') {
                this.props.changeLayOutType(LayoutType.SLIDER_MOBILE)
            }
            this.updateVisContainerSize(newProps.userEnd)
        }
    }

    updateVisContainerSize = (userEnd, layoutType) => {
        let width;
        let height;
        if (!layoutType) {
            layoutType = this.props.layoutType
        }
        //console.log("layoutType", layoutType)
        switch (userEnd) {
            case 'browser-max-pro':
                width = '750'
                if (layoutType === LayoutType.SLIDER_MOBILE || layoutType === LayoutType.FACTSHEET) {
                    height = '550'
                } else if (layoutType === LayoutType.STORYLINE_WEB) {
                    height = '349'
                } else {
                    height = '319'
                }
                break;
            case 'browser-max':
                width = '750'
                if (layoutType === LayoutType.SLIDER_MOBILE || layoutType === LayoutType.FACTSHEET) {
                    height = '550'
                } else if (layoutType === LayoutType.STORYLINE_WEB) {
                    height = '349'
                } else {
                    height = '319'
                }
                break;
            case 'browser-min':
                width = '750'
                if (layoutType === LayoutType.FACTSHEET) {
                    height = '669'
                } else if (layoutType === LayoutType.STORYLINE_WEB) {
                    height = '349'
                }
                else {
                    height = '499'
                }
                break;
            case 'ipad':
                width = '599'
                if (layoutType === LayoutType.FACTSHEET) {
                    height = '626'
                } else if (layoutType === LayoutType.STORYLINE_WEB) {
                    //height = '255'
                    height = '349'
                } else {
                    height = '456'
                }
                break;
            case 'ipad-min':
                width = '574'
                if (layoutType === LayoutType.FACTSHEET) {
                    height = '626'
                } else if (layoutType === LayoutType.STORYLINE_WEB) {
                    //height = '245'
                    height = '349'
                } else {
                    height = '456'
                }
                break;
            case 'iphone-pro':
                width = '352'
                height = '548'
                break;
            case 'iphone':
                width = '352'
                height = '478'
                break;
            default:
                break;
        }
        //console.log("updateVisContainerSize", width, height)
        this.props.updateVisContainerSize({
            width,
            height
        })
    }

    showShareModal = async () => {
        let story = {
            "title": this.props.title,
            "schema": this.props.schema,
            "facts": this.props.facts,
            "aggregationLevel": this.props.aggregationLevel,
            "storyParameter": this.props.storyParameter,
            "resultCoverage": this.props.resultCoverage
        }
        let response = await shareStory(this.props.fileName, story);
        let storyId = response.data.public_id
        this.setState({
            isShareModalVisible: true,
            publishLink: getPublishLink(this.state.layout) + storyId,
            embedLink: getEmbedLink(this.state.layout).replace('{{storyId}}', storyId)
        });
    };

    handleShareCancel = e => {
        this.setState({
            isShareModalVisible: false,
        });
    };

    copy(e) {
        const copyEle = document.querySelector(e) // 获取要复制的节点
        const range = document.createRange(); // 创造range
        window.getSelection().removeAllRanges(); //清除页面中已有的selection
        range.selectNode(copyEle); // 选中需要复制的节点
        window.getSelection().addRange(range); // 执行选中元素
        const copyStatus = document.execCommand("Copy"); // 执行copy操作
        // 对成功与否定进行提示
        if (copyStatus) {
            message.success('copied successfully');
        } else {
            message.fail('copied failed');
        }
        window.getSelection().removeAllRanges(); //清除页面中已有的selection
    }
    changeLayOutTypeListener = (layoutType) => {
        this.updateVisContainerSize(this.state.userEndTag, layoutType)
    }
    render() {
        //console.log("commentPage render ", this.props.visContainerSize.width, this.props.visContainerSize.height)
        let { layoutType, isMobilephoneEnd, storyData, storyRelations, storyFacts } = this.props;
        if (!storyFacts) {
            return (<div className='storyviewWrapper'>
                <div style={{ marginTop: '11px', marginLeft: '15px' }}>
                    <VisOptionView isLoadedInCommentPage={true} changeLayOutTypeListener={this.changeLayOutTypeListener} {...this.props} />
                </div>
            </div >)
        }

        let mobileScale = (this.props.visContainerSize.height - 31) / 530
        // console.log(mobileScale)
        let mobileTop = - (530 - (this.props.visContainerSize.height - 31)) / 2 + 13
        let mobileBottom = - (530 - (this.props.visContainerSize.height - 31)) / 2 + 19
        if (isMobilephoneEnd) {
            layoutType = LayoutType.SLIDER_MOBILE
        }
        storyFacts = storyFacts.map((x, index) => {
            x.index = index;
            return x
        })
        let aggfacts = storyFacts.filter(e => {
            if (!e.aggregated) {
                return true
            } else {
                return e.aggregated && e.aggregatedFact
            }
        })

        let storyview;
        switch (layoutType) {
            case LayoutType.STORY_TEXT:
                storyview = <TextView data={storyData} facts={storyFacts} relations={storyRelations}{...this.props} />
                break;
            case LayoutType.TABLE:
                storyview = <TableView data={storyData} facts={storyFacts} relations={storyRelations} {...this.props} />
                break;
            case LayoutType.STORYLINE_WEB:
                storyview = <StorylineWebView data={storyData} relations={storyRelations} forPublish={true} {...this.props} wholeFacts={this.props.facts} facts={aggfacts} />
                break;
            case LayoutType.SLIDER_MOBILE:
                storyview = <div id='mobile-view' style={{ height: 530, width: 300, margin: '0 auto', marginTop: mobileTop, marginBottom: mobileBottom, transform: 'scale(' + mobileScale + ')' }}>
                    <MobileView {...this.props} data={storyData} facts={storyFacts} relations={storyRelations}
                        forPublish={true} /></div>
                break;
            case LayoutType.FACTSHEET:
                storyview = <FactSheetView data={storyData} facts={storyFacts} relations={storyRelations} {...this.props} forPublish={false} StoryViewFlag={true} />
                break;

            default:
                break;
        }
        return (
            <div className='storyviewWrapper'>
                <div style={{ marginTop: '11px', marginLeft: '15px' }}>
                    <VisOptionView isLoadedInCommentPage={true} changeLayOutTypeListener={this.changeLayOutTypeListener} {...this.props} />
                </div>
                <div>
                    {storyview}
                </div>
            </div >
        )
    }
}
