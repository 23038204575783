import React, { Component } from 'react';
import { Row, Col, Select, Button } from 'antd';
import './FactEditor.less';

export default class BlankView extends Component {

    componentDidMount() {
       
        document.addEventListener('click', this.handleClick)
    
    }
    componentWillUnmount(){
        document.removeEventListener('click', this.handleClick)
    }

    handleClick = e => {
        if(typeof e.target.className !== 'object') {
            let classNameClick = e.target.className;
            if(classNameClick === 'bottomTools' || classNameClick.split(' ')[0] === 'react-transform-component' || classNameClick === 'factsheetContent'){
            this.props.changeCurrentEditing(false, null)
            }
        }
      }
    render() {
        const { intl } = this.props
        return (
            <div>
                <div className='fact-chart-preview'  >
                    <div className="fact-preview" style={{ minHeight: '200px' }}>
                        <div style={{ marginLeft: 6 }}>

                        </div>
                    </div>
                </div>
                {/* <div id='scoreview' >
                </div> */}
                <div id='factconfigure' >
                    <div id="caption">
                    </div>
                    <div id="select-panel" style={{ marginTop: "5px", overflow: "auto", }}>
                        <Row className="shelf">
                            <Col span={8} className="channelName"> {intl.get("type")} </Col>
                            <Col span={16}>
                                <Select className="select-box" id="select-type" defaultValue='' value='' size='small'>
                                </Select>
                            </Col>
                        </Row>
                        <Row className='shelf'>
                            <Col span={8} className='channelName'>{intl.get("measure")}</Col>
                            <Col span={16}>
                                <Col span={14}>
                                    <Select className="select-box" id="select-measure" defaultValue='' value='' size='small'>
                                    </Select>
                                </Col>
                                <Col span={10}>
                                    <Select className="select-box" id="select-agg" defaultValue='' value='' size='small'>
                                    </Select>
                                </Col>
                            </Col>
                        </Row>
                        <Row className={'shelf'}>
                            <Col span={8} className='channelName'>{intl.get("subspace")}</Col>
                            <Col span={16} >
                                <Button id='add-subspace' ref='addSubBtn' size='small'>
                                    +
                            </Button>
                            </Col>
                        </Row>
                        <Row className="shelf">
                            <Col span={8} className="channelName">{intl.get("groupby")}</Col>
                            <Col span={16}>
                                <Select className="select-box" id="select-type" defaultValue='' value='' size='small'>
                                </Select>
                            </Col>
                        </Row>
                        <Row className={'shelf'}>
                            <Col span={8} className='channelName'>{intl.get("focus")}</Col>
                            <Col span={16} >
                                <Button id='add-subspace' ref='addSubBtn' size='small'>
                                    +
                            </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div >
        )
    }
}
