import React, { Component } from 'react'
import { factColors } from '../Color.js'
import EventView from './EventView';
import Script from './Script';
import {message} from 'antd';
// import { Textfit } from '@kyo_ago/react-textfit';

export default class FactSheet extends Component {
    constructor(props) {
        super(props);
        // this.textFitRef = [];
        this.titleRef = React.createRef();
        this.subtitleRef = React.createRef();
        this.textFitRef = {}
    }

    shouldComponentUpdate(nextProps, nextState) {
        // if(this.state.containerWidth === 0)
        //     return true
        // else {
        //     return !_.isEqual(nextProps.fileName, this.props.fileName) || !_.isEqual(nextProps.facts, this.props.facts) || !_.isEqual(nextProps.relations, this.props.relations)  || this.state.containerWidth !== nextState.containerWidth
        // }
        // return !_.isEqual(nextProps.facts, this.props.facts)
        return true
    }

    getOrCreateRef(id) {
        if (!this.textFitRef.hasOwnProperty(id)) {
            this.textFitRef[id] = React.createRef();
        }
        return this.textFitRef[id];
    }



    textFitReady(subtitleRef, styles) {
        // contentEditable=this.props.isCanEditable ? true : false

        // if (titleRef.current==null) return
        if (!subtitleRef) return
        subtitleRef._parent.style.height = 'fit-content';

        if (subtitleRef._parent.clientHeight > parseFloat(styles['height'], 10)) {
            subtitleRef._parent.style['line-height'] = 2;
            subtitleRef._parent.style.transform = 'scale(0.833)';
            subtitleRef._parent.style['margin-top'] = '20px'
        }

        // textFitRef._child.innerHTML = events[i].fact.aggregated && events[i].fact.aggregatedFact ? hightlight(events[i].fact) + hightlight(events[i].fact.aggregatedFact) : hightlight(events[i].fact)
        //textFitRef._child.contentEditable = this.props.forPublish ? false : events[i].fact.aggregated && events[i].fact.aggregatedFact ? false : true
        subtitleRef._child.contentEditable = this.props.forPublish ? false : this.props.isCanEditable ? true : false
        subtitleRef._child.spellCheck = false;
        subtitleRef._child.suppressContentEditableWarning = true;
        let title = subtitleRef._child.innerText
        if(title===""){
            message.info(this.props.intl.get('SubTitle cannot be null'))
            subtitleRef._child.focus()
            subtitleRef._child.innerText=this.props.intl.get('please input title here')
            return
        }
        // record_activity(activitiesCode.editSheetCaption, 1, { type: 'sheet-subtitle', origin: this.props.subtitle, edited: title });
        this.props.changeSubTitle(title);
        // textFitRef._child.addEventListener('blur', ()=>this.endEditing(textFitRef, events, i))
        // textFitRef._child.addEventListener('focus', ()=>this.startEditing(textFitRef,i))
    }
    
    computeDes() {
        let styles = {};
        styles['width'] = '100%';
        styles['height'] = 'fit-content';
        return styles
    }
    
    ComputePdfSize(){
        let styles={}
        if(this.props.factsheetLayoutType==="Horizontal"){
            styles['fontFamily']='sans-serif';
            styles['width']='1300px'
            styles['height']='880px'
            styles['backgroundColor']='#F9F8F6'
            styles['position']='relative'
        }else{
            styles['fontFamily']='sans-serif';
            styles['width']='825px'
            styles['height']='1166px'
            styles['backgroundColor']='#F9F8F6'
            styles['position']='relative'

        }
        return styles
    }
    endEditingTitle = () => {
        let title=this.titleRef.innerText;
        if(title===""){
            message.info(this.props.intl.get('Title cannot be null'))
            this.titleRef.focus()
            this.titleRef.innerText=this.props.intl.get('please input title here')
            return
        }
        this.props.changeTitle(title);
    }
    endEditingSubTitle = () => {
        let title=this.subtitleRef.innerText==="--"?this.props.subtitle:this.subtitleRef.innerText;

        if(title===""){
            message.info(this.props.intl.get('SubTitle cannot be null'))
            this.subtitleRef.focus()
            this.subtitleRef.innerText=this.props.intl.get('please input subtitle here')
            return
        }
        this.props.changeSubTitle(title);
    }


    render() {
        const {factStyle, slotHeight, slotMarginTop, componentRef, events, factLine, title, usertitle ,subtitle,intl} = this.props;
        let storytitle = title;
        if (usertitle !== undefined && usertitle !== "") {
            storytitle = usertitle;
        }
        let subStorytitle =subtitle||"";

        // storytitle = storytitle.toUpperCase();
        return (
            <div className="fsPDF" id="PDF" ref={componentRef} style={this.ComputePdfSize()}>
                <div style={{ height: "55px", alignItems: 'center', display: 'flex' }}>
                    <div className="factsheetContent" style={{ paddingTop: `${slotMarginTop}px`,maxWidth:"100%" }}>
                        <h1 ref={(titleRef)=>this.titleRef =titleRef}
                            spellCheck="false" suppressContentEditableWarning="true" contentEditable={this.props.isCanEditable ? true : false} className="factsheetTitle fs_edit"
                            onBlur={this.endEditingTitle}
                            onKeyDown={(e)=>{
                                if(e.keyCode===13){
                                    if(this.titleRef){
                                        this.titleRef.blur()
                                    }
                                    e.preventDefault()
                                }
                                if(e.keyCode===8){
                                    if(this.titleRef && this.titleRef.innerText.length===1){
                                        e.preventDefault()
                                        message.info(intl.get("Title cannot be null"))
                                    }
                                }
                            }}
                        >{storytitle}
                        </h1>
                    </div>
                </div>
                <div style={{ height: "25px", alignItems: 'center', display: 'flex' }}>
                    <div className="factsheetContent" style={{ paddingTop: `${slotMarginTop}px`,maxWidth:"100%" }}>
                        <p ref={(subtitleRef)=>this.subtitleRef=subtitleRef}
                            spellCheck="false" suppressContentEditableWarning="true" contentEditable={this.props.isCanEditable ? true : false} className="factsheetSubTitle fs_edit"
                            onBlur={this.endEditingSubTitle}
                            onKeyDown={(e)=>{
                                if(e.keyCode===13){
                                    if(this.subtitleRef){
                                        this.subtitleRef.blur()
                                    }
                                    e.preventDefault()
                                }
                                if(e.keyCode===8){
                                    if(this.subtitleRef && this.subtitleRef.innerText.length===1){
                                        e.preventDefault()
                                        message.info(intl.get("SubTitle cannot be null"))
                                    }
                                }
                            }}
                        >
                        {subStorytitle}
                        </p>
                    </div>
                </div>

                <div className={"factsheetContent"} style={{ marginLeft:'',height:'calc(100% - 125px)'}}>
                    {events.map((item, i) =>
                        <div key={i} className={`factsheetSlot factsheetSlot_${factStyle[i]}`} style={{ height: `${slotHeight}px`, marginTop: `${slotMarginTop}px`, boxShadow:item.fact.type===''?'':`inset 0 0 0 2px ${factColors[events[i].fact.type]}, 0 0 0 1px ${factColors[events[i].fact.type]}`,border:item.fact.type===''?`black 3px solid `:'' }}>
                            <div className='factsheetSlotIn'>
                                <div className='factsheetSlotType text-uppercase' >
                                    {item.fact.type}
                                </div>
                                <div className="factsheetSlotContent" style={{ height: `${slotHeight - 50}px`, flexDirection: slotHeight > 235 || factStyle[i] === '4p' || factStyle[i] === '6p' ? (events[i].fact.chart === 'Color Filling Map' && factStyle[i] === '6p') || (events[i].fact.chart === 'Color Filling Map' && factStyle[i] === '4p') ? 'row' : 'column' : 'row' }}>
                                    <Script i={i} slotHeight={slotHeight} item={item.fact} textFitRef={this.getOrCreateRef(i)} factStyle={factStyle} events={events} {...this.props}></Script>
                                    <div className='factsheetSlotIn factsheetVis' style={{
                                        // verticalAlign: 'middle',
                                        // flexGrow: 1,
                                        // display: "flex",
                                        // alignItems: "center",
                                        // justifyContent: "center",
                                        transform: 'translate(0px,-15px)scale(0.9)'
                                    }}>
                                        <EventView key={events[i].id} totalLen={factLine} remainHeight={this.textFitRef[i] === undefined ? slotHeight - 80 - this.textFitRef[i] : -1} outerHeight={slotHeight} index={events[i].index} uuid={events[i].id} fact={events[i].fact} size={factStyle[i]} {...this.props} />
                                    </div>
                                </div>
                            </div>
                            <div className='factsheetSlotId text-uppercase' >
                                {i + 1}
                            </div>
                        </div>
                    )}
                </div>
                {/* <div className="fs-powerby" style={{ height: factLine === 0 ? "30px" : 1166 - 46 - slotMarginTop - 10 - factLine * (slotHeight + slotMarginTop + 8) + 'px' }}> */}
                <div className="fs-powerby" style={{ height: factLine === 0 ? "30px":`${Math.floor(factLine/4)*50+25 }px` }}>
                    <div className="fs-logo"></div>
                    <div style={{ display: 'inline-block' }}>Powered By Calliope</div>
                </div>
            </div>
        )
    }

}