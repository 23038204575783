import React, { Component } from 'react';
import './eventview.less';
import Fact from '@/model/fact'

export default class AddFact extends Component {

    clickAddFact = () => {
        this.props.addFact();
    }
    addEmptyFact=(insert)=>{
        let emptyFact = new Fact(
            '',
            [], 
            [],
            [],
            [],
            [],
            "", // chart
            0,
            0,
            0
        )
        this.props.insertEmptyFact(insert,emptyFact)
    }
    render() {
        return (
            <div id="addfact" onClick={()=>this.addEmptyFact(this.props.events.length)} style={{display:this.props.events.length===0?"flex":"none"}}>
                 <div className="addImage"></div>
                {/* <Icon type="plus" className="addfactIcon" /> */}
            </div>
        )
    }
}
