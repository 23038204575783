import { connect } from 'react-redux';
import * as userAction from '@/action/userAction';
import * as storyAction from '@/action/storyAction';
import CardItemView from './CardItemView';

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = dispatch => {
    return {
        updateOperateRelatedId: ({ did, sid }) => dispatch(userAction.updateOperateRelatedId({ did, sid })),
        updateCommentStoryInfo: (facts, relations, data, schema, title, fileName,real_filename, storyParameter, aggregationLevel, resultCoverage, showSuggestion, subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal ) => dispatch(storyAction.updateCommentStoryInfo(facts, relations, data, schema, title, fileName,real_filename, storyParameter, aggregationLevel, resultCoverage, showSuggestion, subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal ))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardItemView);
