const templates = [
    {
        'id': 0,
        'template': '当{{subspace}}时，总体来看， {{agg}} {{measure}} 在随时间 {{parameter}}.',
    },
    {
        'id': 1,
        'template': '当{{subspace}}时， {{agg}} {{measure}} 总体呈 {{parameter}} 趋势.',
    },
    {
        'id': 2,
        'template': '当{{subspace}}时，总体来看， {{agg}} {{measure}} 在随 {{groupby}} {{parameter}}.',
    },
]

export default templates;