import React from 'react';
// import { Row, Col } from 'antd';

import "./DataShowView.less";

import { Table } from 'antd';
import _ from 'lodash';



function onChange(pagination, filters, sorter, extra) {
  // console.log('params', pagination, filters, sorter, extra);
}




export default class DataShowView extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {
  }


  render() {
    const { data, editstate, editingCurrentFact } = this.props;
 
    let datares = [];
    let currentSubspace=[];

    if(editstate && editingCurrentFact.subspace.length>0){
      let dataclone1 =_.cloneDeep(this.props.dataresLocal)
      let datares1=[]
        currentSubspace=editingCurrentFact.subspace
        currentSubspace.map((item,index)=>{
          if(index===0){
          datares1.push(...dataclone1.filter(filter=>filter[item.field]===item.value))
          dataclone1=datares1
          }else{
            datares1=dataclone1.filter(filter=>filter[item.field]===item.value)
            dataclone1=datares1
          }
          return null
        })
        datares=datares1
      }
    else if(editstate && editingCurrentFact.subspace.length===0){
         datares = _.cloneDeep(this.props.dataresLocal)
          
    }
    // if(datares.length===0) datares=_.cloneDeep(data)

    
    const columns = []
   //重新编辑后dataframe column 消失
    if (data.length > 0) {
      for (let i = 0; i < Object.keys(data[0]).length; i++) {
        columns.push({
          title: `${Object.keys(data[0])[i]}`,
          dataIndex: `${Object.keys(data[0])[i]}`,
          key:`${Object.keys(data[0])[i]}`,
          render: (text) => {
            return (
              <div className="scrolls" key={`column${i}`}>{text}</div>
            )
          }
          // ellipsis: true //tableLayout="fixed"
        });
      }

      for (let i = 0; i < datares.length; i++) {
        datares[i]['key'] = i
      }

    }
    
      return (
        <div className="tableContent">
          <Table columns={columns} dataSource={datares} onChange={onChange} pagination={{ showSizeChanger: false, pageSize: 5, size: 'small' }} scroll={{ x: "300px" }}/>
        </div>
      )
  }
}
