import React,{ Component } from 'react';
import { genFactSentence, genSubtitle } from '../../../sentencer/index';
import FactType from '../../../constant/FactType';
import { isValid, customizeFact } from '../helper';
import { getFactChartType } from '../../../tool/fact2vis';
// import uuidv4 from 'uuid/v4';
// import Association from './Association';
// import Categorization from './Categorization';
// import Difference from './Difference';
// import Distribution from './Distribution';
// import Extreme from './Extreme';
// import Outlier from './Outlier';
// import Proportion from './Proportion';
// import Rank from './Rank';
// import Trend from './Trend';
// import Value from './Value';
import * as api from '@/axios/api';
import _ from 'lodash';
// import activitiesCode from '@/axios/activitiesCode';
// import record_activity from '@/axios/recordactivities';
import RfactsSettings from '../../../pages/CreateStoryPage/FactListViewNew/RfactsSettings'
import "./rightFactChartEditor.less"
import ConfigureChart from './ConfigureChart'

const getFieldValue = (rawData, fieldName) => {
    if (fieldName)
        return Array.from(new Set(rawData.map(d => d[fieldName])));
    else return []
}

const getFieldType = (schema, fieldName) => {
    let temp;
    let tempType;
    if (fieldName) {
        temp = schema.map(d => d["field"] === fieldName ? d["type"] : null)
        for (let i = 0; i < temp.length; i++) {
            if (temp[i]) tempType = temp[i]
        }
    }
    return tempType
}


export default class ConfigureFact extends Component {

    constructor(props) {
        super(props)
        this.scriptRef = React.createRef();

        // this.leftFactChartEditorRef=React.createRef();
        this.state = {
            // script: this.props.editingFact.generatedScript,
            currentFact: this.props.editingFact,
            type: this.props.editingFact.type,
            script: this.props.editingFact.generatedScript,
            // modal
            filterField: '',
            filterValue: '',
            subVisible: false,
            showFocusButton: true,
            showSubButton: true,
            // for show please select
            subSelectValue: 'please select',
            changeFlag: false,
            lang:this.props.currentLocale
        };
    }

    componentDidMount() {
        if (this.visNode) {
            let vis = this.visNode.children[0];
            if (vis)
                vis.style.transform = "translate(-70px,0px)scale(0.75)";
        }
        document.addEventListener('click', this.handleClick)
    }

    static getDerivedStateFromProps(props) {
        if (!props.editing) {
            return {
                currentFact: props.editingFact,
                type: props.editingFact.type,
                script: props.editingFact.generatedScript
            }
        }
        return null
    }
    componentWillUnmount(){
        document.removeEventListener('click', this.handleClick)
    }

    handleClick = e => {
 
        //Attention:判断是否点击空白区域
        // if(this.leftFactChartEditorRef.contains(e.target) || e.target.className === 'ant-select-selection-item' || e.target.className === 'ant-select-item-option-content') {
        //     console.log("click~~~inside")
           
        //     // this.props.changeCurrentEditing(true, this.props.editingFact)
        // } else {
        //     console.log("click~~~outside")
        //     this.props.changeCurrentEditing(false, null)        
        // }

        if(typeof e.target.className !== 'object') {
            console.log("🚀 ~ file: index.js ~ line 117 ~ Fact ~ e.target.className", e.target.className)

            let classNameClick = e.target.className;
            if(classNameClick === 'bottomTools' || classNameClick.split(' ')[0] === 'react-transform-component' || classNameClick === 'factsheetContent'|| classNameClick ==='VisOptionViewDiv'|| classNameClick ==='react-draggable'|| classNameClick ==='rightEditorOperator'|| classNameClick ==='ant-selection-item'|| classNameClick ==='rightEditorOperator'|| classNameClick ==='storyview'|| classNameClick ==='storywebline' || classNameClick==="headerBarWrapper" || classNameClick==="pageWrapper"){           
                this.props.changeCurrentEditing(false, null)   
        }
      }
    }


    handleTypeChange = (value) => {
        let newFact = _.cloneDeep(this.props.editingFact) ;// this.state.currentFact
        newFact.type = value;
        if (value === FactType.ASSOCIATION && newFact.measure.length === 1) {//
            newFact.measure = [newFact.measure[0]]//
        }//
        if (value !== FactType.ASSOCIATION && newFact.measure.length === 2) {
            newFact.measure = [newFact.measure[0]]
        }
        this.setState({//
            type: value,
            changeFlag: true
        })//
        newFact.chart = getFactChartType(this.props.dataresLocal, newFact)
        newFact.focus = Object.assign([], [])
        // record_activity(activitiesCode.editDiagram, 1, {origin: this.props.editingFact, edited: newFact})
        this.updateScoreScript(newFact);
    }

    handleChartChange = (value) => {
        let newFact = _.cloneDeep(this.props.editingFact) 
        newFact.chart = value
        let backendFact = _.cloneDeep(newFact);
        this.setState({
            currentFact: backendFact,
            script: backendFact.generatedScript,
            type: backendFact.type
        });
        // record_activity(activitiesCode.editDiagram, 1, {origin: this.props.editingFact, edited: newFact});
        this.props.updateFact(newFact.index,backendFact)
        this.props.changeCurrentEditing(true,backendFact);

        // this.updateScoreScript(newFact)
    }

    handleAnimationTimeChange = (value) => {
        let newFact = this.props.editingFact;
        newFact.duration = value*1000;
        this.updateScoreScript(newFact);
        
    }



    handleMeasureChange = (value, i) => {
        let newFact = _.cloneDeep(this.props.editingFact) 
        let newList = Object.assign({}, newFact.measure[i]);
        if (value === "COUNT") {
            newList.aggregate = 'count';
            // newList.field = value;
            newFact.measure[i] = newList;
            // let backendFact = _.cloneDeep(newFact);
            // this.props.updateFact(newFact.index,backendFact)
            // record_activity(activitiesCode.editDiagram, 1, {origin: this.props.editingFact, edited: newFact});
            this.updateScoreScript(newFact);
        } else {
            newList.aggregate = 'sum';
            newList.field = value;
            newFact.measure[i] = newList;
            // record_activity(activitiesCode.editDiagram, 1, {origin: this.props.editingFact, edited: newFact});
            this.updateScoreScript(newFact);
        }
       
    }

    handleAGGChange = (value, i) => {
        let newFact = _.cloneDeep(this.props.editingFact) 
        // let newFact = this.state.currentFact;
        let newList = Object.assign({}, newFact.measure[i]);
        if (newList) {
            newList.aggregate = value
            newFact.measure[i] = newList
            // record_activity(activitiesCode.editDiagram, 1, {origin: this.props.editingFact, edited: newFact});
            this.updateScoreScript(newFact)
        }
    }

    handleFilterChange = (value) => {
        let newList = getFieldValue(this.props.dataresLocal, value)
        let newfieldType = getFieldType(this.props.schemaFilterLocal, value)
        this.setState({
            checkAll: true,
            filterField: value,
            subValueList: newList,
            subSelectValue: value,
            subSelectValueType: newfieldType
        })
    }

    onRadioChange = e => {
        this.setState({
            filterValue: e.target.value,
        })
    }

    removeFilter = (value) => {
        let newFact = _.cloneDeep(this.props.editingFact) 
        // let newFact = this.state.currentFact;
        let newList = newFact.subspace
        let index = newFact.subspace.indexOf(value)
        newList.splice(index, 1)
        newFact.subspace = newList
        // record_activity(activitiesCode.editDiagram, 1, {origin: this.props.editingFact, edited: newFact});
        this.updateScoreScript(newFact);
    }

    handleSubOk = e => {
        let newFilter = {
            field: this.state.filterField,
            value: this.state.filterValue
        }
        let newFact = _.cloneDeep(this.props.editingFact) 
        // let newFact = this.state.currentFact;
        let newList = newFact.subspace
        newList.push(newFilter)
        newFact.subspace = newList;

        newFact.generatedScript = genFactSentence(newFact, this.props.intl.options.currentLocale)
        this.setState({
            subVisible: false,
            filterField: '',
            subSelectValue: 'please select',
        });
        // record_activity(activitiesCode.editDiagram, 1, {origin: this.props.editingFact, edited: newFact});
        this.updateScoreScript(newFact)
    };

    handleSubCancel = e => {
        this.setState({
            subVisible: false,
        });
    };

    handleGbChange = (value) => {
        let newFact = _.cloneDeep(this.props.editingFact) 
        // let newFact = this.state.currentFact;
        newFact.groupby = [value]
        // newFact.focus = []
        newFact.focus = Object.assign([], [])
        // record_activity(activitiesCode.editDiagram, 1, {origin: this.props.editingFact, edited: newFact});
        this.updateScoreScript(newFact)
    }

    onFocusClick = () => {
        this.setState({
            showFocusButton: false
        })
    }

    handleFocusChange = (value, focusIndex = 0) => {
        let newFact = _.cloneDeep(this.props.editingFact) 
        // let newFact = this.state.currentFact;
        let newList = newFact.focus

        let newFocus = {
            field: newFact.groupby[0],
            value: value
        }
        if (newFact.type === FactType.EXTREME) {
            newFact.focus[0].value = value.split(':')[1]
            newFact.focus[0].extremeFocus = value.split(':')[0]
            newFact.focus[0].extremeValue = value.split(':')[2]
        } else if (newFact.type === FactType.PROPORTION) {
            if (!newFact.focus.length) {
                newFact.focus = [{ field: newFact.groupby[0] }]
            }
            newFact.focus[0].value = value
        } else if (newFact.type === FactType.DIFFERENCE) {
            if (!newFact.focus.length) {
                newFact.focus[0] = [{ field: newFact.groupby[0] }]
                newFact.focus[1] = [{ field: newFact.groupby[0] }]
            }
            if (focusIndex === 1) {
                newFact.focus[1].value = value
            } else {
                newFact.focus[0].value = value
            }
        } else {
            newList.push(newFocus)
        }
        // this.setState({
        //     showFocusButton: !this.state.showFocusButton
        // })
        this.setState({
            showFocusButton: true
        })
        // record_activity(activitiesCode.editDiagram, 1, {origin: this.props.editingFact, edited: newFact});
        this.updateScoreScript(newFact)
    }

    removeFocus = (value) => {
        let newFact = _.cloneDeep(this.props.editingFact) 
        // let newFact = this.state.currentFact;
        let newList = newFact.focus
        let index = newList.indexOf(value)
        newList.splice(index, 1)
        this.setState({
            showFocusButton: true
        })
        // record_activity(activitiesCode.editDiagram, 1, {origin: this.props.editingFact, edited: newFact});
        this.updateScoreScript(newFact)
    }

    onFocusBlur = () => {
        this.setState({
            showFocusButton: true
        })
    }

    isDisabled = (objArr, key, item) => {
        let newArr = objArr.map((e) => e[key])
        if (newArr.indexOf(item) === -1) return false
        else return true
    }

    showModal = () => {
        this.setState({
            subVisible: true,
        });
    };

    getScoreScript = async (fact) => {
        fact = JSON.stringify(fact);
        let filters = _.cloneDeep(this.props.filtersLocal);
        const response = await api.factScoring(this.props.fileName, fact,JSON.stringify(filters), this.props.method);
        return response.data;
    }

    updateScoreScript = (newFact) => {
        let backendFact = _.cloneDeep(newFact);
        backendFact = customizeFact(backendFact, this.props.schemaFilterLocal, this.props.dataresLocal);
        if (isValid(backendFact)) {
            this.getScoreScript(backendFact).then(res => {
                backendFact.score = res.score
                backendFact.information = res.information
                backendFact.significance = res.significance
                backendFact.parameter = res.parameter
                backendFact.generatedSubtitle = genSubtitle(backendFact)
                backendFact.generatedScript = genFactSentence(backendFact, this.props.intl.options.currentLocale)
                this.setState({
                    currentFact: backendFact,
                    script: backendFact.generatedScript,
                    type: backendFact.type,
                    duration: backendFact.duration
                });
               let backendFactNew=_.cloneDeep(backendFact)
               let measureList = this.props.schemaFilterLocal.filter(key => key['type'] === "numerical")
                if(backendFact.measure.length>0){
                    backendFact.measure.map((item,index)=>{
                        if(item.field==="COUNT") backendFactNew.measure[index].field=measureList[index].field
                        return null
                    })
                }
          
                this.props.updateFact(this.props.editingFact.index, backendFactNew)
                this.props.changeCurrentEditing(true,backendFactNew)
         
            
            }).catch((reason) => {
                console.log(reason);
                backendFact.generatedScript = ''
                this.setState({
                    currentFact: backendFact,
                    script: backendFact.generatedScript,
                    type: backendFact.type,
                    duration: backendFact.duration
                });
                // let backendFactNew=_.cloneDeep(backendFact)
                // let measureList = this.props.schemaFilterLocal.filter(key => key['type'] === "numerical")
                //  if(backendFact.measure.length>0){
                //      backendFact.measure.map((item,index)=>{
                //          if(item.field==="COUNT") backendFactNew.measure[index].filed=measureList[0].filed
                //      })
                //  }
                //  this.props.updateFact(this.props.editingFact.index, backendFactNew)
                //  this.props.changeCurrentEditing(true,backendFactNew)
          
                // this.props.changeCurrentEditing(true,backendFact)
            });
        } else {
            console.log('not valid')
            backendFact.generatedScript = ''
            this.setState({
                currentFact: backendFact,
                script: backendFact.generatedScript,
                type: backendFact.type,
                duration: backendFact.duration
            });
            // this.props.updateFact(this.props.editingFact.index, backendFact)
            // this.props.changeCurrentEditing(true,backendFact)
         

        }
        //todo:index comfirm
        // this.props.updateFact(this.props.index, newFact)
    }

    // clickBack = () => {
    //     this.props.changeCurrentEditing(false, null)
    // }

    // onScriptChange(e) {
    //     this.setState({
    //         script: e.target.value
    //     });

    //     let {editingFact}=this.props;
    //     editingFact.generatedScript=this.state.script;
    //     this.props.updateFact(this.props.editingFact.index,editingFact)        
    // }

    endEditing = () => {
        let script = this.scriptRef.current.innerText;
        let { editingFact } = this.props;
        editingFact.generatedScript = script;
        this.props.updateFact(this.props.editingFact.index, editingFact);
    }

    cancleEditing=() =>{
        this.props.changeCurrentEditing(false, null)
    }

   
    render() {
        let fact = this.props.editingFact;
        // let fact = this.state.currentFact;
        // let type = this.props.editingFact.type;
        // let script = this.props.editingFact.generatedScript;
       
        // const { intl, initDone } = this.props

        // let { schema} = this.props;
      

        
        // let datares =_.cloneDeep(this.props.dataresLocal);     
           
        // let schemaFilter=_.cloneDeep(this.props.schemaFilterLocal);
        // let specData;
        // if (schemaFilter){
        //     specData= {
        //         "schema": schemaFilter,
        //     };
        // }
        // else{
        //     specData= {
        //         "schema": schema
        //     };
        // }
        const factTypeList = []

        for (let key in FactType) {
            if(key==="ASSOCIATION"&&this.props.schemaFilterLocal.filter(key => key['type'] === "numerical").length<2) continue
            factTypeList.push(FactType[key])
        }

        //because count
        let drawFact = _.cloneDeep(fact);
        for (let measure of drawFact.measure) {
            if (measure.aggregate === "count") {
                delete measure.field;
            }
        }
        // let vis;
        // if (isValid(fact)) {
        //     // vis = fact2chart(specData, uuidv4(), drawFact, datares, "large");
        //     vis = fact2chart(specData, uuidv4(), fact, datares, "middle");
        //     // this.props.changeCurrentEditing(editstate, drawFact);
        //     // console.log("Fact -> render -> vis", vis)
        // }

        // let factConfig;
        // //let factType = fact.type;
        // switch (fact.type) {
        //     case FactType.ASSOCIATION:
        //         //factType = intl.get("association")
        //         factConfig = <Association getFieldValue={getFieldValue} isDisabled={this.isDisabled} handleMeasureChange={this.handleMeasureChange} handleAGGChange={this.handleAGGChange} handleFilterChange={this.handleFilterChange} onRadioChange={this.onRadioChange} removeFilter={this.removeFilter} handleSubOk={this.handleSubOk} handleSubCancel={this.handleSubCancel} handleGbChange={this.handleGbChange} onFocusClick={this.onFocusClick} handleFocusChange={this.handleFocusChange} showModal={this.showModal} removeFocus={this.removeFocus} handleChartChange={this.handleChartChange} {...this.state} {...this.props} />
        //         break;
        //     case FactType.CATEGORIZATION:
        //         //factType = intl.get("categorization")
        //         factConfig = <Categorization getFieldValue={getFieldValue} isDisabled={this.isDisabled} handleMeasureChange={this.handleMeasureChange} handleAGGChange={this.handleAGGChange} handleFilterChange={this.handleFilterChange} onRadioChange={this.onRadioChange} removeFilter={this.removeFilter} handleSubOk={this.handleSubOk} handleSubCancel={this.handleSubCancel} handleGbChange={this.handleGbChange} onFocusClick={this.onFocusClick} handleFocusChange={this.handleFocusChange} showModal={this.showModal} removeFocus={this.removeFocus} handleChartChange={this.handleChartChange} {...this.state} onFocusBlur={this.onFocusBlur} {...this.props} />
        //         break;
        //     case FactType.DIFFERENCE:
        //         //factType = intl.get("difference")
        //         factConfig = <Difference updateScoreScript={this.updateScoreScript} getFieldValue={getFieldValue} isDisabled={this.isDisabled} handleMeasureChange={this.handleMeasureChange} handleAGGChange={this.handleAGGChange} handleFilterChange={this.handleFilterChange} onRadioChange={this.onRadioChange} removeFilter={this.removeFilter} handleSubOk={this.handleSubOk} handleSubCancel={this.handleSubCancel} handleGbChange={this.handleGbChange} onFocusClick={this.onFocusClick} handleFocusChange={this.handleFocusChange} showModal={this.showModal} removeFocus={this.removeFocus} handleChartChange={this.handleChartChange} {...this.state} {...this.props} />
        //         break;
        //     case FactType.DISTRIBUTION:
        //         //factType = intl.get("distribution")
        //         factConfig = <Distribution getFieldValue={getFieldValue} getFieldType={getFieldType} isDisabled={this.isDisabled} handleMeasureChange={this.handleMeasureChange} handleAGGChange={this.handleAGGChange} handleFilterChange={this.handleFilterChange} onRadioChange={this.onRadioChange} removeFilter={this.removeFilter} handleSubOk={this.handleSubOk} handleSubCancel={this.handleSubCancel} handleGbChange={this.handleGbChange} onFocusClick={this.onFocusClick} handleFocusChange={this.handleFocusChange} showModal={this.showModal} removeFocus={this.removeFocus} handleChartChange={this.handleChartChange} onFocusBlur={this.onFocusBlur} {...this.state} {...this.props} />
        //         break;
        //     case FactType.EXTREME:
        //         //factType = intl.get("extreme")
        //         factConfig = <Extreme updateScoreScript={this.updateScoreScript} getFieldValue={getFieldValue} isDisabled={this.isDisabled} handleMeasureChange={this.handleMeasureChange} handleAGGChange={this.handleAGGChange} handleFilterChange={this.handleFilterChange} onRadioChange={this.onRadioChange} removeFilter={this.removeFilter} handleSubOk={this.handleSubOk} handleSubCancel={this.handleSubCancel} handleGbChange={this.handleGbChange} onFocusClick={this.onFocusClick} handleFocusChange={this.handleFocusChange} showModal={this.showModal} removeFocus={this.removeFocus} handleChartChange={this.handleChartChange} {...this.state} {...this.props} />
        //         break;
        //     case FactType.OUTLIER:
        //         //factType = intl.get("outlier")
        //         factConfig = <Outlier updateScoreScript={this.updateScoreScript} getFieldValue={getFieldValue} isDisabled={this.isDisabled} handleMeasureChange={this.handleMeasureChange} handleAGGChange={this.handleAGGChange} handleFilterChange={this.handleFilterChange} onRadioChange={this.onRadioChange} removeFilter={this.removeFilter} handleSubOk={this.handleSubOk} handleSubCancel={this.handleSubCancel} handleGbChange={this.handleGbChange} onFocusClick={this.onFocusClick} handleFocusChange={this.handleFocusChange} showModal={this.showModal} removeFocus={this.removeFocus} handleChartChange={this.handleChartChange} {...this.state} {...this.props} />
        //         break;
        //     case FactType.PROPORTION:
        //         //factType = intl.get("proportion")
        //         factConfig = <Proportion updateScoreScript={this.updateScoreScript} getFieldValue={getFieldValue} isDisabled={this.isDisabled} handleMeasureChange={this.handleMeasureChange} handleAGGChange={this.handleAGGChange} handleFilterChange={this.handleFilterChange} onRadioChange={this.onRadioChange} removeFilter={this.removeFilter} handleSubOk={this.handleSubOk} handleSubCancel={this.handleSubCancel} handleGbChange={this.handleGbChange} onFocusClick={this.onFocusClick} handleFocusChange={this.handleFocusChange} showModal={this.showModal} removeFocus={this.removeFocus} handleChartChange={this.handleChartChange}  {...this.state} {...this.props} />
        //         break;
        //     case FactType.RANK:
        //         //factType = intl.get("rank")
        //         factConfig = <Rank updateScoreScript={this.updateScoreScript} getFieldValue={getFieldValue} isDisabled={this.isDisabled} handleMeasureChange={this.handleMeasureChange} handleAGGChange={this.handleAGGChange} handleFilterChange={this.handleFilterChange} onRadioChange={this.onRadioChange} removeFilter={this.removeFilter} handleSubOk={this.handleSubOk} handleSubCancel={this.handleSubCancel} handleGbChange={this.handleGbChange} onFocusClick={this.onFocusClick} handleFocusChange={this.handleFocusChange} showModal={this.showModal} removeFocus={this.removeFocus} handleChartChange={this.handleChartChange} {...this.state} {...this.props} />
        //         break;
        //     case FactType.TREND:
        //         //factType = intl.get("trend")
        //         factConfig = <Trend updateScoreScript={this.updateScoreScript} getFieldValue={getFieldValue} isDisabled={this.isDisabled} handleMeasureChange={this.handleMeasureChange} handleAGGChange={this.handleAGGChange} handleFilterChange={this.handleFilterChange} onRadioChange={this.onRadioChange} removeFilter={this.removeFilter} handleSubOk={this.handleSubOk} handleSubCancel={this.handleSubCancel} handleGbChange={this.handleGbChange} onFocusClick={this.onFocusClick} handleFocusChange={this.handleFocusChange} showModal={this.showModal} removeFocus={this.removeFocus} handleChartChange={this.handleChartChange} onFocusBlur={this.onFocusBlur} {...this.state} {...this.props} />
        //         break;
        //     case FactType.VALUE:
        //         //factType = intl.get("value")
        //         factConfig = <Value getFieldValue={getFieldValue} isDisabled={this.isDisabled} handleMeasureChange={this.handleMeasureChange} handleAGGChange={this.handleAGGChange} handleFilterChange={this.handleFilterChange} onRadioChange={this.onRadioChange} removeFilter={this.removeFilter} handleSubOk={this.handleSubOk} handleSubCancel={this.handleSubCancel} handleGbChange={this.handleGbChange} onFocusClick={this.onFocusClick} handleFocusChange={this.handleFocusChange} showModal={this.showModal} removeFocus={this.removeFocus} handleChartChange={this.handleChartChange} {...this.state} {...this.props} />
        //         break;

        //     default:
        //         break;
        // }

        // const bodyWidth = document.body.clientWidth;

        // const BackView = () => {
        //     return (
        //         <Row className="SaveView">
        //             <Col onClick={() => this.clickBack()}>
        //                 {/* <div ></div> */}
        //                 <p> {initDone && intl.get("Back")}</p>
        //             </Col>
        //         </Row>
        //     )
        // };



        return (
            <div className="rightFactEditor" style={{ height: "100%", position: 'relative', }}>
                    <ConfigureChart {...this.props}/>
                    <RfactsSettings {...this.props}/>   
                {/* <BackView /> */}
            </div>
        )
    }
}
