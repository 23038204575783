import React, { Component } from 'react';
import FactEditor from '../../../view/FactEditorNew';


export default class FactChartEditor extends Component {
    constructor(props) {
        super(props);
        this.factview = React.createRef();
        // this.state = {

        // }
    }
    clickSave = () => {
        this.props.isNowEditing(false,null,null)
    }
    render() {
        const { editstate ,editingCurrentFact ,lang } = this.props;
      
        // isNowEditing(!editstate)
    
        return (
            <div style={{ height: "100%", position: 'relative' }}>

                {/* <Tabs defaultActiveKey="topicFilter-3" > */}
                    {/* <TabPane tab={initDone && intl.get("Data")} key="topicFilter-3"> */}
                    <FactEditor {...this.props} editingFact={editingCurrentFact} ref={el => this.factview = el} editing={editstate} lang = {lang}></FactEditor>
                    
                    
                    {/* </TabPane> */}
                 {/* <TabPane tab={initDone && intl.get("Design")} key="topicFilter-4"></TabPane>  */}

                {/* </Tabs> */}
                
            </div>
                   
           
            )

    }



}