import axios from "axios";
import config from '@/axios/config';

const generateIteratively = async (filename, maxStoryLength, method, start_fact, end_fact, tree, algorithm,filters,similarity,maximumFact) => axios({
    "method": "POST",
    "url": algorithm==="MCTS"?config.url.interpolateMCTS:config.url.interpolateQL,
    "headers": {
        "Content-Type": "application/json; charset=utf-8"
    },
    "data": {
        "file_name": filename,
        "max_story_length": maxStoryLength,
        "filters": filters,
        "method": method,
        "start_fact": start_fact,
        "end_fact": end_fact,
        "tree": tree,
        "similarity":similarity,
        "maximumFact":maximumFact
    }
})

export default generateIteratively;