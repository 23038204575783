import ActionType from '@/action/type';
import LayoutType from '@/constant/LayoutType';
let clickedMenue = localStorage.getItem("clickedMenue") ? JSON.parse(localStorage.getItem('clickedMenue')) : ['Explore', 'stories'];

let lastPage = sessionStorage.getItem('lastPage') ? JSON.parse(sessionStorage.getItem('lastPage')) : '/homePage';
let OperateRelatedId = localStorage.getItem('OperateRelatedId') ? JSON.parse(localStorage.getItem('OperateRelatedId')) : {};
//let layoutType = localStorage.getItem('layoutType') ? JSON.parse(localStorage.getItem('layoutType')) : LayoutType.STORY_TEXT

const initialState = {
    clickedMenue: clickedMenue,
    isCollapsed: true,
    isLeftCollapsed: false,
    updateHeaderbarToggle: false, //默认按钮点击后可以折叠/收缩右侧面板,
    isOpenLeftFloatMenue: false,
    layoutType: LayoutType.STORYLINE_WEB,
    isMobilephoneEnd: false,
    windowUpScroller: {},
    userEndSize: '',
    cardNumber: 5,
    isHiddenCommentAStory: false,
    lastPage,
    OperateRelatedId,
    isShareStory: false,
    isShowGenerate: false,
    newPage: '',
    isToLogout: false,
    isLeaveCreatePage: false,
    isInterruptProcess: false,
    factsheetLayoutType:'Vertical',
};

export default (state = initialState, action) => {
    const newState = Object.assign({}, state);
    switch (action.type) {
        case ActionType.UPDATE_CLICKED_MENUE:
            localStorage.setItem('clickedMenue', JSON.stringify(action.item));
            newState.clickedMenue = action.item;
            //console.log("UPDATE_CLICKED_MENUE",action.item)
            return newState;
        case ActionType.UPDATE_COLLAPSED:
            // localStorage.setItem('isCollapsed', JSON.stringify(action.isCollapsed));
            newState.isCollapsed = action.isCollapsed;
            //console.log("UPDATE_COLLAPSED", newState.isCollapsed)
            return newState;
        case ActionType.UPDATE_LEFT_COLLAPSED:
            // localStorage.setItem('isCollapsed', JSON.stringify(action.isCollapsed));
            newState.isLeftCollapsed = action.isLeftCollapsed;
            //console.log("UPDATE_COLLAPSED", newState.isCollapsed)
            return newState;
        case ActionType.UPDATE_HEADERBAR_TOGGLE:
            newState.updateHeaderbarToggle = action.isChangeToggle;
            return newState;
        case ActionType.OPEN_LEFT_FOLAT_MENUE:
            newState.isOpenLeftFloatMenue = action.isOpenLeftFloatMenue;
            return newState;
        case ActionType.CHANGE_LAYOUT_TYPE:
            //localStorage.setItem('layoutType', JSON.stringify(action.layoutType));
            newState.layoutType = action.layoutType
            return newState;
        case ActionType.CHANGE_USEREND:
            newState.isMobilephoneEnd = action.isMobilephoneEnd
            return newState;
        case ActionType.WINDOW_UP_SCROLL:
            newState.isWindowUpScroll = action.isWindowUpScroll
            return newState;
        case ActionType.UPDATA_USER_END_SIZE:
            newState.userEndSize = action.userEndSize
            //console.log("UPDATA_USER_END_SIZE",action.userEndSize)
            return newState;
        case ActionType.UPDATE_STORY_CARD_NUMBER:
            newState.cardNumber = action.cardNumber
            return newState;
        case ActionType.IS_HIDDEN_COMMENT_STORY:
            newState.isHiddenCommentAStory = action.isHidden
            return newState;
        case ActionType.UPDATE_LAST_PAGE:
            sessionStorage.setItem('lastPage', JSON.stringify(action.lastPage));
            newState.lastPage = action.lastPage
            return newState;
        case ActionType.UPDATE_SID:
            localStorage.setItem('OperateRelatedId', JSON.stringify(action.OperateRelatedId));
            newState.OperateRelatedId = action.OperateRelatedId
            return newState;
        case ActionType.SHARE_STORY:
            newState.isShareStory = action.isShareStory
            //console.log("ActionType.SHARE_STORY",action.isShareStory)
            return newState;
        case ActionType.IS_GENERATE_STORY:
            newState.isShowGenerate = action.isShowGenerate
            return newState;
        case ActionType.TO_NEW_PAGE:
            newState.newPage = action.newPage
            return newState;
        case ActionType.IS_CLICKED_LOGOUT:
            newState.isToLogout = action.isLogOut
            return newState;
        case ActionType.LEAVE_CREATE_PAGE:
            newState.isLeaveCreatePage = action.isLeaving
            return newState;
        case ActionType.IS_INTERRUPT_PROCESS:
            newState.isInterruptProcess = action.isInterruptProcess
            return newState;
        case ActionType.CHANGE_FACTSHEET_LAYOUT_TYPE:
            newState.factsheetLayoutType = action.factsheetLayoutType
            return newState;
        default:
            break;
    }
    return newState;
}