import React, { Component } from 'react';
import { Progress } from 'antd';
import { genStoryText } from '../../../sentencer/index';
import demo from '../../../demo';
import './TextView.less';

export default class TextView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            facts: demo.facts,
            relations: demo.relations
        }
    }

    static getDerivedStateFromProps(nextProps) {
        return {
            relations: nextProps.relations,
            facts: nextProps.facts,
        };
    }

    shouldComponentUpdate(nextProps) {
        let relationUpdate = nextProps.relations.toString() !== this.state.relations.toString()
        let factUpdate = nextProps.facts !== this.state.facts
        let inltDone =nextProps.currentLocale !==this.props.currentLocale
        return relationUpdate || factUpdate || inltDone
    }

    render() {
        const { facts, relations, resultCoverage, storyParameter, manual,intl } = this.props;
        let averageFactScore = (facts.map(x => x.score).reduce((a, b) => a + b, 0)) / facts.length;
        if (!averageFactScore) averageFactScore = 0
        // //Backup: relation
        // let relationText = ''
        // for (const relation of relations) {
        //     if (relation === 'none') {
        //         relationText += relation
        //     } else {
        //         relationText += ('=>'+relation)
        //     }
        // }
        //console.log(relationText);
        //console.log("facts", facts);
        //console.log("relations", relations)
        let text = genStoryText(facts, relations);

        if (manual) {
            return (
                <div style={{ height: '100%', width: 500, margin: '0 auto', fontSize: 16 }}>
                    <h2>{intl.get("Story")}</h2>
                    <div style={{
                        //height: 500,
                        height: 300,
                        width: 500, overflowY: 'auto'
                    }}>
                        <p>{text}</p>
                    </div>
                </div>
            )
        }
        let height;
        let progressbarWidth;
        if (this.props.userEnd.startsWith('iphone')) {
            height = 670
            progressbarWidth = 196
        } else {
            height = 394
            progressbarWidth = 347
        }
        return (
            <div className='summaryView' style={{ height: height }}>
                <div className='headTxt' >{intl.get("Summary")}</div>
                <p className='summaryTxt'>
                    { intl.get("This generated data story includes")}
                     <b>{facts.length}</b>  {intl.get("facts.")}
                     { intl.get("The average importance score is")}  <b>{parseInt(averageFactScore * 1000) / 1000}</b> {intl.get("bit and the data coverage is ")}<b>{parseInt(resultCoverage * 10000) / 100}%</b>.
                </p>
                <div style={{ maxWidth: progressbarWidth }}>
                    <Progress strokeColor={"#F28B30"} percent={(facts.length / storyParameter.maxStoryLength * 100)} format={() => `${facts.length} facts (${facts.length}/${storyParameter.maxStoryLength})`} />
                    <Progress strokeColor={"#F28B30"} percent={(resultCoverage * 10000) / 100} format={percent => `coverage ${parseInt(percent * 10) / 10} %`} />
                    {/* <Progress strokeColor={Color.BLUE} percent={parseInt(averageFactScore * 100)} format={() => `importance ${parseInt(averageFactScore * 1000)/1000}`} /> */}
                </div>
                {/* <h2>Relation</h2>
                <p>{relationText}</p> */}
                <br />
                <div className='headTxt'>{intl.get("StorySummery")}</div>
                <div style={{ flex: 1, width: '100%', paddingBottom: '25px' }}>
                    <p className='detailTxt'>{text}</p>
                </div>
            </div>
        )
    }
}
