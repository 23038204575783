import React, { Component, Suspense } from "react";
import { Spin } from 'antd';
import ExploreStoriesPage from '@/pages/IndexPage/ExploreStories/ExploreStoriesPage';
import SearchResultPage from '@/pages/IndexPage/ExploreStories/SearchResultPage/SearchResultPage';
import ExploreDatasets from '@/pages/IndexPage/ExploreDatasetsPage/ExploreDatasets';
import CommentPage from '@/pages/IndexPage/CommentPage/CommentPage';
import HeadBarView from '@/components/HeadBarView/index';
import IndexLeftMenueView from '@/components/IndexLeftMenueView/index';
import mediaQueryConfig from '@/constant/mediaQueryConfig';
import getUserEndSize from '@/tool/getUserEndSize';
import './indexPageCommon.less'

import {
    HashRouter as Router,
    Switch,
    Route,
    //useParams
} from "react-router-dom";
const UserManualPage= React.lazy(() => import('@/pages/IndexPage/LearnMore/UserManual/UserManualPage'));
const AboutCalliopePage = React.lazy(() => import('@/pages/IndexPage/LearnMore/AboutCalliope/AboutCalliopePage'));
const TutorialPage = React.lazy(() => import('@/pages/IndexPage/LearnMore/Tutorial/TutorialPage'));
const ContactUsPage = React.lazy(() => import('@/pages/IndexPage/ContactUs/ContactUsPage'));
const TermOfUsePage = React.lazy(() => import('@/pages/IndexPage/TermOfUse/TermOfUsePage'));

export default class routeMapping extends Component {
    constructor(props) {
        super(props)
        this.state = {
            lastPage: this.props.lastPage || '/homePage'
        }
    }
    componentWillMount() {
        mediaQueryConfig.map((media) => {
            let mql = window.matchMedia(media)
            this.mediaQueryChanged(mql)
            mql.addListener(this.mediaQueryChanged);
            return media
        })
    }
    componentWillReceiveProps(newProps) {
        if (newProps.history.location.pathname !== this.state.lastPage) {
            this.setState({
                lastPage: newProps.lastPage
            })
            this.props.updateLastPage(this.props.history.location.pathname)
        }
    }

    mediaQueryChanged = (mql) => {
        if (mql.matches) {
            this.props.updateUserEnd(mql.media)
            let userEnd = this.props.userEnd
            if (!userEnd) {
                userEnd = getUserEndSize(mql.media)
            }
            this.changeCardNumber(userEnd)
        }
    }
    changeCardNumber = (userEnd) => {
        switch (userEnd) {
            case "browser-max-pro":
            case "browser-max":
            case "browser-min":
                this.per_page = 5
                break;
            case "ipad":
                this.per_page = 4
                break;
            case "ipad-min":
                this.per_page = 3
                break;
            case "iphone-pro":
            case "iphone":
                this.per_page = 2
                break;
            default:
                this.per_page = 5
                break;
        }
        this.props.updateStoryCardNumber(this.per_page);
    }
    clickBlank = () => {
        //左侧的菜单展开的情况下，点击右侧空白区域，可以关闭
        if (this.props.isOpenLeftFloatMenue) {
            this.props.openLeftFloatMenue(false)
        }
    }
    onCollapsedListener = (isCallapsed) => {
        this.props.setLeftCollapsed(isCallapsed)
    }
    render() {
        const { isLeftCollapsed, isOpenLeftFloatMenue } = this.props;


        const Loading = () => {
            return (<div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', marginTop: "50px" }}>
                <Spin />
            </div>)
        }
        return (
            <div className='pageWrapper'>
                <HeadBarView isLoadedInIndexPage={true} {...this.props} ></HeadBarView>
                <div className='indexPageContent'>
                    <div className='leftFloatMenue' style={{ display: isOpenLeftFloatMenue ? 'block' : 'none' }}>
                        <IndexLeftMenueView isLeftFloatMenue={true}
                            {...this.props} ></IndexLeftMenueView>
                    </div>
                    <div className='leftDiv'
                        style={{ width: isLeftCollapsed ? '50px' : '210px' }}
                    >
                        <IndexLeftMenueView
                            {...this.props} ></IndexLeftMenueView>
                    </div>
                    <div className='indexPageRightContent' onClick={this.clickBlank}>
                        <Router>
                            <Switch>
                                <Route path='/index/commentAStory/:sid' render={
                                    props => {
                                        return <CommentPage {...this.props} match={props.match} ></CommentPage>
                                    }
                                }>
                                </Route>
                                <Route path='/index/existingStoriesOf/:did/:datasetName' render={
                                    props => {
                                        return <SearchResultPage {...this.props} match={props.match} ></SearchResultPage>
                                    }
                                }>
                                </Route>
                                <Route path='/index/explore/stories' render={
                                    props => {
                                        return <ExploreStoriesPage  {...this.props} match={props.match} ></ExploreStoriesPage>
                                    }
                                }>
                                </Route>
                                <Route path='/index/existingStoriesCreatedBy/:uid/:username' render={
                                    props => {
                                        return <SearchResultPage  {...this.props} match={props.match} ></SearchResultPage>
                                    }
                                }>
                                </Route>
                                <Route path='/index/topRatedDataStoriesAbout/:keywords'
                                    render={
                                        props => {
                                            return <SearchResultPage  {...this.props} match={props.match} ></SearchResultPage>
                                        }
                                    }
                                >
                                </Route>
                                <Route path='/index/explore/datasets' render={
                                    props => {
                                        return <ExploreDatasets {...this.props} ></ExploreDatasets>
                                    }
                                }>
                                </Route>
                                <Route path='/index/legal/termOfUse' render={
                                    props => {
                                        return <Suspense fallback={<Loading />}>
                                            <TermOfUsePage {...this.props} ></TermOfUsePage>
                                        </Suspense>
                                    }
                                }>
                                </Route>
                                <Route path='/index/contact/contactUs' render={
                                    props => {
                                        return <Suspense fallback={<Loading />}>
                                            <ContactUsPage {...this.props} ></ContactUsPage>
                                        </Suspense>
                                    }
                                }>
                                </Route>
                                <Route path='/index/learnMore/tutorial' render={
                                    props => {
                                        return <Suspense fallback={<Loading />}>
                                            <TutorialPage {...this.props} ></TutorialPage>
                                        </Suspense>
                                    }
                                }>
                                </Route>
                                <Route path='/index/learnMore/aboutCalliope' render={
                                    props => {
                                        return <Suspense fallback={<Loading />}>
                                            <AboutCalliopePage {...this.props} ></AboutCalliopePage>
                                        </Suspense>
                                    }
                                }>
                                </Route>
                                <Route path='/index/learnMore/userManual' render={
                                    props => {
                                        return <Suspense fallback={<Loading />}>
                                            <UserManualPage {...this.props} ></UserManualPage>
                                        </Suspense>
                                    }
                                }>
                                </Route>
                            </Switch>
                        </Router>
                    </div>
                </div>
            </div >
        )
    }

} 