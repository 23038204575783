const templates = [
    {
        'id': 0,
        'template': 'When {{subspace}}, the {{agg}} {{measure}}  is {{parameter}} over time in general.',
    },
    {
        'id': 1,
        'template': 'When {{subspace}}, the {{agg}} {{measure}}  has a/an {{parameter}} trend.',
    },
    {
        'id': 2,
        'template': 'When {{subspace}}, the {{agg}} {{measure}}  is {{parameter}} over the {{groupby}}s in general.',
    },
]

export default templates;