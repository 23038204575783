import { createSelector } from 'reselect';
import { storyAggregation } from '../tool/factAggregation';
import _ from 'lodash';
// data
export const fileName = state => state.story.fileName;
export const real_filename = state => state.story.real_filename;
export const title = state => state.story.title;
export const subtitle= state => state.story.subtitle;
export const data = state => state.story.data;
export const schema = state => state.story.schema;
export const visData = state => state.story.visData;
export const showSuggestion = state => state.story.showSuggestion;
//editor filters
export const filters = state => state.story.filters;
export const filtersTitle = state => state.story.filtersTitle;
export const datares = state => state.story.datares;
export const schemaFilter = state => state.story.schemaFilter;
export const filtersChangeFlag = state => state.story.filtersChangeFlag;
//filters local
export const filtersLocal = state => state.story.filtersLocal;
export const filtersTitleLocal = state => state.story.filtersTitleLocal;
export const dataresLocal = state => state.story.dataresLocal;
export const schemaFilterLocal = state => state.story.schemaFilterLocal; 
//filters origin 
export const filtersOrigin = state => state.story.filtersOrigin;
export const filtersTitleOrigin = state => state.story.filtersTitleOrigin;
//pannel
export const leftPaneWidth=state=>state.story.leftPaneWidth;
export const rightPaneWidth=state=>state.story.rightPaneWidth;
// story
const originFacts = state => state.story.facts;
export const relations = state => state.story.relations;
export const method = state => state.story.method;
export const selectedFactIndex = state => state.story.selectedFactIndex;
export const hoverFactIndex = state => state.story.hoverFactIndex;
export const maxStoryLength = state => state.story.maxStoryLength;
export const chartDiversity = state => state.story.chartDiversity;
export const information = state => state.story.information;
export const timeLimit = state => state.story.timeLimit;
export const resultCoverage = state => state.story.resultCoverage;
export const aggregationLevel = state => state.story.aggregationLevel;
export const rewardWeight = state => state.story.rewardWeight;
export const uuid = state => state.story.uuid
export const historyStory = state => state.story.historyStory
export const unusedFacts = state => state.story.unusedFacts;
export const editstate=state=>state.story.editstate;
export const editingCurrentFact=state=>state.story.editingCurrentFact
export const algorithm = state => state.story.algorithm
export const calSimilarityType = state => state.story.calSimilarityType
export const maximumFact = state => state.story.maximumFact
export const recommendedSfacts = state => state.story.recommendedSfacts
export const recommendedEfacts = state => state.story.recommendedEfacts
export const recommendedMfacts = state => state.story.recommendedMfacts
// to animation
export const animationFlag = state => state.story.animationFlag;
export const animationType = state => state.story.animationType;
export const specIndex = state => state.story.specIndex;
export const timeline = state => state.story.timeline;
// export const facts = state => {
//     return storyAggregation(state.story.facts, state.story.aggregationLevel)
// }

export const facts = createSelector(
    originFacts,
    aggregationLevel,
    function (originFacts, aggregationLevel) {
        return storyAggregation(originFacts, aggregationLevel)
    }
)


export const storyParameter = createSelector(
    maxStoryLength,
    information,
    chartDiversity,
    timeLimit,
    function (maxStoryLength, information, chartDiversity, timeLimit) {
        return {
            maxStoryLength: maxStoryLength,
            information: information,
            chartDiversity: chartDiversity,
            timeLimit: timeLimit,
        }
    }
)

// fact
export const selectedFact = createSelector(
    facts,
    selectedFactIndex,
    function (facts, selectedFactIndex) {
        return facts[selectedFactIndex]
    }
)
export const isShouldSave = createSelector(
    originFacts,
    title,
    subtitle,
    storyParameter,
    aggregationLevel,
    resultCoverage,
    historyStory,
    function (facts, title,subtitle, storyParameter, aggregationLevel, resultCoverage, historyStory) {
        let {
            historyfacts,
            historyTitle,
            historStoryParameter,
            historyAggregationLevel,
            historyResultCoverage
        } = historyStory
        if (!_.isEqual(facts, historyfacts) || !_.isEqual(title, historyTitle) || !_.isEqual(storyParameter, historStoryParameter)
            || !_.isEqual(aggregationLevel, historyAggregationLevel) || !_.isEqual(resultCoverage, historyResultCoverage)) {
            // console.log("有变化")
            return true
        }
        // console.log("无变化")
        return false
    }
)