import React, { Component } from "react";
// import { message } from 'antd';
import "./socialShare.less";
import { getPublishLink } from '@/tool/publish';
import {
    message,
    // Icon 
} from 'antd';
import * as api from '@/axios/api';
import activitiesCode from '@/axios/activitiesCode';
import config from '@/axios/config';
var QRCode = require('qrcode.react');
let lang;
if (window.location.search) {
    lang = window.location.search.split('=')[1]
}
let URL = `${config.url.publicPrefix}?lang=${lang}#/publish`

export default class socialShareView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shareTitle: 'Calliope: The story of ',
            isShowQRCode: false,
            wechatShareURL: URL + 'mobile/'
        }
    }

    openSharelink = () => {
        window.open(this.sharePublishUrl, '_blank')
    }
    componentDidMount() {
        const { sid } = this.props
        api.getSharedlink(sid).then((response) => {
            let uuid = response.url.split("publish/")[1]
            this.sharePublishUrl = getPublishLink(this.props.layoutType) + uuid
            api.getPublic(uuid).then(async (response) => {
                const { title } = JSON.parse(response.sharedstory.json)
                this.setState({
                    wechatShareURL: `${URL}/mobile/${uuid}` //单独定义微信扫码功能，显示的是mobile layout
                })
                this.initShareComponent(this.sharePublishUrl, title)
            })
            //activitiesApi
            let uid = this.props.userInfo.uid
            let body = {
                action: activitiesCode.shareStory,
                objectid: parseInt(sid),
                meta: response.url,
            }
            api.activitiesApi(uid, body).then(response => {
            })

        }, reject => {
            message.info(this.props.intl.get('Get public share link error'))
        })
    }
    initShareComponent = (publishLink, title) => {
        const { intl } = this.props
        const { shareTitle } = this.state
        if (intl && intl.options.currentLocale === "zh-CN") {
            if (this.weiboPNode) { //动态添加微博分享元素
                let imageNode = document.createElement("button")
                imageNode.setAttribute("class", "weiboShare")
                imageNode.setAttribute("data-sharer", "weibo")
                imageNode.setAttribute("data-title", shareTitle + title)
                imageNode.setAttribute("data-url", publishLink)
                imageNode.setAttribute("data-image", "https://calliope-dev.oss-cn-shanghai.aliyuncs.com/image/logo.png")
                //点击p也可以打开分享功能
                this.weiboPNode.setAttribute("data-sharer", "weibo")
                this.weiboPNode.setAttribute("data-title", shareTitle + title)
                this.weiboPNode.setAttribute("data-url", publishLink)
                this.weiboPNode.setAttribute("data-image", "https://calliope-dev.oss-cn-shanghai.aliyuncs.com/image/logo.png")
                this.weiboPNode.parentNode.insertBefore(imageNode, this.weiboPNode)
                window.Sharer.init();
            }
        } else {
            if (this.TwitterPNode) { //动态添加Twitter分享元素
                let imageNode = document.createElement("button")
                imageNode.setAttribute("class", "twitterShare")
                imageNode.setAttribute("data-sharer", "twitter")
                imageNode.setAttribute("data-title", shareTitle + title)
                imageNode.setAttribute("data-url", publishLink)
                this.TwitterPNode.setAttribute("data-sharer", "twitter")
                this.TwitterPNode.setAttribute("data-title", shareTitle + title)
                this.TwitterPNode.setAttribute("data-url", publishLink)
                this.TwitterPNode.parentNode.insertBefore(imageNode, this.TwitterPNode)
            }

            if (this.FacebookPNode) { //动态添加Facebook分享元素
                let imageNode = document.createElement("button")
                imageNode.setAttribute("class", "facebookShare")
                imageNode.setAttribute("data-sharer", "facebook")
                imageNode.setAttribute("data-url", publishLink)
                this.FacebookPNode.setAttribute("data-sharer", "facebook")
                this.FacebookPNode.setAttribute("data-url", publishLink)
                this.FacebookPNode.parentNode.insertBefore(imageNode, this.FacebookPNode)
            }
            window.Sharer.init();
        }
    }
    generateQRCode = () => {
        this.setState({
            isShowQRCode: true
        })
    }

    render() {
        const { intl } = this.props
        const { isShowQRCode, wechatShareURL } = this.state
        return (
            <div>
                {
                    intl && intl.options.currentLocale === "zh-CN" ?
                        <div className='rowWrapper'>
                            <div> <p ref={(node) => this.weiboPNode = node}>微博</p> </div>
                            <div style={{ marginLeft: "28.5px" }} onClick={this.generateQRCode} ><button className='wechatShare'></button><p>微信</p></div>
                            {/* <div style={{ marginLeft: "28.5px" }} ><button className='toutiaoShare'></button><p>今日头条</p></div> */}
                            <div style={{ marginLeft: "28.5px" }}><button className='linkShare' onClick={this.openSharelink}></button><p>链接</p></div>
                        </div>
                        :
                        <div className='rowWrapper'>
                            <div ><p ref={(node) => this.TwitterPNode = node}>Twitter</p></div>
                            <div style={{ marginLeft: "28.5px" }}><p ref={(node) => this.FacebookPNode = node}>Facebook</p></div>
                            <div style={{ marginLeft: "28.5px" }}>
                                <button className='linkShare' onClick={this.openSharelink}></button><p>Link</p>
                            </div>
                        </div>
                }
                {
                    isShowQRCode ?
                        <div className='qrcodeDiv'>
                            <p >微信扫一扫分享</p>
                            <QRCode value={wechatShareURL} />
                        </div> : null
                }
            </div>
        )
    }
}