export default {
    VALUE: 'value',
    TREND: 'trend',
    CATEGORIZATION: 'categorization',
    DIFFERENCE: 'difference',
    PROPORTION: 'proportion',
    DISTRIBUTION: 'distribution',
    RANK: 'rank',
    ASSOCIATION: 'association',
    EXTREME: 'extreme',
    OUTLIER: 'outlier'
}