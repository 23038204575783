import React from 'react';
import { Collapse,Spin,message} from 'antd';
import DraggableFactCard from './DraggableFactCard';
import OperationType from '@/constant/OperationType'
import Fact from '@/model/fact';
import config from '@/axios/config';
import uuidv4 from 'uuid/v4';
import { filter } from 'lodash';
import * as api from '@/axios/api';
import activitiesCode from '@/axios/activitiesCode';
import _ from 'lodash';
import { genFactSentence,genSubtitle} from '../../../sentencer/index';
import "./index.less";
const { Panel } = Collapse;
const addFactNum = 10;
export default class MoreFactsSelections extends React.Component{
    constructor(props) {
        super(props);
        let lang = props.intl.options.currentLocale
        filter(lang);
        this.state = {
            lang: lang,
        }
    }
    componentDidMount() {
        const { unusedFacts } = this.props;

        if (!unusedFacts.length) {
            this.addFacthere();
        }
      
    }
    // shouldComponentUpdate(nextProps){
    //     if(_.isEqual(nextProps.facts.length,this.props.facts.length)) return false
    //     return true

    // }

    // componentWillUpdate(){
    //     this.setState({
    //         operateState: OperationType.ADDING
    //     })
    // }
    // componentDidUpdate(){
    //     this.setState({
    //         operateState: OperationType.GENERATED
    //     })
    // }

    addFacthere = () => {
        this.setState({
            operateState: OperationType.ADDING
        })
        //TODO:会有重复的fact
        this.adding(config.url.generateRandomlyNew).then(response => {
            const facts = response.data.story.facts;
            let tempFacts = [];
            for (let factDict of facts) {
                let fact = new Fact(
                    factDict['type'],
                    factDict['measure'],
                    factDict['subspace'],
                    factDict['groupby'],
                    factDict['focus'],
                    factDict['parameter'],
                    "", // chart
                    factDict['score'],
                    factDict['information'],
                    factDict['significance']
                );
                fact.uuid = uuidv4();
                tempFacts.push(fact);
            }
            this.props.addFact(tempFacts);
            
        });
    }

    adding = (url) => {
        return new Promise(async (resolve, reject) => {
            let fileName = this.props.fileName;
            let filters = _.cloneDeep(this.props.filtersLocal);
     
            let data;
            if(filters)
            data= {
                file_name: fileName,
                max_story_length: addFactNum,
                topic_filters: JSON.stringify(filters),
                // method: 'mcts'
            }
            else{
                data= {
                    file_name: fileName,
                    max_story_length: addFactNum,
                    //filters: JSON.stringify(filters),
                    // method: 'mcts'
                }
            }
         
            const response = await api.generate(url, data)
            console.log('response-adding', response)
            if (response.data.fail) {
                // message.info(this.props.intl.get("cannot interpolate."))
                this.setState({
                    operateState: OperationType.ADD_FAILED,
                    errorMessage: response.data.fail
                })
                reject()
            }
            if (response.data.error) {
                if(this.props.isEditStory)
                message.info(this.props.intl.get("EditLoading"))
                this.setState({
                    operateState: OperationType.ADD_FAILED,
                    errorMessage: response.data.error
                })
                reject()
            }
            this.setState({
                operateState: OperationType.GENERATED
            })
            resolve(response)
               //activitiesApi
               let uid = this.props.userInfo.uid
               let boby = {
                   action: activitiesCode.generateMoreFacts,
                   objectid: -1,
                   meta: `filename:${fileName}response:${response}`,
               }
               api.activitiesApi(uid, boby).then(response => {
                   //   console.log("activitiesApi",response)
               })
 
        })
    }


    render() {
        const { intl, initDone,unusedFacts} = this.props;
        const { operateState, lang } = this.state;
        let tip = initDone && intl.get("adding");
        unusedFacts.map((item, index) => {
            item.generatedScript = genFactSentence(item, lang)
            item.generatedSubtitle = genSubtitle(item, lang)
            return null
        })
        const AddView = () => {
            return (
                <div className='add'>
                    <div className="addImage" onClick={this.addFacthere}></div>
                </div>
            )
        }
        
        return(
            <Collapse  defaultActiveKey={['datafacts1']}>
                <Panel header={intl.get("Data Fact")} key="datafacts1">
                    <div className="DataFacts">
                        <Spin tip={tip} spinning={operateState === OperationType.ADDING} wrapperClassName="editSpining">
                            {/* <DataFactsTxt /> */}
                            <div className='factsList'>
                                <ul>
                                    {
                                        unusedFacts.map((fact, index) => {
                                            return (<DraggableFactCard key={fact.uuid + fact.index} dataresLocal = {this.props.dataresLocal} schemaFilterLocal = {this.props.schemaFilterLocal}  {...this.props} fact={fact} index={index} id={fact.uuid} isReady={true}/>)
                                        })
                                    }
                                </ul>
                            </div>
                        </Spin>
                    </div>
                    <div>
                        <AddView />
                    </div>
                </Panel>
            </Collapse>
        )
    }
} 