import React from 'react';
import { fact2chart } from '@/tool/fact2vis';
import uuidv4 from 'uuid/v4';
import _ from 'lodash';
import { DragSource } from 'react-dnd';
import { factColors } from '../../../view/StoryViewForComment/Color.js'
import { Tooltip } from 'antd';
import activitiesCode from '@/axios/activitiesCode';
import * as api from '@/axios/api';

// import activitiesCode from '@/axios/activitiesCode';
// import record_activity from '@/axios/recordactivities'
const Types = {
    CARD: 'card'
}

/**
 * Specifies the drag source contract.
 * Only `beginDrag` function is required.
 */
const cardSource = {
    canDrag(props) {
      // You can disallow drag based on props
      return props.isReady
    },
  
    isDragging(props, monitor) {
        // If your component gets unmounted while dragged
        // (like a card in Kanban board dragged between lists)
        // you can implement something like this to keep its
        // appearance dragged:
      return monitor.getItem().id === props.id
    },
  
    beginDrag(props, monitor, component) {
        // Return the data describing the dragged item
        let controls = document.body.querySelectorAll(".dragCard");
        controls[component.props.index].classList.add("dragging");
        const item = { id: props.id }
        return item
    },
  
    endDrag(props, monitor, component) {


        let controls = document.body.querySelectorAll(".dragCard");
        controls[component.props.index].classList.remove("dragging");

        if (!monitor.didDrop()) {
            // You can check whether the drop was successful
            // or if the drag ended but nobody handled the drop
            return
        }

        const dropResult = monitor.getDropResult();
        let {insertFact, index,updateFact} = component.props;
        if(typeof(dropResult.insert) !== "undefined" && !dropResult.updateSingleFlag)
        {
            insertFact(index, dropResult.insert);
              //activitiesApi
              let uid = props.userInfo.uid
              let boby = {
                  action: activitiesCode.insertNewFact,
                  objectid: -1,
                  meta: `insertFact`,
              }
              api.activitiesApi(uid, boby).then(response => {
                  //   console.log("activitiesApi",response)
              })
        }
        if(dropResult.updateSingleFlag){
            updateFact(dropResult.update, props.fact);
        }
    }
}

  /**
   * Specifies which props to inject into your component.
   */
  function collect(connect, monitor) {
    return {
      // Call this function inside render()
      // to let React DnD handle the drag events:
      connectDragSource: connect.dragSource(),
      // You can ask the monitor about the current drag state:
      isDragging: monitor.isDragging()
    }
}


class DraggableFactCard extends React.Component {
    deleteFact = () => {
            this.props.deleteUnusedFact(this.props.index);
    }
    shouldComponentUpdate(nextProps) {
        let {fact} = this.props;
        let nextFact = nextProps.fact;
        return !_.isEqual(fact, nextFact);
    }
    render() {
        let {schema, fact, data,isReady,showSuggestion} = this.props;

        let schemaFilter=_.cloneDeep(this.props.schemaFilterLocal);
        let datares 
        if(this.props.dataresLocal&&this.props.dataresLocal!==[]){    
            datares=_.cloneDeep(this.props.dataresLocal);
        }
        else datares = data
        let specData 
        if (schemaFilter&&schemaFilter!==[]){
            specData= {
                "schema": schemaFilter,
                "showSuggestion": showSuggestion
            };
        }
        else{
            specData= {
                "schema": schema,
                "showSuggestion": showSuggestion
            };
        }
        let uuid = uuidv4();
        let vis = fact2chart(specData, uuid, fact, datares, "small", true);
        let description=fact.generatedSubtitle;
        let descriptionCut=description.length >31 ? description.substring(0, 30)+"…": description
        const FactsDescription = () => {
            return (
                <Tooltip title={`${description}`}>
                <div className="factsDescription">{descriptionCut}</div>
                </Tooltip>
            )        
    };

        // These props are injected by React DnD,
        // as defined by your `collect` function above:
        const {connectDragSource} = this.props;
        return connectDragSource(
            <li>
            <div className={isReady ? "factCard dragCard" :"factCard"}style={{border: `${factColors[fact.type]} 3px solid `}}>
                <FactsDescription/>

                <div className='delete' onClick={() => this.deleteFact()}></div>
                <div className='vis'>
                    {vis}
                </div>
            </div>
            </li>

        )
    }
}

export default DragSource(Types.CARD, cardSource, collect)(DraggableFactCard);
