import React, { Component } from 'react';
import { fact2chart } from '../../../tool/fact2vis';
import './eventview.css';
import { isValid } from '../../FactView/helper'
import _ from 'lodash'
export default class EventView extends Component {

    clickFact = () => {
        const { selectFact, index } = this.props;
        selectFact(index);
    }
    componentDidMount() {
        if (this.visNode) {
            let vis = this.visNode.children[0];
            if (vis)
                vis.style.transform = "translate(-37px,0px)scale(0.64)";
        }
    }
    componentDidUpdate() {
        if (this.visNode) {
            let vis = this.visNode.children[0];
            if (vis)
                vis.style.transform = "translate(-37px,0px)scale(0.64)";
        }
    }
    render() {
        let { uuid, fact,data, schema, showSuggestion } = this.props;
        let vis;
        let schemaFilter=_.cloneDeep(this.props.schemaFilterLocal);
        let datares 
        if(this.props.dataresLocal&&this.props.dataresLocal!==[]){    
            datares=_.cloneDeep(this.props.dataresLocal);
        }
        else datares = data
        let specData 
        if (schemaFilter&&schemaFilter!==[]){
            specData= {
                "schema": schemaFilter,
                "showSuggestion": showSuggestion
            };
        }
        else{
            specData= {
                "schema": schema,
                "showSuggestion": showSuggestion
            };
        }
       // console.log("storyline showSuggestion", showSuggestion)
        if (isValid(fact)) {
            if (fact.aggregated && fact.aggregatedFact) {
                vis = fact2chart(specData, uuid, fact, datares, "small", true,this.props.oldStory);
            } else {
                vis = fact2chart(specData, uuid, fact, datares, "small",false,this.props.oldStory);
            }

        }
        return (
            <div ref={(node) => this.visNode = node}>
                {vis}
            </div>
        )
    }
}
