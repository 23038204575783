const templates = [
    {
        'id': 0,
        'template': 'When {{subspace}}, the {{agg}} {{measure}} of {{focus}} is an outlier when compared with that of other {{groupby}}s.',
    },
    {
        'id': 1,
        'template': 'When {{subspace}}, {{focus}}\'s {{agg}} {{measure}} is anomalously different from that of other {{groupby}}s\'.',
    },
    {
        'id': 2,
        'template': 'When {{subspace}}, the {{agg}} {{measure}} of {{focus}} is anomalously different when compared with that of other {{groupby}}s.',
    },
]

export default templates;