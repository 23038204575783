import React, { Component } from "react";
import HeadBarView from '@/components/HeadBarView/index';
import IndexLeftMenueView from '@/components/IndexLeftMenueView/index';
import CreateStoryView from './CreateStoryView';
import ExploreDatasets from '@/pages/IndexPage/ExploreDatasetsPage/ExploreDatasets';
import mediaQueryConfig from '@/constant/mediaQueryConfig';
import './CreateStoryView.less';

import {
    HashRouter as Router,
    Switch,
    Route
} from "react-router-dom";


export default class indexRoute extends Component {

    componentWillMount() {
        mediaQueryConfig.map((media) => {
            let mql = window.matchMedia(media)
            this.mediaQueryChanged(mql)
            mql.addListener(this.mediaQueryChanged);
            return media
        })
    }
    mediaQueryChanged = (mql) => {
        if (mql.matches) {
            this.props.updateUserEnd(mql.media)
        }
    }
    shouldComponentUpdate(nextProps) {
        if (nextProps.history.location.pathname !== this.props.lastPage) {
            this.props.updateLastPage(nextProps.history.location.pathname)
        }
        return true
    }
    clickBlank = () => {
        //左侧的菜单展开的情况下，点击右侧空白区域，可以关闭
        if (this.props.isOpenLeftFloatMenue) {
            this.props.openLeftFloatMenue(false)
        }
    }
    render() {
        let { isOpenLeftFloatMenue, userEnd } = this.props;
        let isHiddenLeftMenue = userEnd.startsWith('i') //ipad 与iphone

        return (
            <div className='pageWrapper'>
                <HeadBarView isLoadedInIndexPage={true} {...this.props} />
                <div className='CreatStoryPageContent'>
                    <div className='leftFloatMenue' style={{ display: isOpenLeftFloatMenue ? 'block' : 'none' }}>
                        <IndexLeftMenueView
                            {...this.props}
                            isLoadedInCreatedPage={true}
                            isLeftCollapsed={false}>
                        </IndexLeftMenueView>
                    </div>
                    <div className='leftDiv' style={{ width: '50px', display: isHiddenLeftMenue ? "none" : 'block' }}>
                        <IndexLeftMenueView
                            {...this.props}
                            isLoadedInCreatedPage={true}
                            isLeftCollapsed={true} >
                        </IndexLeftMenueView>
                    </div>
                    <div className="CreatMiddlePage"
                        onClick={this.clickBlank}>
                        <Router>
                            <Switch>
                                <Route exact path="/index/story/create" render={
                                    props => {
                                        return <CreateStoryView match={props.match} {...this.props} />
                                    }
                                }>
                                </Route>
                                <Route exact path="/index/story/select/dataset">
                                    <ExploreDatasets {...this.props} isLoadedInCreatePage={true}></ExploreDatasets>
                                </Route>
                                <Route exact path="/index/story/edit/:sid" render={
                                    props => {
                                        return <CreateStoryView isEditStory={true} match={props.match} {...this.props} />
                                    }
                                }>
                                </Route>
                            </Switch>
                        </Router>
                    </div>
                </div>
            </div>)
    }
}