import React, { Component } from 'react';
import { Tabs, message, Pagination, Divider } from 'antd';
import NoBoderRadiusSearchView from '@/components/SearchView/NoBoderRadiusSearchView/NoBoderRadiusSearchView';
import DatasetsTable from '@/components/Datasets/DatasetsTable/DatasetsTable';
import UploadPannelView from '@/components/Datasets/UploadPannel/UploadPannelView';
import * as api from '@/axios/api';
import activitiesCode from '@/axios/activitiesCode';
// import InfiniteScroll from 'react-infinite-scroller';
import _ from 'lodash';
import './ExploreDatasets.less';

const { TabPane } = Tabs;

export default class ExploreDatasets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            datasets: [],
            myDatasets: [],
            loading: false,
            hasMore: true,
            currentMenu: 'Published datasets',
            page: 0,
            defaultInputValue: '',
            isLoadedAll: false,
            totalCount: 0,
            totalPage: 0,
            pageShow: true
        }
    }

    componentWillMount() {
        this.queryPublishedDatasets();
    }

    search = (keywords) => {
        if (this.state.currentMenu !== 'Published datasets') {
            message.info(this.props.intl.get("search Published datasets only"))
            return;
        }
        this.setState({
            defaultInputValue: keywords
        })
        this.queryPublishedDatasets(keywords)
    }

    uploadNewData = (file, newData) => {
        //上传
        let formData = new FormData();
        formData.append("file", file);
        api.uploadData(formData).then((reslove) => {
            if (reslove.status === 'error') {
                let { intl } = this.props
                // console.log("intl", intl.options.currentLocale)
                let errorMessage = intl.options.currentLocale === 'zh-CN' ? reslove.message_zh : reslove.message_en;
                message.info(errorMessage)
                return null;
            }
            this.postDataset(reslove.file_url, reslove.schema, newData)
        })
    }

    postDataset = (fileUrl, schema, newData) => {
        let body = {
            data: newData.data,
            source: newData.source,
            keywords: newData.keywords,
            file: fileUrl,
            size: newData.size,
            schema: JSON.stringify(schema)
        }
        const { intl } = this.props
        api.postADataset(body).then((response) => {
            if (response && response.success) {
                message.info(intl.get("upload successfully"))
                this.setState({
                    currentMenu: 'My datasets'
                })
                //上传成功后，重新拉取数据
                this.queryMyDatasets((list) => {
                    if (Array.isArray(list) && list.length > 0) {
                        const filter = list.filter(item => item && item.did === response.did);

                        if (filter && filter.length > 0) {
                            this.setState({ dataDetail: filter[0] });
                        }
                    }
                })
                //activitiesApi
                let uid = this.props.userInfo.uid
                let body = {
                    action: activitiesCode.uploadDatasets,
                    objectid: response.did,
                    meta: fileUrl,
                }
                api.activitiesApi(uid, body).then(response => { });
            }
        }, (reject) => {
            message.info(intl.get("upload error"))
        })
    }

    queryMyDatasets = (callback) => {
        this.props.windowUpScroller(true)
        let params = {
            per_page: 15,
            page: 0,
            sort: 'time'
        }
        this.setState({
            page: 0,
            pageShow: false
        })
        let userId = this.props.userInfo.uid
        api.queryDatasetsByUserId(userId, params).then((response) => {
            this.props.windowUpScroller(false)
            this.setState({
                pageShow: true,
                myDatasets: response.datasets,
                totalCount: response.totalCount,
            }, () => {
                if (typeof callback === 'function') {
                    callback(response.datasets);
                }
            })
        })
    }

    PageMyDatasetsOnChange = (page) => {
        if (this.state.isLoadedAll) return
        this.props.windowUpScroller(true)
        let nextPage = page - 1;
        let params = {
            per_page: 15,
            page: nextPage,
            sort: 'time'
        }
        this.setState({
            page: nextPage,
            pageShow: true
        })
        let userId = this.props.userInfo.uid
        api.queryDatasetsByUserId(userId, params).then((response) => {
            this.props.windowUpScroller(false)
            if (response.datasets.length === 0) {
                this.setState({
                    isLoadedAll: true,
                })
            } else {
                let currentDatasets = response.datasets
                this.setState({
                    myDatasets: currentDatasets,
                })
            }
        })
    }

    queryPublishedDatasets = (keywords) => {
        this.props.windowUpScroller(true)
        let params;
        this.setState({
            pageShow: false
        })
        if (!keywords) {
            params = {
                per_page: 15,
                //page: this.state.page,
                page: 0,
                sort: 'time'
            }
        } else {
            params = {
                keywords,
                per_page: 15,
                //page: this.state.page,
                page: 0,
                sort: 'time'
            }
            let uid = this.props.userInfo.uid
            let boby = {
                action: activitiesCode.searchDatasets,
                objectid: -1,
                meta: keywords,
            }
            api.activitiesApi(uid, boby).then(response => {
            })

        }
        api.queryPublishedDatasets(params).then((response) => {
            this.props.windowUpScroller(false)
            this.setState({
                page: 0,
                datasets: response.datasets,
                pageShow: true
            })
            if (!keywords) {
                this.setState({
                    totalCount: response.totalCount,
                    totalPage: Math.ceil(response.totalCount / 15)
                })
            } else {
                this.setState({
                    totalCount: response.total.value,
                    totalPage: Math.ceil(response.total.value / 15)
                })
            }
        })
    }

    pagePublishedNumberOnChange = (page) => {
        if (this.state.currentMenu !== 'Published datasets') return
        if (this.state.isLoadedAll) return
        this.props.windowUpScroller(true)
        let params;
        let keywords = this.state.defaultInputValue
        let nextPage = page - 1;
        if (!keywords) {
            params = {
                per_page: 15,
                page: nextPage,
                sort: 'time'
            }
        } else {
            params = {
                keywords,
                per_page: 15,
                page: nextPage,
                sort: 'time'
            }
            let uid = this.props.userInfo.uid
            let boby = {
                action: activitiesCode.searchDatasets,
                objectid: -1,
                meta: keywords,
            }
            api.activitiesApi(uid, boby).then(response => {
            })
        }
        this.setState({
            page: nextPage,
            pageShow: true
        })
        api.queryPublishedDatasets(params).then((response) => {
            this.props.windowUpScroller(false)
            if (response.datasets.length === 0) {
                this.setState({
                    isLoadedAll: true,
                })
            } else {
                let currentDatasets = response.datasets
                this.setState({
                    datasets: currentDatasets,
                })
            }
        })
    }

    handleInfiniteOnLoad = () => {
        if (this.state.currentMenu !== 'Published datasets') return
        if (this.state.isLoadedAll) return
        this.props.windowUpScroller(true)
        let params;
        let keywords = this.state.defaultInputValue
        let nextPage = this.state.page + 1
        this.setState({
            page: nextPage
        })
        if (!keywords) {
            params = {
                per_page: 10,
                page: nextPage,
                sort: 'time'
            }
        } else {
            params = {
                keywords,
                per_page: 10,
                page: nextPage,
                sort: 'time'
            }
            let uid = this.props.userInfo.uid
            let boby = {
                action: activitiesCode.searchDatasets,
                objectid: -1,
                meta: keywords,
            }
            api.activitiesApi(uid, boby).then(response => {
            })
        }
        api.queryPublishedDatasets(params).then((response) => {
            this.props.windowUpScroller(false)
            if (response.datasets.length === 0) {
                this.setState({
                    isLoadedAll: true
                })
            } else {
                let currentDatasets = _.cloneDeep(this.state.datasets).concat(response.datasets)
                this.setState({
                    datasets: currentDatasets
                })
            }
        })
    }

    handleClick = (key) => {
        this.setState({
            currentMenu: key,
            isLoadedAll: false,
            page: 0
        })
        if (key === 'My datasets') {
            this.queryMyDatasets();
        } else {
            if (this.state.defaultInputValue) {
                this.queryPublishedDatasets(this.state.defaultInputValue);
            } else {
                this.queryPublishedDatasets();
            }
        }
    }

    visualize = () => {
        setTimeout(() => {
            this.props.history.push('/index/story/create')
        }, 500);
    }

    resetSearch = () => {
        this.setState({
            defaultInputValue: ''
        })
        this.queryPublishedDatasets();
    }

    render() {
        const { datasets, myDatasets, defaultInputValue, isLoadedAll } = this.state
        const { isLoadedInCreatePage, isLogIn, intl } = this.props

        return <div className='ExploreDatasetsPageContent'>
            <div className='datasetsDescription'>
                <p className='Datasets'>{intl.get("Datasets")}</p>
                {
                    isLoadedInCreatePage ?
                        <div className='Description'>
                            <div className='rowDiv' style={{ marginBottom: '15px' }}>
                                <div className='number'>1</div>
                                <span>{intl.get("Upload or select")}</span>
                            </div>
                            <div className='rowDiv'>
                                <div className='number'>2</div>
                                {intl.getHTML("createStep2")}
                            </div>
                            <p className='PrepareDataTxt' style={{ marginTop: "16px" }}>
                                {intl.get("Prepare Your Data")}
                            </p>
                            <p className="mb_0">{intl.getHTML("Description")}</p>
                        </div>
                        :
                        <div className='Description'>
                            <p >{intl.getHTML('Description1')}
                            </p>
                            <p >{intl.getHTML('Description2')}
                            </p>
                            <p className='PrepareDataTxt'>
                                {intl.get("Prepare Your Data")}
                            </p>
                            <p className="mb_0">{intl.getHTML("Description")}</p>
                        </div>
                }

            </div>
            <div style={{ marginBottom: '26px', display: isLogIn ? "block" : "none" }} >
                <UploadPannelView intl={intl} onUploadDataListener={(originFileOb, newData) => { this.uploadNewData(originFileOb, newData) }}></UploadPannelView>
            </div>
            <NoBoderRadiusSearchView defaultValue={defaultInputValue}
                search={this.search}
                onResetSearchListener={() => this.resetSearch()}
                intl={intl}
            />
            <div className='dataSetsTable'>
                <Tabs defaultActiveKey={this.state.currentMenu} activeKey={this.state.currentMenu} onChange={this.handleClick}>
                    <TabPane tab={intl.get("Published datasets")} key="Published datasets">
                        <DatasetsTable datasets={datasets} isLoadedAll={isLoadedAll} isShowEditAndDelete={false} onClickVisualize={this.visualize} {...this.props}></DatasetsTable>
                        {(this.state.pageShow && this.state.totalCount !== 0) ? (<div className="DataSetsFootPage">
                            <Divider />
                            <Pagination size="small" current={this.state.page + 1} total={this.state.totalCount} pageSize={15} onChange={this.pagePublishedNumberOnChange} />
                        </div>) : null
                        }
                    </TabPane>
                    {
                        isLogIn ?
                            <TabPane tab={intl.get("My datasets")} key="My datasets">
                                <DatasetsTable
                                    datasets={myDatasets}
                                    dataDetail={this.state.currentMenu === 'My datasets' ? this.state.dataDetail : undefined}
                                    isLoadedAll={false}
                                    isShowEditAndDelete={true}
                                    onClickVisualize={this.visualize}
                                    updateMyDatasets={this.queryMyDatasets}
                                    {...this.props}></DatasetsTable>
                                {(this.state.pageShow && this.state.totalCount !== 0) ? (<div className="DataSetsFootPage">
                                    <Divider />
                                    <Pagination size="small" current={this.state.page + 1} total={this.state.totalCount} pageSize={15} onChange={this.PageMyDatasetsOnChange} />
                                </div>) : null
                                }
                            </TabPane>
                            :
                            null
                    }
                </Tabs>
            </div>
        </div>
        //     <InfiniteScroll
        //     initialLoad={false}
        //     pageStart={0}
        //     loadMore={this.handleInfiniteOnLoad}
        //     hasMore={!this.state.loading && this.state.hasMore}
        //     useWindow={false}>
        // </InfiniteScroll >
    }
}