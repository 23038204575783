import { connect } from 'react-redux';
import LoginLoading from './LoginLoading';
import { isLogIn, lastPage } from '@/selector/user';

const mapStateToProps = (state) => ({
    isLogIn: isLogIn(state),
    lastPage: lastPage(state),
})

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginLoading);
