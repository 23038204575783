import React, { Component } from "react";
import { WithOutContext as ReactTags } from 'react-tag-input-no-dnd';
// import TagsInput from 'react-tagsinput'
import './TagsInputView.less'
const KeyCodes = {
    space: 32,
    tab: 9,
    semicolon: 186,
    enter: 13,
    comma: 188
};
const delimiters = [KeyCodes.space, KeyCodes.enter, KeyCodes.semicolon, KeyCodes.tab, KeyCodes.comma];

export default class TagsInputView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tags: this.props.tags
        }
        this.handleDelete = this.handleDelete.bind(this);
        this.handleAddition = this.handleAddition.bind(this);
        // this.handleDrag = this.handleDrag.bind(this);
    }
    componentWillReceiveProps(newProps) {
        if (newProps.tags !== this.state.tags) {
            this.setState({
                tags: newProps.tags
            })
        }
    }
    handleDelete(i) {
        const { tags } = this.state;
        let newTags = tags.filter((tag, index) => index !== i)
        this.props.onTagsChangedListener(newTags)
        this.setState({
            tags: newTags
        })
    }

    handleAddition(tag) {
        let newTags = [...this.state.tags, tag]
        this.props.onTagsChangedListener(newTags)
        this.setState({
            tags: newTags
        })
    }

    // handleDrag(tag, currPos, newPos) {
    //     const tags = [...this.state.tags];
    //     const newTags = tags.slice();
    //     newTags.splice(currPos, 1);
    //     newTags.splice(newPos, 0, tag);
    //     this.props.onTagsChangedListener(newTags)
    //     this.setState({
    //         tags: newTags
    //     })
    // }

    render() {
        const { tags } = this.state;
        // console.log("tags", tags, tags.length)
        return (
            <div className='datakeyWordsPannel'>
                <div className='keywordImage'></div>
                <div className='InputTagDiv'>
                    <ReactTags
                        inputFieldPosition="inline"
                        placeholder={this.props.intl.get("input a keyword")}
                        tags={tags}
                        handleDelete={this.handleDelete}
                        handleAddition={this.handleAddition}
                        // handleDrag={this.handleDrag}
                        handleInputChange={this.handleInputChange}
                        delimiters={delimiters} />
                </div>
            </div>
        )
    }
}