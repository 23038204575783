import { fact2visRules } from './fact2visRule';
import { fact2visAllRules } from './fact2visAllRule';
import FieldType from '@/constant/FieldType';

/****
 * isAllMatch 
 * 手动选择过程 41种
 * 生成过程推荐27种
 */
const getSupportedChartTypes = (fact, schema, isAllMatch = false) => {
    let breakDown = schema.filter(s => s["field"] === fact.groupby[0])[0];
    let supportedChartTypes;
    let rules = isAllMatch ? fact2visAllRules : fact2visRules;

    if (isAllMatch) {
        supportedChartTypes = rules.filter(x => x.fact === fact.type);
        return supportedChartTypes;
    }
    supportedChartTypes = rules.filter(x => x.fact === fact.type);

    if (breakDown) {
        supportedChartTypes = supportedChartTypes.filter(x => x.breakdownType.indexOf(breakDown.type) !== -1);

        /*******important**** 找不到推荐的图表，就用可以支持的图表显示*****/
        if (supportedChartTypes.length === 0 && !isAllMatch) {
            supportedChartTypes = getSupportedChartTypes(fact, schema, true)
            let breakDown = schema.filter(s => s["field"] === fact.groupby[0])[0];
            if (breakDown) {
                supportedChartTypes = supportedChartTypes.filter(x => x.breakdownType.indexOf(breakDown.type) !== -1);
            }
        }
        /*******important the end *********/

        if (breakDown.type === FieldType.CATEGORICAL && breakDown.values) {
            supportedChartTypes = supportedChartTypes.filter(x => {
                if (!x.rang) {
                    return true
                } else {
                    return breakDown.values.length >= x.rang[0] && breakDown.values.length <= x.rang[1]
                }
            });
        }
    }
    //console.log("supportedChartTypes", supportedChartTypes)
    return supportedChartTypes;
};
export default getSupportedChartTypes;