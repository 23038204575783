import React, { Component } from "react";
import { Table, Icon, Input, Spin, message, Modal } from 'antd';
import TagsInputView from './TagsInput/TagsInputView';
import _ from 'lodash';
import * as api from '@/axios/api';
import * as d3 from 'd3';
import config from '@/axios/config';
import activitiesCode from '@/axios/activitiesCode';
import DatasetDetail from '@/components/Datasets/DatasetDetail/DatasetDetail';
import './DatasetsTable.less';

var moment = require('moment');
const compareTime = new Date("2021-11-2")
export default class DatasetsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            datasets: this.props.datasets,
            isIpadView: false,
            isShowModal: false,
            isShowDetail: false,
            modalType: '',
            operateIndex: 0,
            sortTimeOrder: 'descend',
            isWindowUpScroll: this.props.isWindowUpScroll || false
        }
        this.operatingDataTitle = ''
        this.operatingSource = ''
    }
    componentWillMount() {
        // 创建媒体查询列表对象
        var mql = window.matchMedia(`(max-width: 1300px)`);
        this.mediaQueryChanged(mql)
        // 添加监听器
        mql.addListener(this.mediaQueryChanged);
        this.setState({
            isWindowUpScroll: false
        })
    }
    componentWillReceiveProps(newProps) {
        if (newProps.datasets !== this.state.datasets) {
            this.setState({
                datasets: newProps.datasets
            })
        }
        if (!_.isEqual(newProps.dataDetail, this.props.dataDetail) && !_.isEmpty(newProps.dataDetail)) {
            this.setState({ isShowDetail: true, detailRecord: newProps.dataDetail });
        }
    }
    computeData = (originData) => {
        const { isShowEditAndDelete, userInfo } = this.props
        return originData.map((dataset, index) => {
            let keywords = dataset.keywords.split(';');
            let tags = keywords.map((keyword) => {
                return {
                    id: keyword,
                    text: keyword
                }
            })
            return {
                ...dataset,
                tags,
                added: moment(dataset.added).format('YYYY-M-D H:m:s'),
                contributor: isShowEditAndDelete ? userInfo.username : dataset.user.username,
                size: dataset.size % 1000 === 0 ? dataset.size / 1000 + 'k' : (dataset.size / 1000).toFixed(2) + 'k',
            }
        })
    }
    mediaQueryChanged = (mql) => {
        if (mql.matches) {
            //console.log("小于1300px")
            this.setState({
                isIpadView: true
            })

        } else {
            this.setState({
                isIpadView: false
            })
            //console.log("大于1300px")
        }
    }

    viewExistingStories = (text, record, index) => {
        let datasetName = this.state.datasets[index].data
        let did = record.did
        this.props.history.push({
            pathname: `/index/existingStoriesOf/${did}/${datasetName}`
        })
    }

    visualize = (data) => {
        if (compareTime > new Date(data.added.split(" ")[0])) {
            message.info(this.props.intl.get("parse file error"))
            return
        }
        if (this.props.userInfo && this.props.userInfo.uid === -1) {
            window.location.href = config.url.loginRedirectUrl //login first
            return
        }
        // process data according to schema
        let schema = JSON.parse(data && data.schema) && JSON.parse(data && data.schema).fields
        let fileURL = config.url.uploadDataPrefix + data.url
        // let fileName = data.url.split('/data/')[1]
        let fileName = data.url.split('/')[data.url.split('/').length - 1]
        let real_filename = data.data
        this.process(schema, fileURL)
            .then((response) => {
                if (!this.props.isLogIn) {
                    if (process.env.NODE_ENV === 'production') {
                        window.location.href = config.url.loginRedirectUrl //login first
                    } else {
                        this.props.updateOperateRelatedId({ ...this.props.OperateRelatedId, did: data.did })
                        this.props.visualizeData(data)
                        this.props.onClickVisualize()
                    }
                    return
                } else {
                    this.props.updateOperateRelatedId({ ...this.props.OperateRelatedId, did: data.did })
                    this.props.visualizeData(data)
                    this.props.onClickVisualize()
                }
                this.props.connectData(fileName, real_filename, schema, response)
            }).catch((reason) => {
                message.info(this.props.intl.get("parse file error"))
                console.log(reason);
            });

    }
    process = (schema, fileURL) => {
        return new Promise((resolve, reject) => {
            let numericalFields = []
            let numerical = schema.filter(d => d.type === "numerical")
            numericalFields = numerical.map(d => d.field)

            // let temporialFields = []
            // let temporial = schema.filter(d => d.type === "temporal")
            // temporialFields = temporial.map(d => d.field)
            d3.csv(fileURL)
                .then(function (data) {
                    data.forEach((d, i) => {
                        for (let key in d) {
                            if (numericalFields.indexOf(key) !== -1) {
                                d[key] = parseFloat(d[key])
                            }
                            // if (temporialFields.indexOf(key) !== -1) {
                            //     // d[key] = d[key].replace(/-/g, '/')
                            //     let fomatedValue = '';
                            //     d[key].split("-").map((d, i) => {
                            //         d = parseInt(d);
                            //         if (i === 0) {
                            //             fomatedValue += `${d}`;
                            //         } else {
                            //             fomatedValue += `/${d}`;
                            //         }
                            //         return d;
                            //     })
                            //     d[key] = fomatedValue;//2020-03-01 转换为 2020/3/1
                            // }
                        }
                    })
                    resolve(data);
                }).catch(function (error) {
                    reject(error);
                })
        });
    }
    confirmDelete = () => {
        const { datasets, operateIndex } = this.state
        //deleteDatasetById
        let did = datasets[operateIndex].did
        api.deleteDatasetById(did).then(result => {
            let newDatasets = _.cloneDeep(this.state.datasets)
            newDatasets.splice(operateIndex, 1);
            this.setState({
                operateIndex: '',
                isShowModal: false,
                modalType: '',
                datasets: newDatasets
            })
            this.postActivity(
                {
                    action: activitiesCode.deleteDatasets,
                    objectid: datasets[operateIndex].did,
                    meta: datasets[operateIndex].url
                }
            )

            //重新拉取数据
            //this.props.updateMyDatasets();
        }, reject => {
            this.setState({
                operateIndex: '',
                isShowModal: false,
                modalType: '',
            })
            //重新拉取数据
            //this.props.updateMyDatasets();
        })
    }
    cancel = () => {
        this.setState({
            isShowModal: false
        })
    }
    delete = (text, record, index) => {
        this.setState({
            isShowModal: true,
            modalType: 'delete',
            operateIndex: index
        })
    }

    edit = (text, record, index) => {
        //console.log("edit", index, this.props.datasets, this.props.datasets[index])
        this.setState({
            isShowModal: true,
            modalType: 'edit',
            operateIndex: index
        })
    }
    onChange = (pagination, filters, sorter, extra) => {
        const newDatasets = extra.currentDataSource
        this.setState({
            datasets: newDatasets
        })
        // console.log('params', pagination, filters, sorter, extra);
    }

    closeModal = () => {
        this.setState({
            isShowModal: false
        })
    }
    tagsChanged = (tags) => {
        this.currentTags = tags
    }

    onInputChange = (value, type) => {
        if (type === 'dataTitle') {
            this.operatingDataTitle = value
        } else {
            this.operatingSource = value
        }
    }

    Update = () => {
        const { datasets, operateIndex } = this.state
        let newDatasets = _.cloneDeep(datasets);
        //有编辑过tags
        if (this.currentTags) {
            let displayKeyWords = '';
            let lastTagIndex = this.currentTags.length
            this.currentTags.map((tag, index) => {
                if (index === lastTagIndex - 1) {
                    displayKeyWords = displayKeyWords.concat(tag.text)
                } else {
                    displayKeyWords = displayKeyWords.concat(tag.text + ';')
                }
                return tag
            })
            newDatasets[operateIndex].keywords = displayKeyWords
        }

        let did = newDatasets[operateIndex].did
        let body = {
            data: this.operatingDataTitle === '' ? newDatasets[operateIndex].data : this.operatingDataTitle,
            source: this.operatingSource === '' ? newDatasets[operateIndex].source : this.operatingSource,
            keywords: newDatasets[operateIndex].keywords
        }
        const { intl } = this.props
        //数据检验
        if (!body.data) {
            message.info(intl.get("please upload your data"))
            return
        }
        let keywordsArray = body.keywords.split(';');
        if (keywordsArray.length < 3) { //tag不能少于三个
            message.info(intl.get("please input at least 3 tags"))
            return
        }
        if (body.source === '') { //source不能为空
            message.info(intl.get("please input a data source"))
            return
        }

        api.updateADataset(did, body).then(result => {
            newDatasets.splice(operateIndex, 1, {
                ...newDatasets[operateIndex],
                data: this.operatingDataTitle === '' ? newDatasets[operateIndex].data : this.operatingDataTitle,
                source: this.operatingSource === '' ? newDatasets[operateIndex].source : this.operatingSource,
                keywords: newDatasets[operateIndex].keywords
            })
            this.setState({
                isShowModal: false,
                datasets: newDatasets
            })
            this.postActivity(
                {
                    action: activitiesCode.editDatasets,
                    objectid: datasets[operateIndex].did,
                    meta: datasets[operateIndex].keywords
                }
            )
            //重新拉取数据
            //this.props.updateMyDatasets();
        }, reject => {
            this.setState({
                isShowModal: false
            })
            //重新拉取数据
            //this.props.updateMyDatasets();
        })
    }
    postActivity = ({ action, objectid, meta }) => {
        //activitiesApi
        let uid = this.props.userInfo.uid
        let body = {
            action,
            objectid,
            meta,
        }
        api.activitiesApi(uid, body).then(response => {
        })
    }

    render() {
        const { datasets, isShowModal, modalType, operateIndex } = this.state;
        let datasetsInTable = this.computeData(datasets)
        //console.log("datasetsInTable", datasetsInTable)
        const { isShowEditAndDelete, isLoadedAll, intl } = this.props
        let columns = [
            {
                title: intl.get("columns.Data"),
                dataIndex: 'data',
                key: 'data',
                width: isShowEditAndDelete ? '132px' : '190px'
            },
            {
                title: intl.get("columns.Keywords"),
                dataIndex: 'keywords',
                key: 'keywords',
                width: isShowEditAndDelete ? '153px' : '203px'
            },
            {
                title: intl.get("columns.Source"),
                dataIndex: 'source',
                key: 'source',
                width: isShowEditAndDelete ? '94px' : '112px'
            },
            {
                title: intl.get("columns.Contributor"),
                dataIndex: 'contributor',
                key: 'contributor',
                width: isShowEditAndDelete ? '89px' : "102px"
            },
            {
                title: intl.get("columns.Size"),
                dataIndex: 'size',
                key: 'size',
                width: isShowEditAndDelete ? '61px' : '65px'
            },
            {
                title: intl.get("columns.Added"),
                dataIndex: 'added',
                key: 'added',
                width: '109px',
                //sortOrder: this.state.sortTimeOrder,
                // sorter: (a, b) => {
                //     // this.setState({
                //     //     sortTimeOrder: "descend"
                //     // })
                //     return this.state.sortTimeOrder === 'descend' ? false : true;
                // },
            },
            {
                title: intl.get("columns.Data Detail"),
                dataIndex: 'detail',
                key: 'detail',
                align: 'center',
                width: '75px',
                render: (text, record, index) => (
                    <Icon type=" " className='detail' onClick={() => this.setState({ isShowDetail: true, detailRecord: record })}></Icon>
                ),
            },
            {
                title: intl.get("columns.Existing Stories"),
                dataIndex: 'num_of_visualized',
                key: 'num_of_visualized',
                align: 'center',
                width: '75px',
                render: (text, record, index) => (
                    <Icon type=" " className='existingStories' onClick={() => this.viewExistingStories(text, record, index)} ></Icon>
                ),
            }
        ];
        if (isShowEditAndDelete) {
            columns.push({
                title: intl.get("Edit"),
                key: 'Edit',
                align: 'center',
                width: '75px',
                render: (text, record, index) => (
                    <Icon type=" " className='editIcon' onClick={() => this.edit(text, record, index)} />
                ),
            },
                {
                    title: intl.get("Delete"),
                    key: 'Delete',
                    align: 'center',
                    width: '75px',
                    render: (text, record, index) => (
                        <Icon type=" " className='deleteIcon' onClick={() => this.delete(text, record, index)} />
                    ),
                })
        }
        columns.push({
            title: intl.get("columns.Generate"),
            align: 'center',
            key: 'visualize',
            fixed: 'right',
            width: '75px',
            render: (text, record) => (
                <div onClick={() => this.visualize(record)}>
                    <Icon type=" " className='generateIcon'></Icon>
                </div>
            ),
        })
        const InputView = (props) => {
            const { inputTitle, inputValue, onInputChange } = props;
            return (
                <div className='Pannel'>
                    <span>{inputTitle}</span>
                    <Input
                        className='inputStyle'
                        placeholder={inputValue}
                        // value={sourceValue}
                        onChange={(e) => {
                            onInputChange(e.target.value)
                        }}
                    ></Input>
                </div>
            )
        }
        const FooterView = (props) => {
            const { leftTxt, rightTxt, onClickRightButton } = props;
            return (<div className='deleteWrapper'>
                <div className='centerWrapper' onClick={() => this.cancel()}>
                    <span>{leftTxt}</span>
                </div>
                <div className='centerWrapper' onClick={() => {
                    onClickRightButton();
                }}>
                    <span>{rightTxt}</span>
                </div>
            </div >);
        }

        return (
            <div className="datasetsTable" style={{ backgroundColor: 'white', marginTop: '40px' }}>
                <Spin tip={intl.get("Loading")} spinning={this.props.isWindowUpScroll}>
                    <Table columns={columns} dataSource={datasetsInTable} style={{ width: '100%' }}
                        rowKey={record => record.did}
                        size={'small'}
                        pagination={false}
                        tableLayout="fixed"
                        onChange={this.onChange}
                        scroll={{ x: 719 }} />
                    {
                        isShowModal ?
                            modalType === 'delete' ?
                                <div className='deleteModal'>
                                    <div className='titleDiv'>
                                        <div>{intl.get("Delete")}</div>
                                        <Icon type='close' onClick={this.closeModal} className='closeBtn' />
                                    </div>
                                    <div className='content'>
                                        <Icon type='close-circle' theme="filled" style={{ fontSize: '26px', marginRight: '10px', color: '#F28B30' }} />
                                        <span style={{ lineHeight: '26px' }}>{intl.get("deleteWarning")}</span>
                                    </div>
                                    <FooterView leftTxt={intl.get("Cancel")} rightTxt={intl.get("Delete")} onClickRightButton={() => this.confirmDelete()} onClickLeftButton={() => this.cancel()} />
                                </div>
                                :
                                <div className='editModal'>
                                    <div className='titleDiv'>
                                        <Icon type='close' onClick={this.closeModal} className='closeBtn' />
                                    </div>
                                    <div className='editContent'>
                                        <div className='sourceAndKeywordsInput' >
                                            <InputView inputTitle={intl.get("Data title")} inputValue={datasetsInTable[operateIndex].data || intl.get("dataTitleHint")} onInputChange={(value) => this.onInputChange(value, 'dataTitle')} />
                                            <InputView inputTitle={intl.get("Source")} inputValue={datasetsInTable[operateIndex].source || intl.get("sourceHint")} onInputChange={(value) => this.onInputChange(value, 'source')} />
                                        </div>
                                        <div className='editKeyWords'>
                                            <span className='Keywords'>{intl.get("Keywords")}</span>
                                            <div style={{ width: '100%', height: '100px', marginTop: '15px' }}>
                                                <TagsInputView
                                                    intl={intl}
                                                    tags={datasetsInTable[operateIndex].tags}
                                                    onTagsChangedListener={(tags) => this.tagsChanged(tags)} />
                                            </div>
                                        </div>
                                    </div>
                                    <FooterView leftTxt={intl.get("Cancel")} rightTxt={intl.get("Update")} onClickRightButton={() => this.Update()} onClickLeftButton={() => this.Cancel()} />
                                </div>
                            :
                            null
                    }
                    {/* {isWindowUpScroll && (
                    <div className="loading-container">
                        <Spin tip={intl.get("Loading")} />
                    </div>
                )} */}
                    {isLoadedAll && (
                        <span style={{ margin: '0 auto', color: 'grey', paddingTop: "20px" }}> {intl.get("no more dataset")}</span>
                    )}
                </Spin>
                {this.state.isShowDetail &&
                    <Modal
                        footer={null}
                        visible={this.state.isShowDetail}
                        width={(document.getElementsByClassName('ExploreDatasetsPageContent')[0]?.offsetWidth || 1020) + 60}
                        wrapClassName='customModalWrapper'
                        onCancel={() => this.setState({ isShowDetail: false, detailRecord: undefined })}
                    >
                        <DatasetDetail data={this.state.detailRecord} intl={intl} />
                    </Modal>
                }
            </div>

        )
    }
}