import React, { Component } from "react";
import findKeyframesRule from '@/tool/findKeyFramesRule';
import LayoutType from '@/constant/LayoutType';
import './StoryTitle.less';
import { message } from "antd";
const compareTime=new Date("2021-11-2")
export default class StoryTitle extends Component {
    componentDidUpdate() {
        const { isScroll } = this.props;
        if (isScroll) {
            let parentWidth = this.titleNode.parentElement.clientWidth
            if (this.titleNode.scrollWidth > parentWidth) {
                let keyframes = findKeyframesRule("move_right_left");
                //console.log("StoryTitle keyframes",keyframes)
                // 删除已经存在的开始和结束帧
                keyframes.deleteRule("100%");
                keyframes.appendRule("100% { -webkit-transform: translateX(-" + this.titleNode.scrollWidth / 2 + "px); }");
                this.titleNode.style.animation = 'move_right_left 3s linear 1s infinite alternate'
                this.titleNode.style.overflow = 'visible'
            }
        } else {
            this.titleNode.style.animation = null
            this.titleNode.style.overflow = 'hidden'
        }
    }


    shareStory = (e) => {
        if (e.stopPropagation) {
            e.stopPropagation();
        } else {
            e.cancelBubble = true;
        }
        const { story, OperateRelatedId } = this.props
        if(story){
            let oldStory=compareTime>new Date(story.time.split("T")[0])
            if(oldStory){
                message.info(this.props.intl.get("share story error"))
                return
            }
        }
        this.props.changeLayOutType(LayoutType.STORYLINE_WEB)
        this.props.onShareAStory()
        this.props.updateOperateRelatedId({ ...OperateRelatedId, sid: story.sid })
    }

    delete = (e) => {
        if (e.stopPropagation) {
            e.stopPropagation();
        } else {
            e.cancelBubble = true;
        }
        this.props.OnDeleteAStory()
    }
    edit = (e) => {
        if (e.stopPropagation) {
            e.stopPropagation();
        } else {
            e.cancelBubble = true;
        }
        this.props.OnEditAStory()
    }
    render() {
        const { storyTitle, isMyStories } = this.props;
        return <div className='storyTitleWrapper'>
            <div className='animationWrapper'
            // onMouseEnter={this.mouseHover}
            // onMouseLeave={this.mouseLeave}
            >
                <span ref={(node) => this.titleNode = node} className='titleTxt'>{storyTitle}</span>
            </div>
            <div className='functionDiv'>
                {
                    isMyStories ?
                        <div className='editStory' onClick={(e) => this.edit(e)}></div>
                        : null
                }
                {
                    isMyStories ?
                        <div className='deleteStory' onClick={(e) => this.delete(e)}></div>
                        : null
                }
                <div className='shareStory' onClick={(e) => this.shareStory(e)}></div>
            </div>
        </div>
    }

}