import { connect } from 'react-redux';
import IndexLeftMenueView from './IndexLeftMenueView';
import { isLogIn, getUserInfo, selectedMenue, userEnd, isShowGenerate } from '@/selector/user';
import { isShouldSave } from '@/selector/story';
import * as userAction from '../../action/userAction';

const mapStateToProps = (state) => ({
    isLogIn: isLogIn(state),
    userInfo: getUserInfo(state),
    selectedMenue: selectedMenue(state),
    userEnd: userEnd(state),
    isShowGenerate: isShowGenerate(state),
    isShouldSave: isShouldSave(state),
})

const mapDispatchToProps = dispatch => {
    return {
        updateLastPage: (lastPage) => dispatch(userAction.updateLastPage(lastPage)),
        updateClickedMenus: (item) => dispatch(userAction.updateClickedMenus(item)),
        setLeftCollapsed: (isLeftCollapsed) => dispatch(userAction.setLeftCollapsed(isLeftCollapsed)),
        isChangeToggle: (isChangeToggle) => dispatch(userAction.updateHeaderbarToggle(isChangeToggle)),
        toNewPage: (page) => dispatch(userAction.toNewPage(page)),
        leaveCreatePage: (isLeaving) => dispatch(userAction.leaveCreatePage(isLeaving)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IndexLeftMenueView);
