import React, { Component } from 'react';
import './ProjectDescription.less';
import '@/pages/common.less';


export default class ProjectDescription extends Component {
    render() {
        const { intl } = this.props
        return (
            <div className='adsDiv rowDiv' style={{ position: 'relative', alignItems: "flex-end" }}>
                <div className='adsTxt columnDiv' style={{ alignItems: 'flex-start', justifyContent: 'center' }}>
                    <span>Calliope·Data</span>
                    <span>{intl.get("slogan")}</span>
                </div>
                <div className='adsUndrawCard' ></div>
            </div >
        )
    }
}

