import React, { Component } from "react";
import { Layout, Empty, Spin } from "antd";
import CardList from '@/components/CardListView';
import NoBoderRadiusSearchView from '@/components/SearchView/NoBoderRadiusSearchView/NoBoderRadiusSearchView';
import * as api from '@/axios/api';
import activitiesCode from '@/axios/activitiesCode';
import uuidv4 from 'uuid/v4';
//import _ from 'lodash';
import '../ExploreStoriesPage.less';
import '@/pages/common.less';


export default class SearchResultPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowLoading: true,
            page: 0,
            keywords: null,
            did: null,
            uid: null,
            pageTitle: '',
            defaultInputValue: '',
        }
        this.per_page = 5
    }
    componentWillMount() {
        let { keywords, did, datasetName, uid, username } = this.props.match.params;
        if (keywords) {
            this.setState({
                defaultInputValue: keywords
            })
            this.queryByKeyWords(keywords)
            return
        }
        if (did) {
            this.queryByDatasetId(did, datasetName)
            return
        }
        if (uid) {
            this.queryByUserId(uid, username)
            return
        }
    }
    componentWillReceiveProps(newProps) {
        let { keywords, did, datasetName, uid, username } = newProps.match.params;
        if (keywords && keywords !== this.state.keywords) {
            this.setState({
                keywords,
                defaultInputValue: keywords
            })
            this.queryByKeyWords(keywords)
            return
        }
        if (did && did !== this.state.did) {
            this.setState({
                did,
                defaultInputValue: ''
            })
            this.queryByDatasetId(did, datasetName)
            return
        }

        if (uid && uid !== this.state.uid) {
            this.setState({
                uid,
                defaultInputValue: ''
            })
            this.queryByUserId(uid, username)
            return
        }

    }
    queryByUserId = (uid, username) => {
        api.queryStoriesByUserId(uid).then((response) => {
            this.setState({
                pageTitle: this.props.intl.get("Existing stories created by", { username: username }) || '',
                uid
            })
            this.processChartData(response.stories)
        })
    }
    queryByDatasetId = (did, datasetName) => {
        api.queryStoriesByDatasetId(did).then(story => {
            this.setState({
                pageTitle: this.props.intl.get("Existing stories of", { datasetName: datasetName }) || '',
                did
            })
            this.processChartData(story)
        })
    }
    queryByKeyWords = (keywords) => {
        //关键字填充
        this.setState({
            pageTitle: this.props.intl.get("Recently published data stories"),
            keywords
        })
        let params = {
            keywords,
            per_page: this.per_page * 5,
            page: '0',
            sort: 'time'
        }

        api.queryTopRatedDataStories(params).then((response) => {
            this.setState({
                searchResult: response.total.value
            })
            this.processChartData(response.stories)
            //activitiesApi
            let uid = this.props.userInfo.uid
            let boby = {
                action: activitiesCode.searchStories,
                objectid: -1,
                meta: keywords,
            }
            api.activitiesApi(uid, boby).then(response => {
                //   console.log("activitiesApi",response)
            })
        }, reject => {
        })
    }
    processChartData = (storyList) => {
        let newStoriesList = storyList.map(story => {
            const { facts, relations, data, schema, showSuggestion } = JSON.parse(story.json)
            story = { ...story, facts, relations, data, schema, showSuggestion }
            return story
        })
        newStoriesList.map(story => {
            story.MaxSignificanceId = 0
            let events = story.facts.map(function (fact, i, facts) {
                //significance最高的显示第一帧chart画面
                if (fact.significance > facts[story.MaxSignificanceId].significance) {
                    story.MaxSignificanceId = i; //增加字段，保存用来显示第一帧的fact id
                }
                return {
                    id: uuidv4(),
                    index: i,
                    fact: fact,
                    relation: story.relations[i],
                }
            })
            events.filter(fact => {
                if (!fact.aggregated) {
                    return true
                } else {
                    return fact.aggregated && fact.aggregatedFact
                }
            })
            story.events = events;
            let averageFactScore = (events.map(x => x.fact.score).reduce((a, b) => a + b, 0)) / events.length;
            if (!averageFactScore) averageFactScore = 0;
            story.averageFactScore = averageFactScore;
            return story
        })
        this.setState({
            dataStories: newStoriesList,
            isShowLoading: false
        })
    }
    onSearchListener = (value) => {
        if (value === '') {
            this.props.history.push({
                pathname: `/index/explore/stories`
            })
        } else {
            this.props.history.push({
                pathname: `/index/topRatedDataStoriesAbout/${value}`
            })
        }
    }

    resetSearch = () => {
        this.props.history.push({
            pathname: `/index/explore/stories`
        })
    }
    render() {
        const { pageTitle, dataStories, isShowLoading, defaultInputValue } = this.state
        const { getCardNumber, intl } = this.props
        this.per_page = getCardNumber

        return <div className='ExploreStoriesPageContent responsivePageContent'>
            <NoBoderRadiusSearchView defaultValue={defaultInputValue}
                search={(value) => this.onSearchListener(value)}
                onResetSearchListener={() => this.resetSearch()}
                intl={intl}
            />
            <div>
                <span className='pageTitle'>{pageTitle}</span>
                <div className='line'></div>
                {
                    dataStories ? <span className='foundAbout'>{`${intl.get("story.list.Found about")} ${dataStories.length} ${intl.get("story.list.results")}`}</span>
                        :
                        null
                }
                <Layout className='ExploreStoriesList' style={{ marginTop: '20px' }}>
                    {
                        isShowLoading ?
                            <div className="loading-container">
                                <Spin tip={intl.get("Loading")} />
                            </div>
                            :
                            dataStories && dataStories.length === 0 ?
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                :
                                <CardList stories={dataStories} {...this.props} />
                    }
                </Layout>
            </div>
        </div >
    }
}