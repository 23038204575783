import { connect } from 'react-redux';
import * as userAction from '@/action/userAction';
import * as visAction from '@/action/visAction';
import * as storyAction from '@/action/storyAction';
import { userEnd, getUserInfo, OperateRelatedId, isShowGenerate, isInterruptProcess } from '@/selector/user';
import { isShouldSave, showSuggestion } from '@/selector/story';
import VisOptionView from './VisOptionView';

const mapStateToProps = (state) => ({
    userEnd: userEnd(state),
    userInfo: getUserInfo(state),
    OperateRelatedId: OperateRelatedId(state),
    isShowGenerate: isShowGenerate(state),
    isInterruptProcess: isInterruptProcess(state),
    isShouldSave: isShouldSave(state),
    showSuggestion: showSuggestion(state)
})

const mapDispatchToProps = dispatch => {
    return {
        shareStory: (isShareStory) => dispatch(userAction.shareStory(isShareStory)),
        loadingGeneratingView: (isGenerateStory) => dispatch(userAction.loadingGeneratingView(isGenerateStory)),
        changeLayOutType: (layoutType) => dispatch(userAction.changeLayOutType(layoutType)),
        updateVisContainerSize: (containerSize) => dispatch(visAction.updateVisContainerSize(containerSize)),
        updateCommentStoryInfo: (facts, relations, data, schema, title, fileName, real_filename,storyParameter, aggregationLevel, resultCoverage, showSuggestion, subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal ) => dispatch(storyAction.updateCommentStoryInfo(facts, relations, data, schema, title, fileName,real_filename, storyParameter, aggregationLevel, resultCoverage, showSuggestion,subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal )),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VisOptionView);
