import React, { Component } from 'react';
import { Menu, Icon } from 'antd';
import './IndexLeftMenueView.less';
const { SubMenu } = Menu;

export default class IndexLeftMenueView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLeftCollapsed: this.props.isLeftCollapsed,
      userEnd: this.props.userEnd
    };
  }
  componentWillMount() {
    //以/index/xxx 开头的页面，全局配置
    //小于1300px收起左侧的menue,只显示小图标(creat页面除外)
    if (this.props.userEnd === 'browser-min' || this.props.userEnd.startsWith('i') || this.props.isLoadedInCreatedPage) {
      this.setState({
        isLeftCollapsed: true,
      })
      this.props.setLeftCollapsed(true)
      this.props.isChangeToggle(true)

    } else {
      this.setState({
        isLeftCollapsed: false,
      })
      this.props.setLeftCollapsed(false)
      if (!this.props.isLoadedInCreatedPage) { //CreatedPage不需要更改
        this.props.isChangeToggle(false)
      }

      this.props.openLeftFloatMenue(false)
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.isLeftCollapsed !== this.state.isLeftCollapsed) {
      this.setState({
        isLeftCollapsed: newProps.isLeftCollapsed
      })
    }
    if (newProps.userEnd !== this.state.userEnd) {
      if (newProps.userEnd === 'browser-min' || newProps.userEnd.startsWith('i') || this.props.isLoadedInCreatedPage) {
        this.setState({
          isLeftCollapsed: true,
          userEnd: newProps.userEnd
        })
        this.props.setLeftCollapsed(true)
        this.props.isChangeToggle(true)
      } else {
        this.setState({
          isLeftCollapsed: false,
          userEnd: newProps.userEnd
        })
        this.props.setLeftCollapsed(false)
        if (!this.props.isLoadedInCreatedPage) { //CreatedPage不需要更改
          this.props.isChangeToggle(false)
        }
        this.props.openLeftFloatMenue(false)
      }
    }
  }

  onClick = ({ key }) => {
    let page = '/';
    // console.log("onClick", key)
    switch (key) {
      case 'stories':
        this.props.updateClickedMenus(['explore', 'stories'])
        page = '/index/explore/stories'
        break;
      case 'datasets':
        this.props.updateClickedMenus(['explore', 'datasets'])
        page = '/index/explore/datasets'
        break;
      case 'creatStories':
        this.props.updateClickedMenus(['create', 'story'])
        page = "/index/story/select/dataset"
        break;
      case 'tutorial':
        this.props.updateClickedMenus(['learnMore', 'tutorial'])
        page = "/index/learnMore/tutorial"
        break;
      case 'aboutCalliope':
        this.props.updateClickedMenus(['learnMore', 'about calliope'])
        page = "/index/learnMore/aboutCalliope"
        break;
      case 'userManual':
        this.props.updateClickedMenus(['learnMore', 'user manual'])
        page = "/index/learnMore/userManual"
        break;
      case 'contactUs':
        this.props.updateClickedMenus(['contact', 'contactUs'])
        page = "/index/contact/contactUs"
        break;
      // case 'reportABug':
      //   this.props.updateClickedMenus(['contact', 'reportABug'])
      //   this.props.history.push("/index/contact/reportABug");
      //   break;
      case 'termOfUse':
        this.props.updateClickedMenus(['legal', 'termOfUse'])
        page = "/index/legal/termOfUse"
        break;
      default:
        break;
    }
    const { isShouldSave } = this.props
    let pathname = this.props.history.location.pathname
    if (isShouldSave && (pathname === "/index/story/create" || pathname.startsWith("/index/story/edit"))) {
      this.props.leaveCreatePage(true)
      if (pathname.startsWith("/index/story/edit")) {
        this.props.toNewPage("/index/explore/stories")
      } else {
        this.props.toNewPage(page)
      }
      return
    }
    this.props.history.push(page);
  }
  render() {
    const { isLeftCollapsed } = this.state;
    const { isLogIn, selectedMenue, isLeftFloatMenue, intl } = this.props;
    const defaultOpenKeys = selectedMenue[0];

    return (
      <div
        className='indexLeftMenueView'>
        <Menu
          mode="inline"
          defaultOpenKeys={isLeftCollapsed && !isLeftFloatMenue ? null : [defaultOpenKeys]} //大分类
          defaultSelectedKeys={[selectedMenue[1]]} //小分类
          inlineCollapsed={isLeftFloatMenue ? false : isLeftCollapsed}
          onClick={this.onClick}
          className='MenuWrapper'
        >
          <SubMenu
            className='SubTxt'
            key="explore"
            title={
              <div className='parentTitle'>
                <Icon type=" "
                  className={["iconSize", defaultOpenKeys === 'explore' ? "selectedExploreIcon" : "ExploreIcon"]} />
                <span
                  style={{ color: defaultOpenKeys === 'explore' ? "#F28B30" : "#38291F" }}
                >{intl.get("Explore")}</span>
              </div>
            }
          >
            <Menu.Item key="stories">{intl.get("stories")}</Menu.Item>
            <Menu.Item key="datasets">{intl.get("datasets")}</Menu.Item>
            {/* <Menu.Item key="user">users</Menu.Item>
            <Menu.Item key="bugs">bugs</Menu.Item> */}
          </SubMenu>
          {
            isLogIn ?
              <SubMenu
                className='SubTxt'
                key="create"
                title={
                  <div className='parentTitle'>
                    <Icon type=" "
                      className={["iconSize", defaultOpenKeys === 'create' ? "selectedParticipateIcon" : "ParticipateIcon"]} />
                    <span
                      style={{ color: defaultOpenKeys === 'create' ? "#F28B30" : "#38291F" }}
                    >{intl.get("Create")}</span>
                  </div>
                }
              >
                <Menu.Item key="creatStories">{intl.get("story")}</Menu.Item>
                {/* <Menu.Item key="dashboard">dashboard</Menu.Item>
                <Menu.Item key="factsheet">factsheet</Menu.Item> */}
              </SubMenu>
              :
              null
          }
          <SubMenu
            key="learnMore"
            title={
              <div className='parentTitle'>
                <Icon type=" " className={["iconSize", defaultOpenKeys === 'learnMore' ? "selectedLearnMoreIcon" : "LearnMoreIcon"]} />
                <span
                  style={{ color: defaultOpenKeys === 'learnMore' ? "#F28B30" : "#38291F" }}
                >{intl.get("Learn more")}</span>
              </div>
            }
          >
            <Menu.Item key="tutorial">{intl.get("tutorial")}</Menu.Item>
            {/* <Menu.Item key="facts">facts</Menu.Item> */}
            <Menu.Item key="aboutCalliope"> {intl.get("about calliope")}</Menu.Item>
            <Menu.Item key="userManual"> {intl.get("user manual")}</Menu.Item>
          </SubMenu>
          <SubMenu
            key="contact"
            title={
              <div className='parentTitle' >
                <Icon type=" " className={["iconSize", defaultOpenKeys === 'contact' ? "selectedContactIcon" : "ContactIcon"]} />
                <span
                  style={{ color: defaultOpenKeys === 'contact' ? "#F28B30" : "#38291F" }}
                >{intl.get("Contact")}</span>
              </div>
            }
          >
            <Menu.Item key="contactUs">{intl.get("contact us")}</Menu.Item>
            {/* <Menu.Item key="reportABug">report a bug</Menu.Item> */}
          </SubMenu>
          <SubMenu
            key="legal"
            title={
              <div className='parentTitle'>
                <Icon type=" " className={["iconSize", defaultOpenKeys === 'legal' ? "selectedLegalIcon" : "LegalIcon"]} />
                <span
                  style={{ color: defaultOpenKeys === 'legal' ? "#F28B30" : "#38291F" }}
                >{intl.get("Legal")}</span>
              </div>
            }
          >
            <Menu.Item key="termOfUse">{intl.get("term of use")}</Menu.Item>
          </SubMenu>
        </Menu>
      </div>
    );
  }
}