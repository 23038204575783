import React, { Component } from "react";
import SocialShareView from './SocialShare/socialShare';
import {
    // message, 
    Modal,
    // Input, 
    // Icon 
} from 'antd';
import './shareModal.less';

export default class ShareModalView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shareLink: window.location.href,
            layout: this.props.lalayoutType,
            mobilePreTitle: 'The story of',
            shareTitle: 'Calliope: The story of '
        }
    }

    handleShareCancel = e => {
        this.props.closeShareModal()
    };

    // copy(e) {
    //     const copyEle = document.querySelector(e) // 获取要复制的节点
    //     const range = document.createRange(); // 创造range
    //     window.getSelection().removeAllRanges(); //清除页面中已有的selection
    //     range.selectNode(copyEle); // 选中需要复制的节点
    //     window.getSelection().addRange(range); // 执行选中元素
    //     const copyStatus = document.execCommand("Copy"); // 执行copy操作
    //     // 对成功与否定进行提示
    //     if (copyStatus) {
    //         message.success(this.props.intl.get("copied successfully"));
    //     } else {
    //         message.fail(this.props.intl.get("copied failed"));
    //     }
    //     window.getSelection().removeAllRanges(); //清除页面中已有的selection
    // }

    render() {
        const { shareLink } = this.state
        const { intl, title, visible, userInfo, layoutType } = this.props
        const { sid } = this.props.OperateRelatedId
        let publishLink = shareLink.replace("share", "publish");
        //let embedLink = publishLink;
        return (
            <Modal
                width={300}
                height={155}
                visible={visible}
                title={intl.get("Share")}
                onOk={this.handleShareOk}
                onCancel={this.handleShareCancel}
                footer={null}
                destroyOnClose
            >
                <SocialShareView title={title} intl={intl} publishLink={publishLink} sid={sid} userInfo={userInfo} layoutType={layoutType} />
                {/* <div className='link'>{intl.get("Link to this view")}</div>
                <div className='rowCenterDiv'>
                    <Input
                        //placeholder={config.url.shareLinkPrefix} 
                        value={publishLink} className="publishLink" style={{ display: 'inline-block', width: '90%' }} />
                    <Icon onClick={() => this.copy('.publishLink')}
                        type=" " className='copyImage'></Icon>
                </div>

                <div style={{ marginTop: 15 }}>
                    <div className='Embed'>{intl.get("Embed this view")}</div>
                    <div className='rowCenterDiv'>
                        <Input
                            //placeholder={config.url.shareLinkPrefix} 
                            value={embedLink} className="embedLink" style={{ display: 'inline-block', width: '90%' }} />
                        <Icon onClick={() => this.copy('.embedLink')}
                            type=" " className='copyImage'
                        />
                    </div>
                </div> */}
            </Modal >

        )
    }
}