import { connect } from 'react-redux';
import { fileName, real_filename, schema, relations, storyParameter, maxStoryLength, data, resultCoverage, aggregationLevel, facts, unusedFacts, rewardWeight, title,filters,filtersTitle,datares,schemaFilter,filtersChangeFlag ,dataresLocal} from '@/selector/story';
import * as dataAction from '@/action/dataAction';
import * as factAction from '@/action/factAction';
import * as storyAction from '@/action/storyAction';
import DataShowView from './DataShowView';
import { editingCurrentFact, editstate } from '../../../selector/story';

const mapStateToProps = (state) => ({
    schema: schema(state),
    facts: facts(state),
    unusedFacts: unusedFacts(state),
    relations: relations(state),
    data: data(state),
    title: title(state),
    resultCoverage: resultCoverage(state),
    fileName: fileName(state),
    real_filename: real_filename(state),
    storyParameter: storyParameter(state),
    maxStoryLength: maxStoryLength(state),
    rewardWeight: rewardWeight(state),
    aggregationLevel: aggregationLevel(state),
    filters:filters(state),
    filtersTitle:filtersTitle(state),
    datares:datares(state),
    schemaFilter:schemaFilter(state),
    filtersChangeFlag:filtersChangeFlag(state),
    dataresLocal:dataresLocal(state),
    editingCurrentFact:editingCurrentFact(state),
    editstate:editstate(state),
})

const mapDispatchToProps = dispatch => {
    return {
        //data
        visualizeData: (data) => dispatch(dataAction.visualizeData(data)),
        changeData: (fileName, real_filename) => dispatch(dataAction.changeData(fileName, real_filename)),
        uploadData: (fileName, real_filename, schema, data) => dispatch(dataAction.uploadData(fileName, real_filename, schema, data)),
        //fact
        addFact: (facts) => dispatch(factAction.addFact(facts)),
        insertFact: (index, insert) => dispatch(factAction.insertFact(index, insert)),
        updateFact: (index, fact) => dispatch(factAction.updateFact(index, fact)),
        deleteFact: (index) => dispatch(factAction.deleteFact(index)),
        deleteUnusedFact: (index) => dispatch(factAction.deleteUnusedFact(index)),
        orderFacts: (sourceIndex, destinationIndex) => dispatch(factAction.orderFacts(sourceIndex, destinationIndex)),
        //story
        selectFact: (index) => dispatch(storyAction.selectFact(index)),
        generateStory: (facts, relations, coverage) => dispatch(storyAction.generateStory(facts, relations, coverage)),
        changeMethod: (method) => dispatch(storyAction.changeMethod(method)),
        setStoryParameter: (maxStoryLength, information, chartDiversity, timeLimit) => dispatch(storyAction.setStoryParameter(maxStoryLength, information, chartDiversity, timeLimit)),
        setRewardWeight: (logicality, diversity, integrity) => dispatch(storyAction.setRewardWeight(logicality, diversity, integrity)),
        setAggregationLevel: (level) => dispatch(storyAction.setAggregationLevel(level)),
        changeTitle: (title) => dispatch(storyAction.changeTitle(title)),

          //edit filters
          updateFilters:(filters)=>dispatch(factAction.updateFilters(filters)),
          updateFiltersTitle:(filtersTitle)=>dispatch(factAction.updateFiltersTitle(filtersTitle)),
          updateDatares:(datares)=>dispatch(factAction.updateDatares(datares)),
          updateSchemaFilter:(schemaFilter)=>dispatch(factAction.updateSchemaFilter(schemaFilter)),
          updateFiltersChangeFlag:(filtersChangeFlag)=>dispatch(factAction.updateFiltersChangeFlag(filtersChangeFlag))
  
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(DataShowView);
