import React, { Component } from "react";
import StoryInformationView from '@/components/StoryView/StoryInformationView';
// import FactView from '@/view/FactView';
import SequenceView from '@/view/SequenceView/SequenceView';
import VisOptionView from '@/components/StoryView/VisOptionView';
import LayoutType from '@/constant/LayoutType';
import StorylineWebView from '@/view/StoryViewForComment/StorylineWebView';
import FactSheetView from '@/view/StoryViewForComment/FactSheetView';
import MobileView from '@/view/StoryViewForComment/MobileView';
import TextView from '@/view/StoryViewForComment/TextView';
import TableView from '@/view/StoryViewForComment/TableView';
import SaveStoryView from './SaveStoryPannel/SaveStoryView';
import * as api from '@/axios/api';
import config from '@/axios/config';
// import MobileTab from './MobileTabView/MobileTab';
import './CreateStoryView.less';
import HeaderOperatorView from'./HeaderOperatorView'
import FactList from './FactListViewNew';
import FactChartEditor from './FactListViewNew/FactChartEditor';
// import { Layout } from "antd";
// import {genFactSentence,genSubtitle} from '../../sentencer/index';
import mediaQueryConfig from '@/constant/mediaQueryConfig';
import getUserEndSize from '@/tool/getUserEndSize';
import { DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import { Spin } from 'antd'

function calSize(isHiddenRight=false,isHiddenBottom=false) {
    let currentHeight,currentWidth
    if(isHiddenRight){
        currentWidth = document.body.clientWidth
        currentHeight = document.body.clientHeight - 50 - 50;
    }
    else{
        currentWidth = (document.body.clientWidth - 308);
        currentHeight = document.body.clientHeight - 50 - 50 - 200;
    }
    return { 'width': currentWidth, 'height': currentHeight };
}

export default class CreateStoryView extends Component {
    constructor(props) {
        super(props);
        let isHiddenRight=false
        let isHiddenFactView = this.props.userEnd.startsWith('iphone') || this.props.userEnd === 'ipad-min'
        if(isHiddenFactView) isHiddenRight=true
        let size=calSize(isHiddenRight)
        this.state = {
            userEndTag: this.props.userEnd,
            isShareStory: this.props.isShareStory || false,
            width: size.width,
            height: size.height,
        }
    }
    componentWillMount() {
        // this.getAllServices()
        const { isEditStory, userEnd } = this.props
        this.updateVisContainerSize(userEnd)
        mediaQueryConfig.map((media) => {
            let mql = window.matchMedia(media)
            this.mediaQueryChanged(mql)
            mql.addListener(this.mediaQueryChanged);
            return media
        })

        this.props.updateLastPage('/homePage')
        if (!isEditStory && JSON.stringify(this.props.visData) === '{}') {
            this.props.history.push('/index/story/select/dataset')
            return
        }
        //编辑故事
        let { sid } = this.props.match.params
        if (isEditStory) {
            api.queryStoriesBySid(sid).then((response) => {
                let storie = response.story.story
                this.props.updateOperateRelatedId({ ...this.props.OperateRelatedId, sid: parseInt(sid) })
                const { facts, relations, data, schema, storyParameter, aggregationLevel, resultCoverage, fileName, real_filename,showSuggestion,subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal  } = JSON.parse(storie.json)
                console.log("编辑", showSuggestion, JSON.parse(storie.json))
                this.props.updateCommentStoryInfo(facts, relations, data, schema, storie.title, fileName, real_filename,storyParameter, aggregationLevel, resultCoverage,showSuggestion,subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal )
            }, reject => {

            })
        }
    }
    mediaQueryChanged = (mql) => {
        if (mql.matches) {

            this.props.updateUserEnd(mql.media)
            let userEnd = this.props.userEnd
            if (!userEnd) {
                userEnd = getUserEndSize(mql.media)
                let isHiddenRight,isHiddenBottom=false
                let isHiddenFactView = userEnd.startsWith('iphone') || userEnd === 'ipad-min'
                if(isHiddenFactView) isHiddenRight=true
                if(userEnd.startsWith('iphone')) { 
                    this.props.changeLayOutType(LayoutType.SLIDER_MOBILE)                 
                    isHiddenBottom=true
                }
                let size = calSize(isHiddenRight,isHiddenBottom)
    
                this.setState({
                    userEndTag:userEnd,
                    width:size.width,
                    height:size.height
                })        
            }

        }

    }
    handleResize() {

        let isHiddenBottom,isHiddenRight=false
        let isHiddenFactView = this.state.userEndTag.startsWith('iphone') || this.state.userEndTag === 'ipad-min'
        if(isHiddenFactView) isHiddenRight=true
        if(this.props.isMobileEnd) isHiddenBottom=true
        let size = calSize(isHiddenRight,isHiddenBottom);
        if (this._isMounted)
            this.setState({
                width: size.width,  
                height: size.height
            })
    }


    componentWillReceiveProps(newProps) {
        if (newProps.userEnd !== this.state.userEndTag) {
            this.setState({
                userEndTag: newProps.userEnd
            })
            if (newProps.userEnd === 'iphone-pro' || newProps.userEnd === 'iphone') {
                this.props.changeLayOutType(LayoutType.SLIDER_MOBILE)
            }
            this.updateVisContainerSize(newProps.userEnd)
        }
    }
    componentDidMount() {
        this._isMounted = true;
        window.addEventListener('resize', this.handleResize.bind(this));
    }
    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('resize', this.handleResize.bind(this));
        this.props.changeCurrentEditing(false,null)
        this.props.changeTitle('')
        this.props.changeSubTitle('')
        this.props.updateFiltersLocal([],'',[],[])
        // this.props.updateFilters([])
        this.props.updateFiltersTitle('')
        this.props.updateDatares([])
        this.props.updateSchemaFilter([])
        this.props.updateFiltersChangeFlag(false)
        this.props.setHoverIndex(-1)
        this.props.generateStory([],[],1)
        this.props.resetTimeline()
    }

    updateVisContainerSize = (userEnd, layoutType) => {
        let width;
        let height;
        switch (userEnd) {
            case 'browser-max-pro':
                width = '980'
                height = '413'
                break;
            case 'browser-max':
                width = '980'
                height = '413'
                break;
            case 'browser-min':
                width = '750'
                height = '419'
                break;
            case 'ipad':
                width = '598'
                height = '419'
                break;
            case 'ipad-min':
                width = '642'
                height = '419'
                break;
            case 'iphone-pro':
                width = '338'
                height = '650'
                break;
            case 'iphone':
                width = '346'
                height = '537'
                break;
            default:
                break;
        }
        this.props.updateVisContainerSize({
            width,
            height
        })
    }
    changeLayOutTypeListener = (layoutType) => {
        this.updateVisContainerSize(this.state.userEndTag, layoutType)
    }

    render() {
        let {title, facts,userEnd, layoutType, userInfo, isShowGenerate, isShouldSave, isLeavingCreatePage, intl ,editstate} = this.props;
        let {width,height}=this.state;
        //console.log("render", facts, title)
        // let mobileScale = (this.props.visContainerSize.height - 31) / 530
        // let mobileTop = - (530 - (this.props.visContainerSize.height - 31)) / 2 + 13
        // let mobileBottom = - (530 - (this.props.visContainerSize.height - 31)) / 2 + 19
        let isMobileEnd = userEnd.startsWith('iphone')
        let isHiddenFactView = isMobileEnd || userEnd === 'ipad-min'
        let isShowMargin = userEnd === 'ipad'
        let mobileScale,mobileTop, mobileBottom
        if(isHiddenFactView) {
            // mobileScale= (height+50) / 530
            mobileScale= (height-90) / 530
            if(width<=500&&height>=910) mobileScale= 1.5     
            mobileTop = - (530 - (height-90 )) / 2 + 13
            mobileBottom = - (530 - (height-90)) / 2 
        }
        else {
            mobileScale= (height-120) / 530
            mobileTop = - (530 - (height - 120)) / 2 + 13
            mobileBottom = - (530 - (height - 120)) / 2 + 19
        }

        facts = facts.map((x, index) => {
            // x.generatedScript = genFactSentence(x,this.props.intl.options.currentLocale)
            // x.generatedSubtitle=genSubtitle(x,this.props.intl.options.currentLocale)
            x.index = index;
            return x
        })
        let aggfacts = facts.filter(e => {
            if (!e.aggregated) {
                return true
            } else {
                return e.aggregated && e.aggregatedFact
            }
        })

        let storyview;
        switch (layoutType) {
            case LayoutType.STORY_TEXT:
                storyview = <TextView {...this.props} />
                break;
            case LayoutType.TABLE:
                storyview = <TableView {...this.props}  visContainerSize={{
                    width: width,
                    height: height
                }} />
                break;
            case LayoutType.STORYLINE_WEB:
                storyview = <StorylineWebView forPublish={false} {...this.props} wholeFacts={this.props.facts} facts={aggfacts}   visContainerSize={{
                    width: width,
                    height: height
                }} />
                break;
            case LayoutType.SLIDER_MOBILE:
                storyview = <div id='mobile-view' style={{ height: 530, width: 300, margin: '0 auto', marginTop: this.props.visContainerSize.height ? mobileTop : 0, marginBottom: this.props.visContainerSize.height ? mobileBottom : 0, transform: 'scale(' + mobileScale + ')' }}>
                    <MobileView {...this.props} forPublish={false} />
                </div>
                break;
            case LayoutType.FACTSHEET:
                storyview = <FactSheetView {...this.props} forPublish={false} isCanEditable={true} isHiddenFactView={isHiddenFactView}
                visContainerSize={{
                    width: width,
                    height: height
                }} 
                />
                break;

            default:
                break;
        }
        const GeneratingView = ({ isLeavingCreatePage }) => (
            <div className='GeneratingDiv'>
                <div className='GeneratingTitle'>
                    <div className='loadingImage'></div>
                    <span className='LoadingTxt'>{intl.get("Generating")}
                    </span>
                    <span className="dotSpan">...</span>
                </div>
                {
                    isLeavingCreatePage ?
                        <div style={{ height: '99px' }}>
                            <span className='messageInfo'>{intl.getHTML("LeaveHint")}</span>
                            <FooterView {...this.props} />
                        </div>
                        :
                        <span className='messageInfo'>{intl.get("Please wait")}</span>
                }
            </div>
        )
        const FooterView = (props) => {
            return (<div className='wrapper'>
                <div className='centerWrapper' onClick={() => {
                    props.loadingGeneratingView(true)
                    props.leaveCreatePage(false) //important ************* reset state
                }}>
                    <span>{intl.get("No")}</span>
                </div>
                <div className='centerWrapper' onClick={() => {
                    props.loadingGeneratingView(false)
                    props.leaveCreatePage(false) //important ************* reset state
                    //这里兼容了，离开页面去其他页面 和 退出登陆 两种的逻辑
                    if (this.props.isToLogout) {
                        //退出登陆 
                        this.props.visualizeData({})
                        api.logout().then((response) => {
                            let redirectUrl
                            if (process.env.NODE_ENV === 'production') {
                                redirectUrl = config.url.logoutRedirectUrl
                            } else {
                                redirectUrl = "http://localhost:3000/#/logout"
                            }
                            window.location.href = `${config.url.logoutAuthSystem}?redirectUrl=${redirectUrl}`
                        })
                    } else {
                        //跳转
                        if (this.props.newPage) {
                            props.history.push(this.props.newPage)
                        }
                    }
                }}>
                    <span>{intl.get("Yes")}</span>
                </div>
            </div >);
        }
        let story = {
            title,
            time: null,
            num_of_views: null,
            user: userInfo
        }
        return (
            <div style={{ marginLeft: isShowMargin ? "30px" : "0px" ,height:"calc(100% - 28px)"}} >
                <DndProvider  backend = { HTML5Backend }  options={{ enableMouseEvents: true }}>
                <Spin tip={intl.get("Loading")} spinning={this.props.isShowGenerate}>
                <div className='CreatStoryPage' style={{ justifyContent: isHiddenFactView ? "center" : "", height:"100%" }}>
                    <div className='middleContent' style={{height:"100%",width:isHiddenFactView?"100%":"calc(100% - 308px)"}}>
                        <div style={{ height: "60px", lineHeight: '60px',display:'flex',flexDirection:'row',alignItems:'center' }}>
                            <StoryInformationView story={story} isEditable={true} {...this.props}  isMobileEnd={isMobileEnd}/>
                            <HeaderOperatorView intl={intl} {...this.props}  isMobileEnd={isMobileEnd} isHiddenFactView={isHiddenFactView} />
                        </div>           
                        <div className='CreatStoryViewWrapper' style={{height:"calc(100% - 60px)", width:  isMobileEnd ? 'calc(100% - -35px)' :"calc(100% - 15px)"}}>
                            <div style={{ marginLeft: "16px" }}>
                                <VisOptionView isLoadedInCreatePage={true} changeLayOutTypeListener={this.changeLayOutTypeListener} {...this.props} />
                            </div>
                            <div className='storyview' style={{height:isHiddenFactView?"calc(100% - 10px)":"calc(100% - 220px)",display:layoutType===LayoutType.SLIDER_MOBILE?"flex":null }}>
                                <div style={{ width: '100%'}} className='GeneratingWrapper'>
                                    {
                                        isShowGenerate ?
                                            <GeneratingView isLeavingCreatePage={isLeavingCreatePage} leaveCreatePage={this.props.leaveCreatePage}></GeneratingView>
                                            :
                                            isShouldSave && isLeavingCreatePage ?
                                                <SaveStoryView {...this.props} />
                                                :
                                                null

                                    }
                                </div>
                                {storyview}
                            </div>
                            <div className='SequenceViewWrapper' style={{ display: isHiddenFactView ? 'none' : 'block',height:"205px" }}>
                               <SequenceView {...this.props}/>
                            </div>
                        </div>
                    </div>
                    <div className='FactWrapper' style={{ display: isHiddenFactView ? 'none' : 'block' }} >
                        {
                            editstate ? (<div className='rightPannel1'><FactChartEditor intl={intl} {...this.props}/></div>) : (<div className="rightPannel"><FactList intl={intl}{...this.props} /></div>)
                        }
                        {/* <div className='GoalWrapper'>
                            <GenerationPanel {...this.props} />
                        </div> */}
                    </div>
                    {/* <div style={{ display: isHiddenFactView ? "flex" : "none" }} className='mobileTabView'>
                        <MobileTab {...this.props} />
                    </div> */}
                </div>
                </Spin>
                </DndProvider >
            </div >
        )
    }
}