const templates = [
    {
        'id': 0,
        'template': '当{{subspace}}时， {{agg}} {{measure}} 的值等于 {{parameter}}.',
    },
    {
        'id': 1,
        'template': '当{{subspace}}时， {{measure}} 的 {{agg}}值是 {{parameter}}.',
    },
    {
        'id': 2,
        'template': '当{{subspace}}时， {{agg}} {{measure}} 的值是 {{parameter}}.',
    },
]

export default templates;