const templates = [
    {
        'id': 0,
        'template': '当{{subspace}}时， {{agg}} {{measure}} 在不同 {{groupby}}s 上的数据分布如图所示.',
    },
    {
        'id': 1,
        'template': '当{{subspace}}时，将 {{agg}} {{measure}} 根据不同的 {{groupby}}s 进行划分，结果如图所示.',
    },
    {
        'id': 2,
        'template': '当{{subspace}}时，不同 {{groupby}}s 的 {{agg}} {{measure}} 如图所示.',
    },
]

export default templates;