/* eslint no-eval: 0 */
import React, { Component } from 'react';
import { Icon, message } from 'antd';
import { fact2chart } from '../../tool/fact2vis';
import './eventview.less';
import { isValid } from '../FactView/helper';
import _ from 'lodash';
import uuidv4 from 'uuid/v4';
import * as api from '@/axios/api';
import activitiesCode from '@/axios/activitiesCode';
import config from '@/axios/config'
import Fact from '@/model/fact';
import { genSubtitle, genFactSentence } from '../../sentencer';

const addFactNum = 10
export default class EventView extends Component {

    constructor(props) {
        super(props);
        this.scriptRef = React.createRef();
        this.state = {
            fact: this.props.fact
        }
    }
    componentDidMount() {
        if (this.visNode) {
            let vis = this.visNode.children[0];
            if (vis)
                vis.style.transform = "translate(-36px,-20px)scale(0.6)";
        }
    }
    componentDidUpdate() {
        if (this.visNode) {
            let vis = this.visNode.children[0];
            if (vis)
                vis.style.transform = "translate(-36px,-20px)scale(0.6)";
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.hoverFactIndex === undefined) return false
        if (!_.isEqual(nextProps.fact, this.state.fact)) {
            return true
        }
        return false
    }

    clickFact = () => {
        const { selectFact, index } = this.props;
        selectFact(index);
        this.props.changeCurrentEditing(true, _.cloneDeep(this.props.fact));
        if (index === this.props.facts.length - 1 && this.props.recommendedEfacts.length === 0) {
            this.addFacthere('last', index)
        }
        if (index === 0 && this.props.recommendedSfacts.length === 0) {
            this.addFacthere('first', index)
        }
        if (index > 0 && index < this.props.facts.length - 1) {
            if (this.props.recommendedMfacts.length > 0) {
                this.props.recommendedMfacts.map((d, i) => {
                    this.props.deleteMFact(0)
                    return null
                })
            }
            this.addFacthere('middle', index)
        }
    }

    deleteFact = (e) => {
        const { index } = this.props;
        this.props.deleteFact(index);

        this.props.changeCurrentEditing(false, null)
        e.stopPropagation();
        //activitiesApi
        let uid = this.props.userInfo.uid
        let boby = {
            action: activitiesCode.deleteFact,
            objectid: -1,
            meta: `deleteFact${this.props.facts[index]}`,
        }
        api.activitiesApi(uid, boby).then(response => {
            //   console.log("activitiesApi",response)
        })
    }

    endEditing = () => {
        // console.log('end editing');
        let script = this.scriptRef.current.innerText;
        let { fact, index } = this.props;
        fact.generatedScript = script;
        this.props.updateFact(index, fact);
    }

    addFacthere = (fact_location, factIndex) => {
        let startFact = {}
        let endFact = {}
        let startEvent = {}
        let endEvent = {}
        if (fact_location === "middle") {
            startEvent = this.props.facts[factIndex - 1]
            endEvent = this.props.facts[factIndex + 1]
            if (startEvent.type !== "")
                startFact = {
                    "type": startEvent.type,
                    "measure": startEvent.measure,
                    "groupby": startEvent.groupby,
                    "subspace": startEvent.subspace,
                    "focus": startEvent.focus
                }
            if (endEvent.type !== "")
                endFact = {
                    "type": endEvent.type,
                    "measure": endEvent.measure,
                    "groupby": endEvent.groupby,
                    "subspace": endEvent.subspace,
                    "focus": endEvent.focus
                }
        }

        //TODO:会有重复的fact
        this.adding(config.url.interpolateMCTS, fact_location, startFact, endFact).then(response => {
            let tempdata
            if (response.data.story) tempdata = response.data
            else tempdata = eval('(' + response.data + ')')
            let facts = tempdata.story.facts;
            if (fact_location === "middle") facts = facts.facts
            if (facts === null) facts = []
            facts.pop()
            facts.shift()
            facts = facts.slice(0, addFactNum)

            let tempFacts = [];
            for (let factDict of facts) {
                let fact = new Fact(
                    factDict['type'],
                    factDict['measure'],
                    factDict['subspace'],
                    factDict['groupby'],
                    factDict['focus'],
                    factDict['parameter'],
                    "", // chart
                    factDict['score'],
                    factDict['information'],
                    factDict['significance']
                );
                fact.uuid = uuidv4();
                fact.generatedScript = genFactSentence(fact, this.props.intl.options.currentLocale)
                fact.generatedSubtitle = genSubtitle(fact, this.props.intl.options.currentLocale)
                tempFacts.push(fact);
            }
            if (tempFacts.length === 0) tempFacts = ["none"]
            if (fact_location === 'first')
                this.props.addSFact(tempFacts);
            if (fact_location === 'middle')
                this.props.addMFact(tempFacts);
            if (fact_location === 'last')
                this.props.addEFact(tempFacts);
        });
    }

    adding = (url, fact_location, startFact, endFact) => {
        return new Promise(async (resolve, reject) => {
            let fileName = this.props.fileName;
            let filters = _.cloneDeep(this.props.filtersLocal);

            let data;
            if (fact_location === 'middle') {
                data = {
                    file_name: fileName,
                    max_story_length: addFactNum,
                    fact_location: fact_location,
                    start_fact: startFact,
                    end_fact: endFact,
                    similarity: this.props.calSimilarityType,
                    filters: JSON.stringify(filters),
                    maximumFact: 10
                    // method: 'mcts'
                }
            }
            else {
                data = {
                    file_name: fileName,
                    max_story_length: addFactNum,
                    fact_location: fact_location,
                    filters: JSON.stringify(filters),
                    // method: 'mcts'
                }
            }

            const response = await api.interpolate(url, data)
            if (response.data.fail) {
                message.info(this.props.intl.get("cannot interpolate."))

                reject()
                // record_activity(activitiesCode.generateMoreFacts, 0 , {filename:fileName, response: response});
            }
            if (response.data.error) {
                message.info(this.props.intl.get("cannot interpolate."))
                reject()
                // record_activity(activitiesCode.generateMoreFacts, 0 , {filename:fileName, response: response});
            }
            resolve(response)
            // record_activity(activitiesCode.generateMoreFacts, 1 , {filename:fileName, response: response});

        })
    }

    render() {
        let { fact, uuid, data, isSelected, schema, showSuggestion, index, hoverFactIndex, editstate } = this.props;
        let script = fact.generatedScript;
        let datares
        if (this.props.dataresLocal && this.props.dataresLocal !== []) {
            datares = _.cloneDeep(this.props.dataresLocal);
        }
        else datares = data
        let schemaFilter = _.cloneDeep(this.props.schemaFilterLocal);
        let specData;
        if (schemaFilter && schemaFilter !== []) {
            specData = {
                "schema": schemaFilter,
                "showSuggestion": showSuggestion
            };
        }
        else {
            specData = {
                "schema": schema,
                "showSuggestion": showSuggestion
            };
        }
        //console.log("segurenceView", showSuggestion)
        if (fact.aggregated && fact.aggregatedFact) {
            let aggregatedFact = fact.aggregatedFact;
            let compoundScript = fact.generatedScript + " " + aggregatedFact.generatedScript;
            let vis = fact2chart(specData, uuid, fact, datares, "small", true);
            return (
                <div
                    className='eventview'
                    onClick={this.clickFact}
                    style={{
                        position: "relative",
                        zIndex: 0,
                        boxShadow: (isSelected && !editstate) ? "rgb(244, 158, 40) 0px 0px 0px 2px" : (isSelected && editstate ? "rgb(244, 158, 40) 0px 0px 0px 3px" : "rgb(0, 0 0) 0px 0px 0px 0px")
                    }}
                >
                    <div className="chart-preview">
                        <div style={{ marginLeft: 6 }}>
                            {vis}
                        </div>
                    </div>
                    <div className="script-preview">
                        {compoundScript}
                    </div>
                </div >
            )
        } else {
            let vis;
            if (isValid(fact)) {
                vis = fact2chart(specData, uuid, fact, datares, "small");
            }
            return (
                <div
                    className={`creatstory-eventview creatstory-eventview_left_${index === hoverFactIndex} creatstory-eventview_right_${index === hoverFactIndex - 1}`}
                    onClick={this.clickFact}
                    style={{
                        position: "relative",
                        zIndex: 0,
                        boxShadow: (isSelected && !editstate) ? "rgb(244, 158, 40) 0px 0px 0px 2px" : (isSelected && editstate ? "rgb(244, 158, 40) 0px 0px 0px 3px" : "rgb(0, 0 0) 0px 0px 0px 0px")
                    }}
                >

                    <Icon type='close'
                        className='closeIcon'
                        onClick={this.deleteFact}
                    />
                    {/* <SmallCardView specData={specData} uuid={uuid} fact={fact} datares={datares} /> */}
                    <div className="chart-preview" >
                        <div ref={(node) => this.visNode = node} style={{ height: '100%' }}>
                            {vis}
                        </div>
                    </div>
                    <div ref={this.scriptRef} className="script-preview" contentEditable suppressContentEditableWarning={true}
                        onBlur={this.endEditing}>
                        {script}
                    </div>
                </div >
            )
        }
    }
}
