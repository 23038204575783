import React from 'react';
import './HeaderOperatorView.less'
import {Select,Modal,  message} from 'antd';
// import _ from 'lodash';
import activitiesCode from '@/axios/activitiesCode';
// import Fact from '@/model/fact';
import * as api from '@/axios/api';
import { getPublishLink } from '@/tool/publish';
import LayoutType from '@/constant/LayoutType';
const { Option } = Select;
let winRef;
export default class HeaderOperatorView extends React.Component {

    constructor(props) {
        super(props);
     
        this.state = {
            factsheetLayoutType:this.props.factsheetLayoutType,
            remakeJudge:false,//判断是否进行清空图表的操作
            cannotChangeData: false,
            isCanSave: true
        }
    }
        onFactsheetLayoutTypeSelect = (value) =>{
            this.props.changeFactsheetLayoutType(value)
            this.setState({
                factsheetLayoutType:value
            })
        }

      
        clickRemakeJudge=()=>{
            this.setState({
                remakeJudge:true
            })
        }
        clickRemakeJudgeNo=()=>{
            this.setState({
                remakeJudge:false
            })
        }
        clickRemake = () =>{
            let tempFacts=[]
            // for (let i=0;i<2;i++) {
            //     let fact = new Fact(
            //         '',
            //         [],
            //         [],
            //         [],
            //         [],
            //         [],
            //         "", // chart
            //         0,
            //         0,
            //         0
            //     )
            //     tempFacts.push(fact);
            // }
            this.props.generateStory(tempFacts.slice(), [
                "none",
            ], 0);
            this.setState({
                remakeJudge:false
            })
        }
    
        editStory = () => {
            let hide = message.loading(this.props.intl.get("Updating"), 0);
            const { title, facts, relations, data, schema, aggregationLevel, storyParameter, resultCoverage, fileName,real_filename, showSuggestion,subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal } = this.props;
            if (!facts || !data || !schema) return
            let { sid } = this.props.OperateRelatedId
            let averageFactScore = (facts.map(x => x.score).reduce((a, b) => a + b, 0)) / facts.length;
            if (!averageFactScore) averageFactScore = 0
            let description = `This generated data story includes ${facts.length} facts.The average importance score is ${parseInt(averageFactScore * 1000) / 1000} bit and the data coverage is ${parseInt(resultCoverage * 10000) / 100}%.`
            //新增加信息
            let storyObj = {
                facts,
                relations,
                data,
                schema,
                aggregationLevel,
                storyParameter,
                resultCoverage,
                fileName,
                real_filename,
                title,
                showSuggestion,
                subtitle,
                filtersLocal,
                filtersTitleLocal,
                dataresLocal,
                schemaFilterLocal,

            }
    
            let body = {
                title,
                description,
                json: JSON.stringify(storyObj)
            }
            return new Promise((resolve, reject) => {
                api.updateStory(sid, body).then(() => {
                    const { facts, relations, data, schema, storyParameter, aggregationLevel, resultCoverage, fileName, real_filename, title, showSuggestion,subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal } = storyObj
                    this.props.updateCommentStoryInfo(facts, relations, data, schema, title, fileName, real_filename, storyParameter, aggregationLevel, resultCoverage, showSuggestion,subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal )
                    hide();
                    resolve();
                    //activitiesApi
                    let uid = this.props.userInfo.uid
                    let body = {
                        action: activitiesCode.updateStory,
                        objectid: sid,
                        meta: JSON.stringify(
                            {
                                title,
                                description,
                                json: JSON.stringify(storyObj)
                            }
                        ),
                    }
                    api.activitiesApi(uid, body).then(response => {
                    })
    
                }, error => {
                    hide();
                    reject();
                    message.info(this.props.intl.get("please try again"))
                })
            })
        }
    
        saveStory = () => {
            if (this.props.isShowGenerate) return
            const { sid } = this.props.OperateRelatedId
            if (sid) {
                this.editStory().then(() => { })
                return
            }
            this.publishStory().then((newSid) => {
                this.props.updateOperateRelatedId({ ...this.props.OperateRelatedId, sid: newSid })
            }, reject => {
                message.info(this.props.intl.get("Publishing  story error"))
            })
        }
    
        publishStory = () => {
            const hide = message.loading(this.props.intl.get("Publishing"), 0);
            const { title, facts, relations, data, schema, aggregationLevel, storyParameter, resultCoverage, fileName,real_filename,subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal } = this.props;
            if (!facts || !data || !schema) return
            let { did } = this.props.OperateRelatedId
            //console.log("this.props", title, facts, relations, data, schema, aggregationLevel, storyParameter, resultCoverage, fileName)
            let averageFactScore = (facts.map(x => x.score).reduce((a, b) => a + b, 0)) / facts.length;
            if (!averageFactScore) averageFactScore = 0
            let description = `This generated data story includes ${facts.length} facts.The average importance score is ${parseInt(averageFactScore * 1000) / 1000} bit and the data coverage is ${parseInt(resultCoverage * 10000) / 100}%.`
    
            let storyObj = {
                facts,
                relations,
                data,
                schema,
                title,
                fileName,
                real_filename,
                aggregationLevel,
                storyParameter,
                resultCoverage,
                showSuggestion: true,
                subtitle,
                dataresLocal,
                schemaFilterLocal,
                filtersLocal,
                filtersTitleLocal
            }
            // console.log("publishStory storyObj", storyObj.showSuggestion)
            let body = {
                did,
                title,
                description,
                json: JSON.stringify(storyObj)
            }
    
            return new Promise((resolve, reject) => {
                api.createStory(body).then((response) => {
                    hide();
                    let newSid = response.sid
                    this.props.updateOperateRelatedId({ ...this.props.OperateRelatedId, sid: newSid })
                    const { facts, relations, data, schema, storyParameter, aggregationLevel, resultCoverage, fileName,real_filename, title, showSuggestion,subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal  } = storyObj
                    this.props.updateCommentStoryInfo(facts, relations, data, schema, title, fileName, real_filename, storyParameter, aggregationLevel, resultCoverage,showSuggestion,subtitle,dataresLocal,schemaFilterLocal,filtersLocal,filtersTitleLocal )
                    //activitiesApi
                    let uid = this.props.userInfo.uid
                    let body = {
                        action: activitiesCode.createStories,
                        objectid: newSid,
                        meta: null,
                    }
                    api.activitiesApi(uid, body).then(response => {
                    })
                    resolve(newSid)
                }, error => {
                    hide();
                    reject()
                })
            })
        }
        shareStory = () => {
            if (this.props.isShowGenerate) return
            let tempUrl = 'www.idvxlab.com/' //先通过用户点击打开页面，然后再对页面进行重定向,解决safri使用window.open被阻止的问题
            winRef = window.open(tempUrl, '_blank')
            let { sid } = this.props.OperateRelatedId
            let { isShouldSave } = this.props
            if (sid) { //保存过的可以直接生成link
                if (isShouldSave) { //有更新
                    this.editStory().then(() => {
                        this.getPublicShareLinkAndHref(sid)
                    })
                } else { //没有更新，直接生成link
                    this.getPublicShareLinkAndHref(sid)
                }
            } else { //publishing before share 
                this.publishStory().then((sid) => {
                    //查询story信息
                    api.queryStoriesBySid(sid).then(() => {
                        this.getPublicShareLinkAndHref(sid)
                    }, reject => {
                        message.info(this.props.intl.get('Loading error'))
                    })
                }, reject => {
                    message.info(this.props.intl.get("Saving  story error"))
                })
            }
        }
    
        getPublicShareLinkAndHref = (sid) => {
            api.getSharedlink(sid).then((response) => {
                let uuid = response.url.split("publish/")[1]
                let sharePublishUrl = getPublishLink(this.props.layoutType) + uuid
                winRef.location = sharePublishUrl
                //activitiesApi
                let uid = this.props.userInfo.uid
                let body = {
                    action: activitiesCode.shareStory,
                    objectid: sid,
                    meta: response.url,
                }
                api.activitiesApi(uid, body).then(response => {
                })
            }, reject => {
                winRef.close()
                message.info(this.props.intl.get("Get public share link error"))
            })
        }
    
    render() {
        const { layoutType,intl,isShouldSave} = this.props
        let { isCanSave } = this.state;
        let canNotShare= layoutType === LayoutType.STORY_TEXT || layoutType === LayoutType.TABLE
        return (
            <div className={this.props.isMobileEnd? "editorHeader_Mobile":'editorHeader'} style={{ display: this.props.isHiddenFactView ? 'none' : 'flex' }}>
                
                <div className={"rightEditorOperator"} style={{display:this.props.isMobileEnd?"none":"flex"}}> 
                    <div className="rightTool ResetOperator">
                        <div className="centerdiv" onClick={() => this.clickRemakeJudge()}>
                        <div className="deleteImg"></div>
                        {intl.get("Clear")}
                        </div>
                    </div>
                    <div className="rightTool UndoOperator" onClick={this.props.undo}></div>
                    <div className="rightTool RedoOperator" onClick={this.props.redo}></div>
                    <div className={this.props.layoutType==="factsheet"?"rightTool LayoutOperator":"rightTool LayoutOperatorDisabled"} >
                        <Select defaultValue={this.state.factsheetLayoutType} style={{ width: 80,display:'block' }} onChange={this.onFactsheetLayoutTypeSelect} disabled={this.props.layoutType==="factsheet"?false:true}>
                            <Option value="Vertical">{intl.get("Vertical")}</Option>
                            <Option value="Horizontal">{intl.get("Horizontal")}</Option>
                        </Select>
                    </div>
                   
                    <div className={isCanSave?"rightTool SaveOperator":"rightTool SaveOperatorDisabled"}>
                        <div className="centerdiv" onClick={() => this.saveStory()}>
                        <div className={isShouldSave ? "yellowSaveBtn" : "greySaveBtn"}></div>
                        {intl.get("Save")}
                        </div>
                    </div>
                    <div className={canNotShare?"rightTool ShareOperatorDisabled":"rightTool ShareOperator"}>
                        <div className="centerdiv" onClick={canNotShare?null:() => this.shareStory()}>
                        <div className={"shareBtn"}></div>
                        {intl.get("Share")}
                        </div>
                    </div>

                    {/* <div className='operateDiv' style={{ display:'flex' }}>
                        <Tooltip title={intl.get("save")} key={"save"}>
                            <div style={{ display: isCanSave ? 'block' : 'none' }} className={isShouldSave ? "yellowSaveBtn" : "greySaveBtn"} onClick={() => this.saveStory()}></div>
                        </Tooltip>
                        <Tooltip title={intl.get("share")} key={"share"}>
                            <div style={{ display: layoutType === LayoutType.STORY_TEXT || layoutType === LayoutType.TABLE || !isCanSave ? 'none' : 'block' }} onClick={() => this.shareStory()}></div>
                        </Tooltip> 
                    </div> */}
                </div>
              
                <Modal
                        key = {"modal_clear"}
                        className = "clear_story"
                        // closable = {false}
                        // title={initDone && intl.get("Clear")}
                        centered
                        visible={this.state.remakeJudge}
                        // destroyOnClose = {true}
                        onOk={() => this.clickRemake()}
                        onCancel={() => this.clickRemakeJudgeNo()}
                        okText = {intl.get("OK")}
                        cancelText = {intl.get("Cancel")}
                        style={{width:"400px"}}
                        >
                            <div>
                            <div className="clearModal_title">{intl.get("Warning")}</div>
                            <div className="clearModal_hint">{intl.get("ClearModal")}</div>
                            </div>

                    </Modal> 
            </div>
        )
    }
}