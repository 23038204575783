import ActionType from './type';

export const addFact = (facts) => ({
    type: ActionType.ADD_FACT,
    facts: facts
})

export const updateFact = (index, fact) => ({
    type: ActionType.UPDATE_FACT,
    index,
    fact,
})

export const insertFact = (index, insert) => ({
    type: ActionType.INSERT_FACT,
    index,
    insert,
})

export const deleteFact = (index) => ({
    type: ActionType.DELETE_FACT,
    index,
})

export const deleteUnusedFact = (index) => ({
    type:ActionType.DELETE_UNUSEDFACT,
    index,
})

export const orderFacts = (sourceIndex, destinationIndex) => ({
    type: ActionType.ORDER_FACTS,
    sourceIndex,
    destinationIndex,
})

export const setHoverIndex = (index) => ({
    type: ActionType.SET_HOVER_INDEX,
    index
})

export const updateFilters=(filters)=>({
    type: ActionType.UPDATE_FILTERS,
    filters
})
export const updateFiltersTitle=(filtersTitle)=>({
    type: ActionType.UPDATE_FILTERS_TITLE,
    filtersTitle
})
export const updateDatares=(datares)=>({
    type: ActionType.UPDATE_DATARES,
    datares
})
export const updateSchemaFilter=(schemaFilter)=>({
    type: ActionType.UPDATE_SCHEMA_FILTER,
    schemaFilter
})
export const updateFiltersChangeFlag=(filtersChangeFlag)=>({
    type: ActionType.UPDATE_FILTERS_CHANGE_FLAG,
    filtersChangeFlag
})

export const updatePannelWidth=(leftPaneWidth,rightPaneWidth)=>({
    type: ActionType.UPDATE_PANNEL_WIDTH,
    leftPaneWidth,
    rightPaneWidth
})

export const changeCurrentEditing=(editstate,editingCurrentFact)=>({
    type:ActionType.CHANGE_CURRENT_EDITING,
    editstate,
    editingCurrentFact
})

export const updateFiltersLocal=(filtersLocal, filtersTitleLocal, dataresLocal, schemaFilterLocal)=>({
    type: ActionType.UPDATE_FILTERS_LOCAL,
    filtersLocal,
    filtersTitleLocal,
    dataresLocal,
    schemaFilterLocal
}) 

export const addSFact=(facts)=>({
    type:ActionType.ADD_SFACT,
    facts:facts
})

export const addEFact=(facts)=>({
    type:ActionType.ADD_EFACT,
    facts:facts
})

export const addMFact=(facts)=>({
    type:ActionType.ADD_MFACT,
    facts:facts
})

export const deleteSFact=(index)=>({
    type:ActionType.DELETE_SFACT,
    index
})

export const deleteMFact=(index)=>({
    type:ActionType.DELETE_MFACT,
    index
})

export const deleteEFact=(index)=>({
    type:ActionType.DELETE_EFACT,
    index
})

export const insertEmptyFact = (insert, emptyFact) => ({
    type: ActionType.INSERT_EMPTY_FACT,
    insert,
    emptyFact
})

export const insertInterpolatedFacts = (insert, interpolatedFacts) => ({
    type: ActionType.INSERT_INTERPOLATED_FACTS,
    insert,
    interpolatedFacts
})

export const inserRecommendedFact = (index, insert,factLocation) => ({
    type: ActionType.INSERT_RECOMMENDEDFACT,
    index,
    insert,
    factLocation
})