import React, { Component } from "react";
import { Layout, Empty, Spin, Tabs, message, Pagination, Divider} from "antd";
import CardList from '@/components/CardListView';
import NoBoderRadiusSearchView from '@/components/SearchView/NoBoderRadiusSearchView/NoBoderRadiusSearchView'
import * as api from '@/axios/api';
// import InfiniteScroll from 'react-infinite-scroller';
import uuidv4 from 'uuid/v4';
import _ from 'lodash';
import './ExploreStoriesPage.less';
import '@/pages/common.less';

const { TabPane } = Tabs;
export default class ExploreStoriesPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataStories: [],
            myStories: [],
            hasMore: true,
            isShowLoading: true,
            page: 0,
            defaultInputValue: '',
            currentMenu: 'Published data stories',
            totalCount:0,
            totalPage:0,
            isChangePage:false,
            isQueryMyStories:false
        }
        this.per_page = 5
    }
    componentWillMount() {
        this.queryPublishedDatastories()
    }

    processChartData = (storyList, isQueryMyStories) => {
        let newStoriesList = storyList.map(story => {
            const { facts, relations, data, schema, showSuggestion } = JSON.parse(story.json)
            story = { ...story, facts, relations, data, schema, showSuggestion }
            return story
        })
        newStoriesList.map(story => {
            story.MaxSignificanceId = 0
            let events = story.facts.map(function (fact, i, facts) {
                //significance最高的显示第一帧chart画面
                if (fact.significance > facts[story.MaxSignificanceId].significance) {
                    story.MaxSignificanceId = i; //增加字段，保存用来显示第一帧的fact id
                }
                return {
                    id: uuidv4(),
                    index: i,
                    fact: fact,
                    relation: story.relations[i],
                }
            })
            events.filter(fact => {
                if (!fact.aggregated) {
                    return true
                } else {
                    return fact.aggregated && fact.aggregatedFact
                }
            })
            story.events = events;
            let averageFactScore = (events.map(x => x.fact.score).reduce((a, b) => a + b, 0)) / events.length;
            if (!averageFactScore) averageFactScore = 0;
            story.averageFactScore = averageFactScore;
            return story
        })
        if (isQueryMyStories) {
            this.setState({
                myStories: newStoriesList,
                isShowLoading: false,
                currentMenu: this.state.currentMenu,
            })
        } else {
            this.setState({
                dataStories: newStoriesList,
                isShowLoading: false
            })
        }
    }
    onSearchListener = (value) => {
        if (this.state.currentMenu !== 'Published data stories') {
            message.info(this.props.intl.get("search Published data stories only"))
            this.setState({
                defaultInputValue: value
            })
            return
        }
        if (value === '') {
            this.props.history.push({
                pathname: `/index/explore/stories`
            })
        } else {
            this.props.history.push({
                pathname: `/index/topRatedDataStoriesAbout/${value}`
            })
        }
    }
    handleInfiniteOnLoad = () => {
        if (this.state.currentMenu !== 'Published data stories') return
        if (!this.state.hasMore) return
        if (!this.lastLoadTime) {
            this.lastLoadTime = new Date().getTime();
            this.loadingData()
        }
        let duration = 1000;
        if (new Date().getTime() - this.lastLoadTime > duration) {
            this.lastLoadTime = new Date().getTime();
            this.loadingData()
        }
    };
    pageNumberOnChange=(page)=>{
        let nextPage=page-1;
        let params = {
            per_page: this.per_page * 3,//减少请求次数
            page: nextPage,
            sort: 'time' //rate|share|time
        }
        this.setState({
            page: nextPage,
            isShowLoading: false,
            isChangePage:true
        })
        api.queryTopRatedDataStories(params).then((response) => {
            if (response.stories.length === 0) {
                this.setState({
                    isShowLoading: false,
                })
            } else {
                this.setState({
                    isShowLoading: false,
                    isChangePage:false
                })
                let newDataList = response.stories
                this.processChartData(newDataList)
            }
        })               
    }

    pageMystoryNumberOnChange=(page)=>{
        const { userInfo } = this.props
        let nextPage=page-1;
        let params = {
            per_page: this.per_page*3-1,//减少请求次数
            page: nextPage,
            sort: 'time' //rate|share|time
        }
        this.setState({
            page: nextPage,
            isShowLoading: false,
            isChangePage:true,
        })
        let uid = this.props.userInfo.uid
        api.queryStoriesByUserId(uid,params).then((response) => {
            response.stories.map((story) => {
                story.user = _.cloneDeep(userInfo)
                return story
            })
            if (response.stories.length === 0) {
                this.setState({
                    isShowLoading: false,
                })
            } else {
                this.setState({
                    currentMenu: "My data stories",
                    hasMore: (this.state.page+1)*(this.per_page*3-1)>=this.state.totalCount? false:true,
                    isShowLoading: false,
                    isChangePage:false
                })
                let isQueryMyStories = true
                this.processChartData(response.stories, isQueryMyStories)
            }

           
        })
    }
    loadingData = () => {
        let nextPage = this.state.page + 1
        let params = {
            per_page: this.per_page * 3,//减少请求次数
            page: nextPage,
            sort: 'time' //rate|share|time
        }
        this.setState({
            page: nextPage,
            isShowLoading: false
        })
        api.queryTopRatedDataStories(params).then((response) => {
            if (response.stories.length === 0) {
                this.setState({
                    hasMore: false,
                })
            } else {
                let newDataList = _.cloneDeep(this.state.dataStories).concat(response.stories)
                this.processChartData(newDataList)
            }
        })
    }
    handleClick = (key) => {
        if (key === 'My data stories') {
            this.setState({
                isShowLoading: true,
                hasMore: true,
                isQueryMyStories:true
            })
            this.queryMyStories();
        } else {
            this.setState({
                isShowLoading: true,
                hasMore: true,
                isQueryMyStories:false
            })
            this.queryPublishedDatastories();
        }
    }
    queryPublishedDatastories = () => {
        let params = {
            per_page: this.per_page * 3,//初始请求
            page: 0,
            sort: 'time'
        }
        api.queryTopRatedDataStories(params).then((response) => {
            this.setState({
                currentMenu: "Published data stories",
                page: 0,
                totalCount:response.totalCount,
                totalPage:Math.ceil(response.totalCount/this.per_page) 
            })
            this.processChartData(response.stories)
        })
    }
    queryMyStories = () => {
        const { userInfo } = this.props
        let params = {
            per_page: 14,
            page: 0,
            sort: 'time'
        }
        this.setState({
            page: 0,
            // pageShow:false
        })
        let uid = this.props.userInfo.uid
        api.queryStoriesByUserId(uid,params).then((response) => {
            response.stories.map((story) => {
                story.user = _.cloneDeep(userInfo)
                return story
            })
            this.setState({
                currentMenu: "My data stories",
                hasMore: (this.state.page+1)*(this.per_page*3-1)>=response.totalCount? false:true,
                totalCount:response.totalCount,
                totalPage:Math.ceil(response.totalCount/(this.per_page*3-1)) 
            })
            let isQueryMyStories = true
            this.processChartData(response.stories, isQueryMyStories)
        })
    }
    resetSearch = () => {
        this.props.history.push({
            pathname: `/index/explore/stories`
        })
    }
    render() {
        const { dataStories, isShowLoading, defaultInputValue, myStories, currentMenu, hasMore,isChangePage } = this.state
        const { getCardNumber, isLogIn, intl } = this.props
        this.per_page = getCardNumber

        return (
            <div className='ExploreStoriesPageContent responsivePageContent'>
                <NoBoderRadiusSearchView defaultValue={defaultInputValue}
                    search={(value) => this.onSearchListener(value)}
                    onResetSearchListener={() => this.resetSearch()}
                    intl={intl}
                />
                <Tabs defaultActiveKey={currentMenu} activeKey={currentMenu} onChange={this.handleClick}>
                    <TabPane tab={intl.get("Published data stories")} key="Published data stories">
                        <Layout className='ExploreStoriesList'>
                            {
                                isShowLoading ?
                                    <div className="loading-container">
                                        <Spin tip={intl.get("Loading")} />
                                    </div>
                                    :
                                    dataStories && dataStories.length === 0 ?
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                        :
                                        <Spin tip={intl.get("Loading")} spinning={isChangePage}>
                                        <CardList stories={dataStories} {...this.props} />
                                        </Spin>
                            }
                        </Layout>
                    </TabPane>
                    {
                        isLogIn ?
                            <TabPane tab={intl.get("My data stories")} key="My data stories">
                                <Layout className='ExploreStoriesList'>
                                    {
                                        isShowLoading ?
                                            <div className="loading-container">
                                                <Spin tip={intl.get("Loading")} />
                                            </div>
                                            :
                                            <Spin tip={intl.get("Loading")} spinning={isChangePage}>
                                            <CardList stories={myStories} isMyStories={true} {...this.props} />
                                            </Spin>
                                    }
                                </Layout>
                            </TabPane>
                            :
                            null
                    }
                </Tabs>
               { isShowLoading || !hasMore || this.state.isQueryMyStories?null:(<div className="StroyFootPage">
                    <Divider />
                    <Pagination  size="small" current={this.state.page+1}   total={this.state.totalCount} pageSize={15}  onChange={this.pageNumberOnChange}/>
                </div>)
                }
                 { !this.state.isQueryMyStories?null:(<div className="StroyFootPage">
                    <Divider />
                    <Pagination  size="small" current={this.state.page+1}   total={this.state.totalCount} pageSize={14}  onChange={this.pageMystoryNumberOnChange}/>
                </div>)
                }
                {!hasMore && (
                    <div style={{ width: "100%", textAlign: "center" ,height:"70px" }}>
                        <span style={{ color: 'grey' }}> {intl.get("no more data")}</span>
                    </div>
                )}
            </div >)
             // <InfiniteScroll
        //     style={{ paddingBottom: "30px" }}
        //     initialLoad={false}
        //     pageStart={0}
        //     loadMore={this.handleInfiniteOnLoad}
        //     hasMore={hasMore}
        //     useWindow={false}
        // // loader={<div key={0}>Loading ...</div>}
        // >
        // </InfiniteScroll>

    }
}