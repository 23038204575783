import React, { Component } from 'react';
import { Menu, Dropdown } from 'antd';
import getLink from '@/pages/IndexPage/linkConfig';

export default class HorizontalMenuView extends Component {

  clickSubMenue = (link) => {
    this.props.history.push(link);
    let parentMenue = link.split("/index/")[1].split('/')[0]
    let subMenue = link.split("/index/")[1].split('/')[1]
    if (parentMenue === 'creat') {
      if (subMenue === "stories") {
        subMenue = 'creatStories'
      }
    }
    this.props.updateClickedMenus([parentMenue, subMenue])
  }

  getsubMunuesItems = (parentMunue, subMunues) => {
    return <Menu>
      {
        subMunues.map((subMunue, idx) => {
          //console.log("subMunue11", subMunue)
          return <Menu.Item key={"subMunue" + idx} className='MenuItem' onClick={() => this.clickSubMenue(getLink(subMunue))}>{this.props.intl.get(subMunue)}</Menu.Item>
        })
      }
    </Menu>
  }

  render() {
    const { menues, intl } = this.props
    let newConstructMenue = {}
    menues.map(menue => {
      newConstructMenue[Object.keys(menue)] = menue[Object.keys(menue)];
      return menue;
    })
    let parentMunues = Object.keys(newConstructMenue)
    let Menues = parentMunues.map((parentMunue, index) => {
      return <Dropdown
        key={parentMunue + ':' + index}
        overlay={this.getsubMunuesItems(parentMunue, newConstructMenue[parentMunue])}
        placement="bottomCenter" overlayClassName='customMenuBg'>
        <div className='MenuTxt'>{intl.get(parentMunue)}</div>
      </Dropdown>
    })

    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
        {
          Menues
        }
      </div>
    )
  }
}