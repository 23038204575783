import React, { Component } from 'react';
import { Spin, Table, Pagination, Divider } from 'antd';
import { usePapaParse } from 'react-papaparse';
import * as echarts from 'echarts';
import config from '@/axios/config';
import './DatasetDetail.less';

import categoricalIcon from '../../../image/dataType/categorical.png';
import geographicalIcon from '../../../image/dataType/geographical.png';
import idIcon from '../../../image/dataType/id.png';
import numericalIcon from '../../../image/dataType/numerical.png';
import temporalIcon from '../../../image/dataType/temporal.png';
import textIcon from '../../../image/dataType/text.png';
import unknownIcon from '../../../image/dataType/unknown.png';

const DEFAULT_ICON_TYPE = {
    categorical: categoricalIcon,
    geographical: geographicalIcon,
    id: idIcon,
    numerical: numericalIcon,
    temporal: temporalIcon,
    text: textIcon,
    unknown: unknownIcon
}
const DEFAULT_TEST_DATA = {
    text: { 'unique_values': 20 },
    temporal: { '1970': 27, '1971': 94, '1972': 118, '1973': 44, '1974': 19, '1975': 47, '1976': 13, '1977': 14, '1978': 5, '1980': 8 },
    numerical: { 'stat': { 'min_value': 82, 'avg_value': 100, 'max_value': 165 }, 'range': [['80', '89', 27], ['89', '98', 94], ['98', '107', 118], ['107', '116', 44], ['116', '125', 19], ['125', '134', 47], ['134', '143', 13], ['143', '152', 14], ['152', '161', 5], ['161', '170', 5]] },
    geographical: { 'USA': 0.62, 'Japan': 0.2, 'Other': 0.18 },
    categorical: { 'BMW': 5, 'Ford': 10, 'Benz': 8 }
}

export default class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tableWidth: 0,
            loading: false,
            current: 1,
            pageSize: 10
        };
        this.wrapper = React.createRef();
        this.idKey = 'id' + new Date().valueOf();
    }

    componentDidMount() {
        if (!this.wrapper.current) {
            return;
        }
        this.setState({ tableWidth: this.wrapper.current.offsetWidth });

        const { data = {} } = this.props;
        const { url } = data;

        if (url) {
            const { readRemoteFile } = usePapaParse();

            this.setState({ loading: true });
            readRemoteFile(config.url.uploadDataPrefix + url, {
                complete: (result) => {
                    const { data: list = [] } = result || {};

                    this.setState({ loading: false, list: list.slice(1) });
                },
                error: (error) => {
                    console.error('error', error);
                    this.setState({ loading: false, errorMsg: '数据文件加载失败' });
                }
            });
        }
    }

    render() {
        const { list = [], current, pageSize } = this.state;
        const { data = {}, intl } = this.props;
        const { data: fileName = '', size = '0kb' } = data;
        const locale = intl.getInitOptions().currentLocale || '';
        let schema = {};
        try {
            schema = JSON.parse(data.schema);
        } catch (e) {
            console.error(e);
        }
        const { statistics = {}, fields = [] } = schema;
        const columns = fields.map(item => {
            return {
                title: <Column key={item.field} data={item} total={statistics.row} />,
                dataIndex: item.field,
                ellipsis: true,
                width: this.state.tableWidth / 7 + 'px'
            }
        });
        console.info(schema);

        return (
            <div ref={this.wrapper}>
                <div className='fileInfo'>
                    <div className='fileName'>{fileName}.csv</div>
                    <div className='fileSize'>({size})</div>
                </div>
                {this.state.tableWidth > 0 &&
                    <div className='customTableWrapper headNoPadding'>
                        <Spin tip={intl.get('Loading')} spinning={this.state.loading}>
                            <Table
                                dataSource={list.slice((current - 1) * pageSize, current * pageSize).map((item, index) => {
                                    const result = { [this.idKey]: (current - 1) * pageSize + index };

                                    columns.forEach((col, index) => {
                                        result[col.dataIndex] = item[index];
                                    });

                                    return result;
                                })}
                                columns={columns}
                                locale={
                                    locale.indexOf('zh') !== -1 ? { emptyText: '暂无数据' } : { emptyText: 'No Data' }
                                }
                                rowKey={this.idKey}
                                pagination={false}
                                scroll={{ x: this.state.tableWidth }}
                            />
                        </Spin>
                        <div className='DataSetsFootPage'>
                            <Divider />
                            <Pagination
                                size='small'
                                current={current}
                                pageSize={pageSize}
                                total={statistics.row}
                                onChange={(current) => this.setState({ current })}
                            />
                        </div>
                    </div>
                }
            </div>
        );
    }
}

class Column extends Component {
    generateData(data, total) {
        let type = (data.type || '').toLowerCase(), newRange = [], result = {};

        if (type === 'numerical') {
            const { summary = {} } = data;
            const { stat, range } = summary;

            if (range) {
                try {
                    const object = JSON.parse(range);

                    Object.keys(object).forEach(key => {
                        const array = key.split(',');

                        if (data.subtype === 'integer+') {
                            newRange.push([parseInt(array[0].replaceAll('[', '')), parseInt(array[1].replaceAll('[', '')), object[key]]);
                        } else {
                            newRange.push([parseFloat(array[0].replaceAll('[', '')), parseFloat(array[1].replaceAll('[', '')), object[key]]);
                        }
                    });

                    result = { stat, range: newRange };
                } catch (e) {
                    console.error(e);
                }
            }
        } else if (type === 'temporal') {
            const { summary } = data;

            if (summary) {
                try {
                    const object = JSON.parse(summary);

                    result = { ...object };
                } catch (e) {
                    console.error(e);
                }
            }
        } else if (type === 'geographical') {
            const { summary } = data;

            if (summary) {
                Object.keys(summary).forEach(key => {
                    result[key] = Math.round(summary[key] / total * 10000) / 10000;
                });
            }
        } else if (type === 'categorical') {
            const { summary } = data;

            result = { ...summary };
        }

        return result;
    }

    render() {
        const { data, total } = this.props;
        const { field, values = [], summary = {} } = data;
        const { unique_values } = summary;
        let type = (data.type || '').toLowerCase();
        // console.info('add', this.props, this.generateData(data));

        return (
            <div className='columnWrapper'>
                <div className='columnName'>
                    <div className='type'><img src={DEFAULT_ICON_TYPE[type] || unknownIcon} alt={type} /></div>
                    <div className='name'>{field}</div>
                </div>
                <div className='statisticsWrapper'>
                    <div className='content'>
                        {
                            // (type === 'id' || type === 'text') && (!values || values.length === 0 || values.length === total) ?
                            type === 'id' || type === 'ID' || type === 'text' ?
                                <div className='uniqueValues'>
                                    <div className='number'>{unique_values || values.length}</div>
                                    <div className='desc'>unique values</div>
                                </div> :
                                type === 'categorical' || type === 'numerical' || type === 'temporal' ?
                                    <BarChart key={field} field={field} type={type} data={this.generateData(data, total)} /> :
                                    type === 'geographical' ?
                                        <Geographical key={field} field={field} data={this.generateData(data, total)} othersNum={values.length - 2} /> :
                                        <div className='uniqueValues'>
                                            <div className='number'>{total}</div>
                                            <div className='desc'>unique values</div>
                                        </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

class Geographical extends Component {
    render() {
        const { data, othersNum } = this.props;

        return (
            <div className='geographicalWrapper'>
                {Object.keys(data).map((key, index) => {
                    const value = data[key] || 0;

                    return (
                        <div className='item' key={key}>
                            <div className='name'>{key}{index === Object.keys(data).length - 1 ? `(${othersNum})` : ``}</div>
                            <div className='value'>{Math.round((isNaN(value) ? 0 : value) * 10000) / 100}%</div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

class BarChart extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.ref = React.createRef();
    }

    componentDidMount() {
        this.initChart();
    }

    initChart() {
        if (this.ref.current) {
            this.chart = echarts.init(this.ref.current);
            const { type, data } = this.props;
            const list = [], series = [];

            if (type === 'categorical' || type === 'temporal') {
                Object.keys(data).forEach(key => {
                    list.push(key);
                    series.push(data[key]);
                });
            } else if (type === 'numerical') {
                const { range = [] } = data;

                range.forEach(arry => {
                    list.push(arry[0]);
                    series.push(arry[arry.length - 1]);
                });
            }

            this.option = {
                color: ['#FFB348'],
                tooltip: {
                    confine: true,
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                grid: {
                    top: 10,
                    right: 10,
                    bottom: 20,
                    left: 10,
                    // containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: list,
                    axisLine: {
                        show: false
                    },
                    axisLabel: {
                        interval: type === 'categorical' ? 'auto' : 0,
                        color: '#666666',
                        fontSize: 10,
                        formatter: (value, index) => {
                            if (type === 'categorical') {
                                return value;
                            } else {
                                if (index === 0 || index === (list.length - 1)) {
                                    return value;
                                }
                                return '';
                            }
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    }
                },
                series: [
                    {
                        type: 'bar',
                        data: series
                    }
                ]
            };

            this.chart.setOption(this.option);
        }
    }

    render() {
        return (
            <div ref={this.ref} style={{ height: 104, width: '100%' }}></div>
        );
    }
}