const templates = [
    {
        'id': 0,
        'template': 'When {{subspace}}, the difference between {{focus1}} and {{focus2}} is {{parameter}} in terms of {{agg}} {{measure}}.',
    },
    {
        'id': 1,
        'template': 'When {{subspace}}, {{focus1}} and {{focus2}}\'s difference on the {{agg}} {{measure}} is {{parameter}}.',
    },
    {
        'id': 2,
        'template': 'When {{subspace}}, {{focus1}} is {{parameter}} {{higher/lower}} than {{focus2}} in terms of {{agg}} {{measure}}.',
    },
]

export default templates;