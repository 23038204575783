import React, { useState } from 'react';
import {  Collapse ,Tag, Checkbox,message} from 'antd';
import {Tooltip,Slider,Divider } from 'antd';
import NumericalFilter from './NumericalFilter';
import draw from './BarBrush';
import config from '@/axios/config';
import "./index.less";
const plur = require('plur');
const { Panel } = Collapse;
const { CheckableTag } = Tag;

const convertField = function (field, param = 'single') {
    let gb = field
    if (param === 'single')
        return gb
    else if (param === 'plural') {
        if (gb.indexOf(' of ') !== -1) {
            let gbWords = gb.split(" ")
            let gbWordIndex = gbWords.indexOf("of") - 1
            if (gbWordIndex > -1) {
                let plurWord = plur(gbWords[gbWordIndex], 2)
                return gb.replace(gbWords[gbWordIndex], plurWord)
            }
        } else
            return plur(gb, 2)
    }
}
export default class TopicSettings extends React.Component{
    constructor(props) {
        super(props);
        let checkedTag=[]
        if(props.filters){
            checkedTag=props.schema.filter(d => d.type === 'categorical' || d.type === "geographical").map((item, index)=>{
            let temp=props.filters.filter(filter => filter.field ===  item.field)[0].value
            return { 'field': item.field, 'checked': temp.length===item.values.length?true:(temp.length===0?false:"indeterminate")};
        })
        }
        this.state = {
            checkedTag:checkedTag
        }
    }
    // shouldComponentUpdate(nextProps) {
    //     return !_.isEqual(this.props.filter, nextProps.filter)
    // }
    onChange=(e,field,checkedTag1,props)=>{
        checkedTag1.filter(filter=>filter.field===field)[0].checked=e.target.checked
        let filterOriginValue = props.filtersOrigin.filter(filterOrigin =>filterOrigin.field === field)[0].value
        let filter1 = props.filters;
        let filterTitle=props.filtersTitle
        if(!e.target.checked){
            message.info(props.intl.get("EmptyTag"))
        }
        filter1.filter(filter => filter.field === field)[0].value = e.target.checked?filterOriginValue:[]
        filterTitle.filter(filter => filter.field === field)[0].title=e.target.checked?`All ${field}`:``
        props.updateFilters(filter1);
        props.updateFiltersTitle(filterTitle);
        props.updateFiltersChangeFlag(true); 
        this.setState({
            checkedTag:checkedTag1
        })
      }
    onChangeTag=(field,checkedTag1,filters,schema)=>{
        let temp1=filters.filter(filter => filter.field ===  field)[0].value
        let item=schema.filter(filter => filter.field ===  field)[0].values
        checkedTag1.filter(filter=>filter.field===field)[0].checked = temp1.length===item.length?true:(temp1.length===0?false:"indeterminate")
        this.setState({
            checkedTag:checkedTag1
        })
      }

    render(){
        const { intl,schema} = this.props;
        let {checkedTag} = this.state;
        let description_all = "All ";
        let description_select = "Selected ";
        if(checkedTag.length===0 ||checkedTag.length!==schema.filter(d => d.type === 'categorical' || d.type === "geographical").length){
            checkedTag=schema.filter(d => d.type === 'categorical' || d.type === "geographical").map((item, index)=>{
            let temp=this.props.filters.filter(filter => filter.field ===  item.field)[0].value
            return { 'field': item.field, 'checked': temp.length===item.values.length?true:(temp.length===0?false:"indeterminate")};
          })
      }
        const CategoricalFilter = (props) => {
            let values = props.info.values;
            let field = props.info.field;
            let filter = props.filters.filter(filter => filter.field === field);
            let checkedOrNot=checkedTag.filter(filter=>filter.field===field)[0].checked
            let filterOrigin = props.filtersOrigin.filter(filterOrigin =>filterOrigin.field === field)
            let selectedTags = checkedOrNot === true ? filterOrigin[0].value :(checkedOrNot === false ? [] : filter[0].value);

            return (
                <div className="CategoricalFilter">
                    <div className="topicContent">
                        <Tooltip title={field}>
                        <p className="fieldText">{field}</p>
                        </Tooltip>
                    </div>
                    <div className="topicDetails">
                        <div className={checkedOrNot===true?"allTagChoose-checked":"allTagChoose"}>
                        {/* eslint-disable-next-line no-restricted-globals */}
                            <Checkbox checked={checkedOrNot===true?true:false} onChange={()=>props.checkTag(event,field,checkedTag,props)}>{intl.get("TagClear")}</Checkbox>
                        </div>
                        {values.map(tag => (
                            <CheckableTag
                                key={tag}
                                checked={selectedTags.indexOf(tag) > -1}
                                onChange={(checked) => {
                
                                    let nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
                                    //  setSelectedTags(nextSelectedTags);
                                    
                                    let filter = {
                                        'field': props.info.field,
                                        'type': 'equal',
                                        'value': nextSelectedTags
                                    }
                                    let pluraltitle;
                                    pluraltitle=  convertField(props.info.field,'plural')  
                                    

                                    let filterTitle = nextSelectedTags.length >= 3 ? 
                                        (nextSelectedTags.length === values.length? 
                                            {'field': props.info.field, 'type' : props.info.type, 'title': description_all + pluraltitle}:
                                            {'field': props.info.field, 'type' :  props.info.type, 'title': description_select+ pluraltitle}
                                        ):
                                        {'field': props.info.field, 'type' :  props.info.type, 'title': nextSelectedTags.join(',')};


                                    let filters = props.filters;
                                    let filtersTitle = props.filtersTitle;


                                    let findFilter = false;
                                    let findFilterTitle = false;
                                    for (let i = 0; i < filters.length; i++) {
                                        if (filters[i].field === filter.field) {
                                            filters[i] = filter;
                                            findFilter = true;
                                            break;
                                        }
                                    }

                                    for (let i = 0; i < filtersTitle.length; i++) {
                                        if (filtersTitle[i].field === filterTitle.field) {
                                            filtersTitle[i] = filterTitle;
                                            findFilterTitle = true;
                                            break;
                                        }
                                    }

                                    if (findFilter === false) {
                                        filters.push(filter);
                                    }

                                    if (findFilterTitle === false) {
                                        filtersTitle.push(filterTitle);
                                    }

                                    props.updateFilters(filters);
                                    props.updateFiltersTitle(filtersTitle);
                                    props.updateFiltersChangeFlag(true); 
                                    props.onChangeTag(field,checkedTag,filters,schema)
                                    // this.props.orderFacts(0, 0);
                                    //props.filteredgenerate(props.postUrl, filter);
                                }}
                            >
                                {/\r/.test(tag) === true? 'Null': tag}
                            </CheckableTag>
                        ))}
                    </div>
                    <Divider />
                </div>
            )
        };

        const TemporalFilter = (props) => {
            let values = props.info.values;
            let field = props.info.field;
            let filter = props.filters.filter(filter => filter.field === field);
            const [defaultValue, setDefaultValue] = useState(filter[0].value2);

            let marks = {};

            // let minIndex = values.indexOf(filter.value[0]);
            // let maxIndex = values.indexOf(filter.value[1]);
            for (let i = 0; i < values.length; i++) {
                if (i === 0) {
                    marks[parseInt(i / (values.length - 1) * 100)] = {
                        label: <span className= "timeMarkMin" >{filter[0].value[0].split("/")[0]}</span>,
                    }
                } else if(i === (values.length - 1)) {
                    marks[parseInt(i / (values.length - 1) * 100)] = {
                        label: <span className= "timeMarkMax" >{filter[0].value[filter[0].value.length - 1].split("/")[0]}</span>,
                    }
                }
                else {
                    marks[parseInt(i / (values.length - 1) * 100)] = {
                        label: <span></span>,
                    }
                }
            }


            return (
                <div className="TemporalFilter">
                    <div className="topicContent">
                        <Tooltip title={field}>
                            <p className="fieldText">{field}</p>
                        </Tooltip>
                    </div>

                    <div className="topicDetails">
                        <Slider range = {true}
                            marks={marks}
                            defaultValue={defaultValue}
                            dots={false} 
                            step={1}
                            tipFormatter={(value) => value === 100 ? values[values.length - 1] :  values[parseInt(value / (100 / (values.length)))]}
                            onChange={(value) => {
                                let start = parseInt(value[0] / 100 * (values.length ));
                                let end = value[1] === 100 ? values.length - 1 : parseInt(value[1] / 100 * (values.length ));
                                let filter = {
                                    'field': props.info.field,
                                    'type': 'equal',
                                    'value': props.info.values.filter((d, i) => {
                                        return i >= start && i <= end
                                    }),
                                    'value2': value,
                                    'value3': [start, end]
                                }
                                let filterTitle = {'field': props.info.field, 'type' : 'temporal', 'title': props.info.values[start] + '-' + props.info.values[end]};

                                let filters = props.filters;
                                let filtersTitle = props.filtersTitle;


                                let findFilter = 'findFilter-false';
                                let findFilterTitle = 'findFilterTitle-false';
                                for (let i = 0; i < filters.length; i++) {
                                    if (filters[i].field === filter.field) {
                                     //   filters[i] = filter;
                                        findFilter = i;
                                        break;
                                    }
                                }


                                for (let i = 0; i < filtersTitle.length; i++) {
                                    if (filtersTitle[i].field === filterTitle.field) {
                                        filtersTitle[i] = filterTitle;
                                        findFilterTitle = i;
                                        break;
                                    }
                                }
  

                                if (findFilter === 'findFilter-false') {
                                    filters.push(filter);
                                }

                                if (findFilterTitle === 'findFilterTitle-false') {
                                    filtersTitle.push(filterTitle);
                                }

                                
                                //判断 - 如果滑块的new range和old range一样，就不change，否则交互不smooth
                                if(findFilter !== 'findFilter-false' && filters[findFilter].value3[0] === start && filters[findFilter].value3[1] === end) {
                                    setDefaultValue(value);
                                }
                                else {
                                    setDefaultValue(value);
                                    filter.value2 = [start*(100 / (values.length - 1)), end*(100 / (values.length - 1))];
                                    filters[findFilter] = filter;
                                    
                                    props.updateFilters(filters);
                                    props.updateFiltersTitle(filtersTitle);
                                    props.updateFiltersChangeFlag(true); 
                                    // this.props.orderFacts(0, 0);
                                }
                            }} 
                            />
                    </div>
                    <Divider />
                </div>

            )

        };

        return(
            <Collapse defaultActiveKey={["topicFilter-data-1"]}>
            <Panel header={intl.get("Topic Filter")} key="topicFilter-data-1">

                <div className="TopicFilter">
                {
                       schema.filter(d => d.type !== 'text' && d.type !== "ID").map((info, index) => {
                            if (info.type === 'categorical'||info.type==='geographical') {
                                return (
                                    // eslint-disable-next-line no-restricted-globals
                                    <CategoricalFilter key={"Categorical"+index} info={info} filters={this.props.filters} filtersOrigin={this.props.filtersOrigin} updateFilters={this.props.updateFilters} filtersTitle={this.props.filtersTitle} updateFiltersTitle={this.props.updateFiltersTitle} filteredgenerate={this.filteredgenerate} postUrl={config.url.generateRandomlyNew}  updateFiltersChangeFlag = {this.props.updateFiltersChangeFlag}  checkTag={()=>this.onChange(event,info.field,checkedTag,this.props)} onChangeTag={this.onChangeTag}/>
                                );
                            } else if (info.type === 'numerical') {
                                return (
                                    <NumericalFilter key={"Numerical"+index} info={info} draw={draw} id={`numericalFilter-${index}`} filters={this.props.filters} filterIndex={index} updateFilters={this.props.updateFilters} updateFiltersChangeFlag = {this.props.updateFiltersChangeFlag} isHiddenFactView={this.props.isHiddenFactView} filtersChangeFlag={this.props.filtersChangeFlag}/>
                                );
                            } else if (info.type === 'temporal') {
                                return (
                                    <TemporalFilter key={"Temporal"+index} info={info} filters={this.props.filters} updateFilters={this.props.updateFilters} filtersTitle={this.props.filtersTitle} updateFiltersTitle={this.props.updateFiltersTitle} filteredgenerate={this.filteredgenerate} postUrl={config.url.generateRandomlyNew} updateFiltersChangeFlag = {this.props.updateFiltersChangeFlag}/>
                                );
                            }
                            return null
                         
                        })
                    }

                </div>

                {/* <UpdateView/> */}
                {/* <Row className="UpdateView" >
                    <Col onClick={() => this.filteredgenerate(config.url.generateRandomly)}>
                        <p> {initDone && intl.get("Update")}</p>
                    </Col>
                </Row> */}
            </Panel>
            
        </Collapse>
        )
    }
}