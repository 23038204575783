import React, { Component } from "react";
import config from '@/axios/config';
import { Avatar } from 'antd';
import './AvatarView.less';

export default class AvatarView extends Component {
    render() {
        const { isSmallAvatar, userInfo } = this.props
        return (
            <Avatar size={isSmallAvatar ? 24 : 48}
                id='AvatarView'
                className="AvatarView"
                src={`${config.url.userImage}/${userInfo.avatar}`}
                style={{ backgroundColor: '#ffe3b1', color: "#38291f" }}
            >{userInfo && userInfo.username.substr(0, 1)}</Avatar>
        )
    }
}