import { connect } from 'react-redux';
import { isCollapsed, isOpenLeftFloatMenue, userEnd, getUserInfo } from '@/selector/user';
import { fileName, facts, relations, data, resultCoverage, showSuggestion } from '@/selector/story';
import * as userAction from '@/action/userAction';
import HomePage from './HomePage';

const mapStateToProps = (state) => ({
    //selectedMenue: selectedMenue(state),
    userEnd: userEnd(state),
    isOpenLeftFloatMenue: isOpenLeftFloatMenue(state),
    isCollapsed: isCollapsed(state),
    //isLogIn: isLogIn(state),
    userInfo: getUserInfo(state),
    facts: facts(state),
    relations: relations(state),
    data: data(state),
    resultCoverage: resultCoverage(state),
    fileName: fileName(state),
    //title: title(state)
    showSuggestion: showSuggestion(state)

})

const mapDispatchToProps = dispatch => {
    return {
        updateLastPage: (lastPage) => dispatch(userAction.updateLastPage(lastPage)),
        updateUserEnd: (userEndSize) => dispatch(userAction.updateUserEnd(userEndSize)),
        windowUpScroller: (isWindowUpScroll) => dispatch(userAction.windowUpScroller(isWindowUpScroll)),
        openLeftFloatMenue: (isOpenLeftFloatMenue) => dispatch(userAction.openLeftFloatMenue(isOpenLeftFloatMenue)),
        updateClickedMenus: (item) => dispatch(userAction.updateClickedMenus(item)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
