const templates = [
    {
        'id': 0,
        'template': '{{Sentence A}} {{Sentence B}}',
    },
    {
        'id': 1,
        'template': '{{Sentence A}} {{Sentence B}}',
    },
    {
        'id': 2,
        'template': '{{Sentence A}} {{Sentence B}}',
    },
]

export default templates;