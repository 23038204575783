import React, { Component } from 'react';
import { Table } from 'antd';
import _ from 'lodash';
import './index.css';


export default class TableView extends Component {
    constructor(props) {
        super(props);
        let rowsNum;
        // rowsNum = parseInt((this.props.visContainerSize.height - 40-34-20-10) / 40) // 40: header row, 34: page navigator
        // rowsNum = parseInt((this.props.visContainerSize.height - 38-35-20) / 38) // 40: header row, 34: page navigator
        rowsNum = parseInt((this.props.visContainerSize.height - 168) / 45) // 40: header row, 34: page navigator
        this.state = {
            selectedRowKeys: [], 
            rowsNum: rowsNum
        };
    }

    UNSAFE_componentWillUpdate(nextProps, nextState) {
        if(parseInt((nextProps.visContainerSize.height- 168) / 45) !== this.state.rowsNum) {
            let _r = parseInt((nextProps.visContainerSize.height - 168) / 45)
            this.setState({
                rowsNum: _r<=0?0:_r
            })
        }
  }


    render() {
        const { data, schema,datares } = this.props;
        let data_table;
        let datatemp
        if(datares&&datares!==[]){
            data_table=datares.map((item, index) =>({...item, key: index}))
            datatemp=datares
        } 
        else{
            data_table=data.map((item, index) =>({...item, key: index}))
            datatemp=data
        } 

        const columns = schema.map((col, index) => {
            if(col.type==='categorical') { // 对应筛选
                return {
                    title: col.field,
                    dataIndex: col.field,
                    filters: _.keys(_.countBy(datatemp, function(data) { return data[col.field]; })).map((item,i)=>{
                        return {
                            text: item,
                            value: item
                        }
                    }),
                    onFilter: (value, record) => record[col.field].indexOf(value) === 0,
                }
            } else if(col.type==='temporal'){ // 对应排序
                return {
                    title: col.field,
                    dataIndex: col.field,
                    sorter: (a, b) => new Date(a[col.field]) - new Date(b[col.field]),
                    sortDirections: ['descend', 'ascend'],
                }
            } else { // 对应排序
                return {
                    title: col.field,
                    dataIndex: col.field,
                    sorter: (a, b) => a[col.field] - b[col.field],
                    sortDirections: ['descend', 'ascend'],
                }
            }
            
        })

        const paginationProps = {
            pageSize: this.state.rowsNum
        }
        
        return <div style={{padding: '10px 0 10px 0',overflowX:"scroll"}}>
                <Table className="dataTable" size="small" style={{ width: "100%", padding:'5px'}} columns={columns} dataSource={data_table} pagination={ paginationProps } />
            </div>

        
    }
}