import React from 'react';
import ChartType from '../constant/ChartType';
import getSupportedChartTypes from '@/tool/getSupportedChartTypes';
import Chart from '../Chart';
import { isValid, customizeFact } from '@/view/FactView/helper';
import _ from 'lodash';
import datafilter from './datafilter';
import AreaChart from '../charts/AreaChart';
import BarChart from '../charts/BarChart';
import RankedBarChart from '../charts/RankedBarChart';
import ISOType from '../charts/ISOType';
import NumberChart from '../charts/NumberChart';
import LineChart from '../charts/LineChart';
import ProportionChart from '../charts/ProportionChart';
import ScatterPlot from '../charts/ScatterPlot';
import BubbleChart from '../charts/BubbleChart';
import Map from '../charts/Map';
const defaultSpec = {
    "encoding": {},
    "style": {}
}

export const facts2charts = (facts, shema, chartDiversity = 0) => {
    for (let i = 0; i < facts.length; i++) {
        let supportedChartTypes = getSupportedChartTypes(shema, facts[i]);
        let choiceCount = parseInt((supportedChartTypes.length + 1) * chartDiversity);
        let choicedChartTypes = supportedChartTypes.slice(0, choiceCount + 1);
        facts[i].chart = choicedChartTypes[Math.floor(Math.random() * choicedChartTypes.length)].chart;
    }
    return facts
}
export const getChoice = (length) => {
    let choice = Math.round(Math.random() * (length - 1));
    return choice;
}


export const getFactChartType = (shema, fact, choice = 0) => {
    let supportedChartTypes = getSupportedChartTypes(fact, shema);
    //console.log("supportedChartTypes", fact.type, fact, supportedChartTypes)
    if (supportedChartTypes.length === 0) return null;
    return supportedChartTypes[getChoice(supportedChartTypes.length)].chart;
}

const getvischartype = (chart) => {
    let chartType = chart;
    switch (chart) {
        case ChartType.AREA_CHART:
            chartType = "areachart";
            break;
        case ChartType.BUBBLE_CHART:
            chartType = "bubblechart";
            break;
        case ChartType.COLOR_FILLING_MAP:
            chartType = "filledmap";
            break;
        case ChartType.BUBBLE_MAP:
            chartType = "bubblemap";
            break;
        case ChartType.HALF_RING_CHART:
            chartType = "donutchart";
            break;
        case ChartType.HORIZONTAL_BAR_CHART:
            chartType = "horizentalbarchart";
            break;

        case ChartType.VERTICAL_BAR_CHART:
            chartType = "verticalbarchart";
            break;
        case ChartType.STACKED_BAR_CHART:
        case ChartType.VERTICAL_DIFFERENCE_BAR_CHART:
            chartType = "verticalDifferenceBarChart";
            break;
        case ChartType.VERTICAL_DIFFERENCE_ARROW_CHART:
            chartType = "verticalDifferenceArrowChart"
            break;
        case ChartType.ISOTYPE_BAR_CHART:
            chartType="isotypeBarChart"
            break;
        case ChartType.LINE_CHART:
        case ChartType.STACKED_LINE_CHART:
            chartType = "linechart";
            break;
        case ChartType.PROPORTION_ISOTYPE_CHART:
            chartType ="proportionIsotypeChart";
            break;
        case ChartType.PIE_CHART:
            chartType = "piechart";
            break;
        case ChartType.PROGRESS_BAR_CHART:
            chartType = "progresschart";
            break;
        case ChartType.RING_CHART:
            chartType = "donutchart";
            break;
        case ChartType.SCATTER_PLOT:
            chartType = "scatterplot";
            break;
        case ChartType.TEXT_CHART:
            chartType = "textchart";
            break;
        case ChartType.TREE_MAP:
            chartType = "treemap";
            break;
        default:
            break;
    }
    return chartType;
}

export const fact2chart = function (specData, uuid, fact, data, size, compound = false, oldStory = false) {
    if (fact.chart === "") {
        fact.chart = getFactChartType(specData.schema, fact);//针对生成页面的chart
    }

    let chart = fact.chart;
    // if (compound) {
    //     chart = fact.compoundChart;
    // }

    // if (chart === ChartType.ISOTYPE_BAR_CHART) {
    //     fact.chart = ChartType.VERTICAL_BAR_CHART;
    // }
    fact = customizeFact(fact);//important
    if (!fact.chart || !isValid) {
        return null;
    }
    let specFact = {
        ...fact,
        breakdown: fact.groupby.map(d => {
            return {
                "field": d
            }
        })
    }

    let specChart = {
            size: size,
            type: getvischartype(chart),
            style: "business",
            duration: 0,
            showSuggestion: !specData.showSuggestion ? false : specData.showSuggestion
        }
   
    // let specChart = {
    //     size: size,
    //     type: getvischartype(chart),
    //     style: "business",
    //     duration: 0,
    //     showSuggestion: !specData.showSuggestion ? false : specData.showSuggestion
    // }
    specData = {
        ...specData,
        values: data,
    }
    let specNew = {
        data: specData,
        fact: specFact,
        chart: specChart,
    }
    let width,height;
    switch (size) {
        case 'wide':
            height = 220;
            width = 560;
            break;
        case 'middle':
            height = 200;
            width = 360;
            break;
        case 'small':
            height = 150;
            width = 235;
            break;
        default:
            height=640;
            width=640;
            break;
        }
    console.log("spec", specNew)
    let spec = _.cloneDeep(defaultSpec);
    const filteredData = datafilter(data, fact.subspace);
    // console.log("showSuggestion", specFact.measure[0].field==="COUNT")
    if(oldStory){
        // if(specFact.measure[0].field==="COUNT"){
            switch (chart) {
                case ChartType.AREA_CHART:
                    spec.encoding['y'] = {};
                    spec.encoding['y']['field'] = fact.measure[0].field;
                    spec.encoding['y']['aggregation'] = fact.measure[0].aggregate;
                    spec.encoding['x'] = {};
                    spec.encoding['x']['field'] = fact.groupby[0];
                    if (fact.focus.length > 0) {
                        spec.style = {
                            'focus': fact.focus[0] ? fact.focus[0].value : '',
                        }
                    }
                    return <AreaChart uuid={uuid} data={filteredData} spec={spec} width={width} height={height} />;
                case ChartType.BUBBLE_CHART:
                    spec.encoding['y'] = {};
                    spec.encoding['y']['field'] = fact.measure.length > 0 ? fact.measure[0]['field'] : 'COUNT';
                    spec.encoding['y']['aggregation'] = fact.measure.length > 0 ? fact.measure[0]['aggregate'] : 'count';
                    spec.encoding['x'] = {};
                    spec.encoding['x']['field'] = fact.groupby[0];
                    spec.style = {
                        'style': 'matrix', // matrix line
                    }
                    return <BubbleChart uuid={uuid} data={filteredData} spec={spec} width={width} height={height} />
                case ChartType.COLOR_FILLING_MAP:
                    spec.encoding['color'] = {};
                    spec.encoding['color']['field'] = fact.measure[0].field;
                    spec.encoding['color']['aggregation'] = fact.measure[0].aggregate;
                    spec.encoding['area'] = {};
                    spec.encoding['area']['field'] = fact.groupby[0];
                    spec.style = {
                        'focus': fact.focus.length ? fact.focus[0].value : '',
                    }
                    return <Map uuid={uuid} data={filteredData} spec={spec} width={width} height={height} />
                case ChartType.HALF_RING_CHART:
                    spec.encoding['size'] = {};
                    spec.encoding['size']['field'] = fact.measure[0].field;
                    spec.encoding['size']['aggregation'] = fact.measure[0].aggregate;
                    spec.encoding['color'] = {};
                    spec.encoding['color']['field'] = fact.groupby[0];
                    spec.style = {
                        'focus': fact.focus[0].value,
                        'style': 'half_donut', // bar pie donut half_donut
                    }
                    return <ProportionChart uuid={uuid} data={filteredData} spec={spec} width={width} height={height} />
                case ChartType.HORIZONTAL_BAR_CHART:
                    spec.encoding['y'] = {};
                    spec.encoding['y']['field'] = fact.measure[0].field;
                    spec.encoding['y']['aggregation'] = fact.measure[0].aggregate;
                    spec.encoding['x'] = {};
                    spec.encoding['x']['field'] = fact.groupby[0];
                    spec.style = {
                        'order': 'descending',
                    }
                    return <RankedBarChart uuid={uuid} data={filteredData} spec={spec} width={width} height={height} />;
                case ChartType.VERTICAL_DIFFERENCE_BAR_CHART:
                    spec.encoding['y'] = {};
                    spec.encoding['y']['field'] = fact.measure[0].field;
                    spec.encoding['y']['aggregation'] = fact.measure[0].aggregate;
                    spec.encoding['x'] = {};
                    spec.encoding['x']['field'] = fact.groupby[0];
                    if (fact.focus.length > 1) {
                        spec.style = {
                            'difference': [fact.focus[0].value, fact.focus[1].value],
                        }
                    }
                    return <BarChart uuid={uuid} data={filteredData} spec={spec} width={width} height={height} />
                case ChartType.VERTICAL_DIFFERENCE_ARROW_CHART:
                    spec.encoding['y'] = {};
                    spec.encoding['y']['field'] = fact.measure[0].field;
                    spec.encoding['y']['aggregation'] = fact.measure[0].aggregate;
                    spec.encoding['x'] = {};
                    spec.encoding['x']['field'] = fact.groupby[0];
                    if (fact.focus.length > 1) {
                        spec.style = {
                            'difference': [fact.focus[0].value, fact.focus[1].value],
                        }
                    }
                    return <BarChart uuid={uuid} data={filteredData} spec={spec} width={width} height={height} />
                case ChartType.ISOTYPE_BAR_CHART:
                    spec.encoding['y'] = {};
                    spec.encoding['y']['field'] = fact.measure[0].field;
                    spec.encoding['y']['aggregation'] = fact.measure[0].aggregate;
                    spec.encoding['x'] = {};
                    spec.encoding['x']['field'] = fact.groupby[0];
                    if (fact.focus.length > 0) {
                        spec.style = {
                            'focus': [],
                        }
                        for (const focus of fact.focus) {
                            spec.style['focus'].push(focus.value)
                        }
                    }
                    return <ISOType uuid={uuid} data={filteredData} spec={spec} width={width} height={height} />
                // case ChartType.JUXTAPOSITION:
                //     vis1 = <div></div>
                //     vis2 = <div></div>
                //     if (isValid(fact)) {
                //         vis1 = fact2chart(uuid, fact, data, width, height);
                //     }
                //     if (isValid(fact.aggregatedFact)) {
                //         vis2 = fact2chart(uuid + 1, fact.aggregatedFact, data, width, height);
                //     }
                    
                //     return <Row style={{ width: width, height: height - 10 }}>
                //         <Col span={12}>
                //             <div style={{ transform: 'scale(0.5)', transformOrigin: 'left', height: height - 10 }}>
                //                 {vis1}
                //             </div>
                //         </Col>
                //         <Col span={12}>
                //             <div style={{ transform: 'scale(0.5)', transformOrigin: 'left', height: height - 10 }}>
                //                 {vis2}
                //             </div>
                //         </Col>
                //     </Row>
                case ChartType.LINE_CHART:
                    spec.encoding['y'] = {};
                    spec.encoding['y']['field'] = fact.measure[0].field;
                    spec.encoding['y']['aggregation'] = fact.measure[0].aggregate;
                    spec.encoding['x'] = {};
                    spec.encoding['x']['field'] = fact.groupby[0];
                    spec.style = {
                        'focus': fact.focus.length ? fact.focus[0].value : '',
                    }
                    return <LineChart uuid={uuid} data={filteredData} spec={spec} width={width} height={height} />;
                case ChartType.PIE_CHART:
                    spec.encoding['size'] = {};
                    spec.encoding['size']['field'] = fact.measure[0].field;
                    spec.encoding['size']['aggregation'] = fact.measure[0].aggregate;
                    spec.encoding['color'] = {};
                    spec.encoding['color']['field'] = fact.groupby[0];
                    spec.style = {
                        'focus': fact.focus[0].value,
                        'style': 'pie', // bar pie donut half_donut
                    }
                    return <ProportionChart uuid={uuid} data={filteredData} spec={spec} width={width} height={height} />
                case ChartType.PROGRESS_BAR_CHART:
                    spec.encoding['size'] = {};
                    spec.encoding['size']['field'] = fact.measure[0].field;
                    spec.encoding['size']['aggregation'] = fact.measure[0].aggregate;
                    spec.encoding['color'] = {};
                    spec.encoding['color']['field'] = fact.groupby[0];
                    spec.style = {
                        'focus': fact.focus[0].value,
                        'style': 'bar', // bar pie donut half_donut
                    }
                    return <ProportionChart uuid={uuid} data={filteredData} spec={spec} width={width} height={height} />
                case ChartType.PROPORTION_ISOTYPE_CHART:
                    spec.encoding['y'] = {};
                    spec.encoding['y']['field'] = fact.measure[0].field;
                    spec.encoding['y']['aggregation'] = fact.measure[0].aggregate;
                    spec.encoding['x'] = {};
                    spec.encoding['x']['field'] = fact.groupby[0];
                    if (fact.focus.length > 0) {
                        spec.style = {
                            'proportion': fact.focus[0].value,
                        }
                    }
                    return <ISOType uuid={uuid} data={filteredData} spec={spec} width={width} height={height} />
                case ChartType.RING_CHART:
                    spec.encoding['size'] = {};
                    spec.encoding['size']['field'] = fact.measure[0].field;
                    spec.encoding['size']['aggregation'] = fact.measure[0].aggregate;
                    spec.encoding['color'] = {};
                    spec.encoding['color']['field'] = fact.groupby[0];
                    spec.style = {
                        'focus': fact.focus[0].value,
                        'style': 'donut', // bar pie donut half_donut
                    }
                    return <ProportionChart uuid={uuid} data={filteredData} spec={spec} width={width} height={height} />
                case ChartType.SCATTER_PLOT:
                    spec.encoding['x'] = {};
                    spec.encoding['x']['field'] = fact.measure[0].field;
                    spec.encoding['x']['aggregation'] = fact.measure[0].aggregate;
                    spec.encoding['y'] = {};
                    spec.encoding['y']['field'] = fact.measure[1].field;
                    spec.encoding['y']['aggregation'] = fact.measure[1].aggregate;
                    spec.style = {
                    }
                    return <ScatterPlot uuid={uuid} data={filteredData} spec={spec} width={width} height={height} />;
                // case ChartType.STACKED_LINE_CHART:
                //     //TODO: STACKED_LINE_CHART
                //     vis1 = fact2chart(uuid, fact, data, width, height);
                //     vis2 = fact2chart(uuid + 1, fact.aggregatedFact, data, width, height);
                //     return <Row style={{ width: width, height: height - 10 }}>
                //         <Col span={12}>
                //             <div style={{ transform: 'scale(0.5)', transformOrigin: 'left', height: height - 10 }}>
                //                 {vis1}
                //             </div>
                //         </Col>
                //         <Col span={12}>
                //             <div style={{ transform: 'scale(0.5)', transformOrigin: 'left', height: height - 10 }}>
                //                 {vis2}
                //             </div>
                //         </Col>
                //     </Row>
                // case ChartType.STACKED_BAR_CHART:
                //     //TODO: STACKED_BAR_CHART
                //     vis1 = fact2chart(uuid, fact, data, width, height);
                //     vis2 = fact2chart(uuid + 1, fact.aggregatedFact, data, width, height);
                //     return <Row style={{ width: width, height: height - 10 }}>
                //         <Col span={12}>
                //             <div style={{ transform: 'scale(0.5)', transformOrigin: 'left', height: height - 10 }}>
                //                 {vis1}
                //             </div>
                //         </Col>
                //         <Col span={12}>
                //             <div style={{ transform: 'scale(0.5)', transformOrigin: 'left', height: height - 10 }}>
                //                 {vis2}
                //             </div>
                //         </Col>
                //     </Row>
                case ChartType.TEXT_CHART:
                    spec.style = {
                        "value": fact.parameter
                    }
                    spec.encoding['y'] = {};
                    spec.encoding['y']['field'] = fact.measure[0].field;
                    spec.encoding['y']['aggregation'] = fact.measure[0].aggregate;
                    return <NumberChart uuid={uuid} data={filteredData} spec={spec} width={width} height={height} />
                case ChartType.VERTICAL_BAR_CHART:
                    spec.encoding['y'] = {};
                    spec.encoding['y']['field'] = fact.measure[0].field;
                    spec.encoding['y']['aggregation'] = fact.measure[0].aggregate;
                    spec.encoding['x'] = {};
                    spec.encoding['x']['field'] = fact.groupby[0];
                    if (fact.focus.length > 0) {
                        spec.style = {
                            'focus': [],
                        }
                        for (const focus of fact.focus) {
                            spec.style['focus'].push(focus.value)
                        }
                    }
                    return <BarChart uuid={uuid} data={filteredData} spec={spec} width={width} height={height} />
        
                default:
                    break;
            // }
        }
    }else{
        switch(chart){
            case ChartType.VERTICAL_DIFFERENCE_BAR_CHART:
                spec.encoding['y'] = {};
                spec.encoding['y']['field'] = fact.measure[0].field;
                spec.encoding['y']['aggregation'] = fact.measure[0].aggregate;
                spec.encoding['x'] = {};
                spec.encoding['x']['field'] = fact.groupby[0];
                if (fact.focus.length > 1) {
                    spec.style = {
                        'difference': [fact.focus[0].value, fact.focus[1].value],
                    }
                }
                return <BarChart uuid={uuid} data={filteredData} spec={spec} width={width} height={height} />
            case ChartType.VERTICAL_DIFFERENCE_ARROW_CHART:
                spec.encoding['y'] = {};
                spec.encoding['y']['field'] = fact.measure[0].field;
                spec.encoding['y']['aggregation'] = fact.measure[0].aggregate;
                spec.encoding['x'] = {};
                spec.encoding['x']['field'] = fact.groupby[0];
                if (fact.focus.length > 1) {
                    spec.style = {
                        'difference': [fact.focus[0].value, fact.focus[1].value],
                    }
                }
                return <BarChart uuid={uuid} data={filteredData} spec={spec} width={width} height={height} />
            case ChartType.ISOTYPE_BAR_CHART:
                spec.encoding['y'] = {};
                spec.encoding['y']['field'] = fact.measure[0].field;
                spec.encoding['y']['aggregation'] = fact.measure[0].aggregate;
                spec.encoding['x'] = {};
                spec.encoding['x']['field'] = fact.groupby[0];
                if (fact.focus.length > 0) {
                    spec.style = {
                        'focus': [],
                    }
                    for (const focus of fact.focus) {
                        spec.style['focus'].push(focus.value)
                    }
                }
                return <ISOType uuid={uuid} data={filteredData} spec={spec} width={width} height={height} />
            case ChartType.PROPORTION_ISOTYPE_CHART:
                spec.encoding['y'] = {};
                spec.encoding['y']['field'] = fact.measure[0].field;
                spec.encoding['y']['aggregation'] = fact.measure[0].aggregate;
                spec.encoding['x'] = {};
                spec.encoding['x']['field'] = fact.groupby[0];
                if (fact.focus.length > 0) {
                    spec.style = {
                        'proportion': fact.focus[0].value,
                    }
                }
                return <ISOType uuid={uuid} data={filteredData} spec={spec} width={width} height={height} />
            default:
                return <Chart spec={_.cloneDeep(specNew)} uuid={uuid} />
        }
    }
    
}

